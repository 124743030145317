import React from 'react';

import { TwitterTweetEmbed } from 'react-twitter-embed';

const XEmbed = ({ url }) => {
  const match = url.match(
    /https?:\/\/(www\.)?(twitter.com|x.com)\/\w+\/status\/(\d+)/
  );
  const tweetId = match?.[3];
  return (
    <div className="x-embed">
      <TwitterTweetEmbed tweetId={tweetId} options={{ width: 350 }} />
    </div>
  );
};

export default XEmbed;
