import Datetime from './datetime';

const parseConfigHierarchyLabel = (configFromFile) => {
  const columns = Object.keys(configFromFile[0]);
  const initailResultList = columns.map((column) =>
    column.startsWith('_')
      ? {
          group: column.slice(1),
          result: {},
        }
      : {
          group: column,
          result: [],
        }
  );
  const resultList = configFromFile.reduce((resultList, currentRow) => {
    const results = resultList;
    Object.entries(currentRow).forEach(([key, label], idx, row) => {
      if (label === '') {
        return;
      }

      const group = key.startsWith('_') ? key.slice(1) : key;
      const result = {
        label,
        value: `${group}|${label}`,
      };
      if (key.startsWith('_')) {
        const parentValue = row[idx - 1][1];
        if (!results[idx].result[parentValue]) {
          results[idx].result[parentValue] = [];
        }
        if (results[idx].result[parentValue].some((e) => e.label === label)) {
          return;
        }
        results[idx].result[parentValue] = [
          ...results[idx].result[parentValue],
          result,
        ];
      } else if (!results[idx].result.some((e) => e.label === label)) {
        results[idx].result = [...resultList[idx].result, result];
      }
    });
    return results;
  }, initailResultList);
  return resultList;
};

const parseConfigHierarchyLabelLegacy = (configFromFile) => {
  try {
    const findExistingResult = ({ resultList, label }) =>
      resultList.find((group) => group.label === label);
    const columns = Object.keys(configFromFile[0]);
    const resultList = configFromFile.reduce((resultList, currentRow) => {
      const existingResult = findExistingResult({
        resultList,
        label: currentRow[`${columns[0]}`],
      });
      let initialResultGroup;
      if (!existingResult) {
        initialResultGroup = {};
      } else {
        initialResultGroup = existingResult;
      }
      const result = columns.reduce((resultGroup, column, idx) => {
        if (currentRow[`${column}`] === '') {
          return resultGroup;
        }
        const currentResult = {
          group: column,
          label: currentRow[`${column}`],
          value: `${column}|${currentRow[`${column}`]}`,
        };
        const firstColumn = idx === 0;
        const currentResultHaveParent = !firstColumn;
        if (currentResultHaveParent) {
          // find current result parent
          let parent = resultGroup;
          for (let i = 1; i < idx; i += 1) {
            const previousCurrentColumn = columns[i];
            parent = parent.children.find(
              ({ label }) => label === currentRow[`${previousCurrentColumn}`]
            );
          }
          if (parent.children) {
            const existing = parent.children.some(
              (childrenParent) => childrenParent.label === currentResult.label
            );
            if (!existing) {
              parent.children.push(currentResult);
            }
          } else {
            parent.children = [currentResult];
          }
          return resultGroup;
        }
        const isNewResultGroup = Object.keys(resultGroup).length === 0;
        if (isNewResultGroup) {
          return currentResult;
        }
        return resultGroup;
      }, initialResultGroup);

      if (existingResult) {
        return resultList;
      }
      return [...resultList, result];
    }, []);
    return resultList;
  } catch (error) {
    console.error('error', error);
    return null;
  }
};

const parseConfigMultipleLabel = (configFromFile) => {
  const columns = Object.keys(configFromFile[0]);
  try {
    const initialResultGroup = columns.reduce((initialResultGroup, column) => {
      // eslint-disable-next-line no-param-reassign
      initialResultGroup[`${column}`] = [];
      return initialResultGroup;
    }, {});

    const resultGroup = configFromFile.reduce((resultGroup, currentRow) => {
      Object.keys(currentRow).forEach((key) => {
        if (currentRow[`${key}`] !== '' && currentRow[`${key}`] !== null) {
          resultGroup[`${key}`].push(currentRow[`${key}`]);
        }
      });
      return resultGroup;
    }, initialResultGroup);

    const resultList = Object.keys(resultGroup).reduce((resultList, key) => {
      const group = {
        group: key,
        result: resultGroup[`${key}`].map((label) => ({
          label,
          value: `${key}|${label}`,
        })),
      };
      return [...resultList, group];
    }, []);
    return resultList;
  } catch (error) {
    console.error('error', error);
    return null;
  }
};

const flatCheckItemTypeZocialeye = (checkItemList) =>
  checkItemList.reduce((acc, checkItem) => {
    const userCheckedResult = checkItem.usersCheckResultList.reduce(
      (userCheckedResult, userCheckResult) => {
        const row = userCheckResult.checkResultList.map((result) => {
          const labelAndValue = result.resultList.reduce(
            (labelValue, label) => {
              // eslint-disable-next-line no-param-reassign
              labelValue[`$result-${label.group}`] = label.label;
              return { ...labelValue };
            },
            {}
          );
          return {
            byId: userCheckResult.byUserID,
            note: userCheckResult.note,
            ...labelAndValue,
            payment: userCheckResult.paymentRate,
            checkedAt: Datetime.toFormat(
              userCheckResult.checkedAt,
              'YYYY-MM-DD[T]HH:mm:ssz[Z]',
              'YYYY-MM-DD HH:mm:ss',
              'UTC',
              'Asia/Bangkok'
            ),
          };
        });
        return [...userCheckedResult, ...row];
      },
      []
    );
    const notUserCheckedResult = userCheckedResult.length === 0;
    if (notUserCheckedResult) {
      return [
        ...acc,
        {
          ...checkItem.checkItem.zocialEyeData,
        },
      ];
    }
    return [
      ...acc,
      ...userCheckedResult.map((checkResults) => ({
        ...checkItem.checkItem.zocialEyeData,
        ...checkResults,
      })),
    ];
  }, []);

const flatCheckItemTypeText = (checkItemList) =>
  checkItemList.reduce((acc, checkItem) => {
    const userCheckedResult = checkItem.usersCheckResultList.reduce(
      (userCheckedResult, userCheckResult) => {
        const row = userCheckResult.checkResultList.map((result) => {
          const labelAndValue = result.resultList.reduce(
            (labelValue, label) => {
              // eslint-disable-next-line no-param-reassign
              labelValue[`$result-${label.group}`] = label.label;
              return { ...labelValue };
            },
            {}
          );
          return {
            byId: userCheckResult.byUserID,
            note: userCheckResult.note,
            ...labelAndValue,
            payment: userCheckResult.paymentRate,
            checkedAt: Datetime.toFormat(
              userCheckResult.checkedAt,
              'YYYY-MM-DD[T]HH:mm:ssz[Z]',
              'YYYY-MM-DD HH:mm:ss',
              'UTC',
              'Asia/Bangkok'
            ),
          };
        });
        return [...userCheckedResult, ...row];
      },
      []
    );
    const notUserCheckedResult = userCheckedResult.length === 0;
    if (notUserCheckedResult) {
      return [
        ...acc,
        { ...checkItem.checkItem.legacyData, ...checkItem.checkItem.metaData },
      ];
    }
    return [
      ...acc,
      ...userCheckedResult.map((checkResults) => ({
        ...checkItem.checkItem.legacyData,
        ...checkItem.checkItem.metaData,
        ...checkResults,
      })),
    ];
  }, []);

const flatCheckItemTypeRedsmith = (checkItemList) =>
  checkItemList.reduce((acc, checkItem) => {
    const userCheckedResult = checkItem.usersCheckResultList.reduce(
      (userCheckedResult, userCheckResult) => {
        const row = userCheckResult.checkResultList.map((result) => {
          const labelAndValue = result.resultList.reduce(
            (labelValue, label) => {
              // eslint-disable-next-line no-param-reassign
              labelValue[`$result-${label.group}`] = label.label;
              return { ...labelValue };
            },
            {}
          );
          return {
            byId: userCheckResult.byUserID,
            note: userCheckResult.note,
            ...labelAndValue,
            payment: userCheckResult.paymentRate,
            checkedAt: Datetime.toFormat(
              userCheckResult.checkedAt,
              'YYYY-MM-DD[T]HH:mm:ssz[Z]',
              'YYYY-MM-DD HH:mm:ss',
              'UTC',
              'Asia/Bangkok'
            ),
          };
        });
        return [...userCheckedResult, ...row];
      },
      []
    );
    const notUserCheckedResult = userCheckedResult.length === 0;
    if (notUserCheckedResult) {
      return [
        ...acc,
        {
          ...checkItem.checkItem.redsmithData,
        },
      ];
    }
    return [
      ...acc,
      ...userCheckedResult.map((checkResults) => ({
        ...checkItem.checkItem.redsmithData,
        ...checkResults,
      })),
    ];
  }, []);

export default {
  parseConfigHierarchyLabel,
  parseConfigHierarchyLabelLegacy,
  parseConfigMultipleLabel,
  flatCheckItemTypeZocialeye,
  flatCheckItemTypeText,
  flatCheckItemTypeRedsmith,
};
