import React from 'react';

import { Col, Row } from 'reactstrap';

import TextInput from '../atoms/TextInput';

const TaskNote = ({ updateNote, note, disableNote }) => (
  <Row className="task-note">
    <Col md={12}>
      <TextInput
        id="check-item-note"
        type="textarea"
        className="note"
        placeholder=""
        value={note}
        onChange={({ target }) => updateNote(target.value)}
        disabled={disableNote}
      />
    </Col>
  </Row>
);

export default TaskNote;
