import React from 'react';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';

library.add(faPlus);

const AddButton = ({ disabled, onClick }) => (
  <Button
    className="add-button"
    color="info"
    disabled={disabled}
    onClick={onClick}
  >
    <FontAwesomeIcon icon="plus" />
  </Button>
);

export default AddButton;
