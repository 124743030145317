import React from 'react';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faBroom } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Button } from 'reactstrap';

library.add(faBroom);

const ClearReservedCheckItemsButton = ({ disabled, onClick, taskId }) => (
  <OverlayTrigger
    key="top"
    placement="top"
    overlay={
      <Tooltip id={`${taskId}-tooltip-clear-reserved-check-items-button`}>
        <strong>CLEAR RESERVED</strong>
      </Tooltip>
    }
  >
    <Button
      className="start-button btn-outline"
      disabled={disabled}
      onClick={onClick}
      id={`${taskId}-clear-reserved-check-items-button`}
    >
      <FontAwesomeIcon icon={['far', 'broom']} />
    </Button>
  </OverlayTrigger>
);

export default ClearReservedCheckItemsButton;
