import moment from 'moment-timezone';

const fromTimestamp = (timestamp) => moment.unix(timestamp).utc();
const toDate = (datetime) => moment(datetime).toDate();
const toMoment = (datetime) => moment(datetime);
const toMomentWithTimezone = (datetime, timezone) =>
  moment.tz(datetime, timezone);
const toFormat = (
  datetime,
  fromFormat = 'YYYY-MM-DD HH:mm:ss',
  toFormat = 'YYYY-MM-DD HH:mm:ss',
  fromTimezone = 'UTC',
  toTimezone = 'Asia/Bangkok'
) =>
  moment.tz(datetime, fromFormat, fromTimezone).tz(toTimezone).format(toFormat);

const lastMonth = () => moment().subtract(1, 'month');
const now = () => moment();
const nextMonth = () => moment().add(1, 'month');

export default {
  toFormat,
  toMoment,
  toMomentWithTimezone,
  toDate,
  fromTimestamp,
  now,
  nextMonth,
  lastMonth,
};
