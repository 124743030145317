export const getErrorMessage = (errorCode) => {
  let errorMessage = null;

  switch (errorCode) {
    case 'FetchError':
      errorMessage = 'Connection lost';
      break;
    case 'UserCredentialInvalidError':
      errorMessage = 'Username or password is incorrect';
      break;
    case 'UnauthorizedError':
      errorMessage = 'Please login';
      break;
    case 'UserUsernameNotAvailableError':
      errorMessage = 'Username is not available';
      break;
    case 'SyntaxError':
      errorMessage = 'Invalid payload request';
      break;
    default:
      errorMessage = 'Something wrong';
      break;
  }

  return errorMessage;
};
