import React from 'react';

const TaskStatisticBody = ({ data }) => {
  return (
    <tbody>
      {data && data.length > 0 ? (
        data.map((row, idx) => (
          <tr key={idx}>
            <td width="140" className="text-center">
              {row.userID}
            </td>
            <td width="100" className="text-center">
              {row.userName}
            </td>
            <td width="80" className="text-center">
              {row.checkedItemCount}
            </td>
          </tr>
        ))
      ) : (
        <tr>
          <td colSpan={6}>No statistic</td>
        </tr>
      )}
    </tbody>
  );
};

export default TaskStatisticBody;
