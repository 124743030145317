import React from 'react';

import currencyNumber from '../../utils/currencyNumber';

const TaskStatistic = ({ checkedCount, totalCount }) => (
  <div className="task-statistic">
    <div>
      <span className="label">Checked:</span>
      <span className="value">{currencyNumber(checkedCount) || 0}</span>
    </div>
    <div className="divider" />
    <div>
      <span className="label">Total:</span>
      <span className="value">{currencyNumber(totalCount) || 0}</span>
    </div>
  </div>
);

export default TaskStatistic;
