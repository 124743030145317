import React from 'react';

import { Input } from 'reactstrap';

const CheckboxInput = (props) => (
  <div className="input-checkbox">
    <Input addon type="checkbox" {...props} />
  </div>
);

export default CheckboxInput;
