/** App */
export const SET_APP_STATE = 'SET_APP_STATE';
export const SET_APP_LOADING = 'SET_APP_LOADING';
export const SET_SIDEBAR = 'SET_SIDEBAR';

/** Auth */
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

/** User */
export const SET_USER = 'SET_USER';
export const RESET_USER = 'RESET_USER';
export const UPDATE_USER_VALUE = 'UPDATE_USER_VALUE';
export const SAVE_ASSIGN_USER = 'SAVE_ASSIGN_USER';

/** Task */
export const SET_TASK = 'SET_TASK';
export const SET_TASKS_QUERY = 'SET_TASKS_QUERY';
export const SET_TASKS = 'SET_TASKS';
export const SET_TASKS_COUNT = 'SET_TASKS_COUNT';
export const GET_TASKS = 'GET_TASKS';
export const SET_SELECTED_TASK_LIST_PAGE = 'SET_SELECTED_TASK_LIST_PAGE';
export const SET_SELECTED_TASK = 'SET_SELECTED_TASK';
export const SET_SELECTED_CONFIG = 'SET_SELECTED_CONFIG';
export const SET_SELECTED_CHECKRESULT = 'SET_SELECTED_CHECKRESULT';
export const SET_TASK_PERIOD = 'SET_TASK_PERIOD';
export const SET_TASK_DATA = 'SET_TASK_DATA';
export const SET_SELECTED_USER_STAT = 'SET_SELECTED_USER_STAT';
export const CLEAR_SELECTED_TASK = 'CLEAR_SELECTED_TASK';
export const CLEAR_SELECTED_USER_STAT = 'CLEAR_SELECTED_USER_STAT';
export const RESET_TASK = 'RESET_TASK';
export const CREATE_INVITE_LINK = 'CREATE_INVITE_LINK';
export const UPDATE_INVITE_LINK = 'UPDATE_INVITE_LINK';
export const SET_SYNC = 'SET_SYNC';
export const RESET_SYNC = 'RESET_SYNC';
export const UPDATE_SYNC_VALUE = 'UPDATE_SYNC_VALUE';
export const SET_TASK_EXPORT = 'SET_TASK_EXPORT';
export const RESET_TASK_EXPORT = 'RESET_TASK_EXPORT';
export const UPDATE_TASK_EXPORT_VALUE = 'UPDATE_TASK_EXPORT_VALUE';

/** New Task */
export const SET_DATA_NEW_TASK = 'SET_DATA_NEW_TASK';
export const SET_SCHEDULE_NEW_TASK = 'SET_SCHEDULE_NEW_TASK';
export const UPDATE_TASK_VALUE = 'UPDATE_TASK_VALUE';
export const CLEAR_TASK_VALUE = 'CLEAR_TASK_VALUE';

/** CheckItem */
export const SET_CHECKITEM_DATA = 'SET_CHECKITEM_DATA';
export const CLEAR_CHECKITEM_DATA = 'CLEAR_CHECKITEM_DATA';
export const SET_CHECKITEM_CONFIG = 'SET_CHECKITEM_CONFIG';
export const GET_CHECKITEM = 'GET_CHECKITEM';
export const SKIP_CHECKITEM = 'SKIP_CHECKITEM';
export const CLEAR_RESERVED_CHECKITEMS = 'CLEAR_RESERVED_CHECKITEMS';

/** Check Result */
export const SAVE_CHECK_RESULT = 'SAVE_CHECK_RESULT';
export const ADD_INITIAL_CHECK_RESULT = 'ADD_INITIAL_CHECK_RESULT';
export const UPDATE_CHECK_RESULT = 'UPDATE_CHECK_RESULT';
export const UPDATE_NOTE = 'UPDATE_NOTE';
