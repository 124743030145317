import React, { useContext, useEffect, useRef, useState } from 'react';

import { useAlert } from 'react-alert';
import { Redirect, useHistory } from 'react-router-dom';

import Loading from '../components/molecules/Loading';
import Task from '../components/views/Task';
import {
  ADD_INITIAL_CHECK_RESULT,
  CLEAR_CHECKITEM_DATA,
  CLEAR_SELECTED_TASK,
  CLEAR_SELECTED_USER_STAT,
  SET_CHECKITEM_DATA,
  SET_SELECTED_TASK,
  SET_SELECTED_USER_STAT,
  SKIP_CHECKITEM,
} from '../constants/actions';
import {
  ALERT_CHECKITEM_SAVE_ERROR,
  ALERT_CHECKITEM_SAVE_SUCCESS,
  ALERT_CHECKITEM_SKIP_ERROR,
  ALERT_CHECKITEM_SKIP_SUCCESS,
  ALERT_TASK_INACTIVE_ERROR,
  ALERT_TASK_NOT_AVAILABLE,
  ALERT_TASK_NOT_FOUND_ERROR,
} from '../constants/messages';
import { TASK_LIST } from '../constants/route';
import { INITIAL, PENDING, REDIRECT, RENDERED } from '../constants/state';
import Api from '../services/api';
import { AppContext } from '../store';
import { formatCheckResult } from '../utils/format';
import { isTaskScheduleActive, to } from '../utils/utils';

const TaskCheckResult = ({ match }) => {
  const { state, dispatch } = useContext(AppContext);
  const [stateStatus, setStateStatus] = useState(INITIAL);

  const saveCounter = useRef(0);
  const [isSaving, setIsSaving] = useState(false);
  const [isCheckableItemAvailable, setIsCheckableItemAvailable] =
    useState(false);
  const history = useHistory();
  const alert = useAlert();

  const {
    checkitem: { data: checkableItem, results: checkResults },
    task: { selectedTask, selectedUserStat },
    auth,
  } = state;

  const { id: taskId } = match.params;

  let checkResultConfig;
  let checkResultType;
  let checkItemPaymentRate;
  const backToTaskList = () => {
    history.push(to(TASK_LIST));
  };

  const getUserStatistic = async (taskId, userId, token) => {
    const api = new Api(token);
    const response = await api.getUserStatistic(taskId, userId);
    if (response) {
      dispatch({
        type: SET_SELECTED_USER_STAT,
        payload: { selectedUserStat: { ...response, userId } },
      });
    }
  };

  const updateTaskResult = async (
    checkitemId,
    checkResultList,
    note,
    actionType,
    actionTime
  ) => {
    saveCounter.current += 1;
    setIsSaving(true);
    const { paymentRate } = selectedTask.config;
    const currentCheckitem = checkableItem.find(
      (checkitem) => checkitem.id === checkitemId
    );

    checkItemPaymentRate =
      paymentRate.type === 'flat'
        ? paymentRate.compensationPerItem
        : paymentRate.compensationSettings[currentCheckitem.channel];

    const checkResult = formatCheckResult(
      checkResultType,
      checkResultList,
      note,
      checkItemPaymentRate
    );
    const { id: checkItemId, taskSchedulerID } = currentCheckitem;
    const api = new Api(auth.user.token);
    const response = await api.saveCheckResult(
      taskId,
      checkItemId,
      taskSchedulerID,
      checkResult,
      actionType,
      actionTime
    );
    if (response?.status !== 'success') saveCounter.current -= 1;
  };

  const updateCheckItem = () => {
    saveCounter.current = 0;
    setIsCheckableItemAvailable(false);
    dispatch({ type: CLEAR_CHECKITEM_DATA });
    setIsSaving(false);
    setStateStatus(PENDING);
  };

  const alertMessage = (checkedItemCount) => {
    const saveComplete = checkedItemCount - saveCounter.current === 0;
    const saveIncomplete =
      checkedItemCount - saveCounter.current > 0 && saveCounter.current !== 0;

    if (saveComplete) {
      alert.show(
        `${ALERT_CHECKITEM_SAVE_SUCCESS} ${saveCounter.current} items`,
        {
          timeout: 3000,
          type: 'success',
        }
      );
      return;
    }

    if (saveIncomplete) {
      alert.show(
        `${ALERT_CHECKITEM_SAVE_SUCCESS} ${saveCounter.current} items`,
        {
          timeout: 3000,
          type: 'warning',
        }
      );
      return;
    }

    alert.show(`${ALERT_CHECKITEM_SAVE_ERROR}`, {
      timeout: 3000,
      type: 'error',
    });
  };

  const updateAllTaskResult = async () => {
    let checkedItemCount = 0;
    const occupiedCheckResults = checkResults.filter(
      (checkResult) => checkResult.checkResult.length > 0
    );
    await Promise.all(
      occupiedCheckResults.map(async (checkResult) => {
        checkedItemCount += 1;
        await updateTaskResult(
          checkResult.checkitemId,
          checkResult.checkResult,
          checkResult.note,
          checkResult.actionType,
          checkResult.actionTime
        );
      })
    );
    alertMessage(checkedItemCount);
    updateCheckItem();
  };

  const skipCheckItem = () => setStateStatus(SKIP_CHECKITEM);

  if (selectedTask && selectedTask.id && selectedTask.config) {
    const { resultTypeConfiguration } = selectedTask.config;

    checkResultType = resultTypeConfiguration.type;
    checkResultConfig = resultTypeConfiguration.configuration;
  }

  useEffect(() => {
    const saveSkipCheckItem = async (taskId, checkableItem, token) => {
      const { id: checkItemId } = checkableItem;

      const api = new Api(token);
      const success = await api.skipCheckItem(taskId, checkItemId);

      if (success) {
        setStateStatus(PENDING);
        dispatch({ type: CLEAR_CHECKITEM_DATA });

        alert.show(ALERT_CHECKITEM_SKIP_SUCCESS, {
          timeout: 3000,
          type: 'success',
        });
      } else {
        setStateStatus(RENDERED);

        alert.show(ALERT_CHECKITEM_SKIP_ERROR, {
          timeout: 3000,
          type: 'error',
        });
      }
    };

    const getCheckableItem = async (taskId, token) => {
      if (isCheckableItemAvailable) return;
      const api = new Api(token);
      const itemCount = auth.user.checkableItemLimit;
      const data = await api.getCheckItem(taskId, itemCount);
      if (data) {
        data.forEach((checkitem) => {
          dispatch({
            type: ADD_INITIAL_CHECK_RESULT,
            payload: { checkitemId: checkitem.id },
          });
        });
        setIsCheckableItemAvailable(true);
        dispatch({
          type: SET_CHECKITEM_DATA,
          payload: { data: data.length > 0 ? data : [] },
        });
        setStateStatus(RENDERED);
      } else {
        setStateStatus(REDIRECT);

        alert.show(ALERT_TASK_NOT_AVAILABLE, {
          timeout: 3000,
          type: 'error',
        });
      }
    };

    const getTask = async (taskId, token) => {
      if (Object.keys(selectedTask).length !== 0) return;
      const api = new Api(token);
      const task = await api.getTask(taskId);

      if (task && task.id) {
        if (
          task &&
          task.currentSchedule &&
          !isTaskScheduleActive(task.currentSchedule.schedule)
        ) {
          alert.show(ALERT_TASK_INACTIVE_ERROR, {
            timeout: 3000,
            type: 'error',
          });
          backToTaskList();
        } else {
          dispatch({
            type: SET_SELECTED_TASK,
            payload: { selectedTask: task },
          });
        }
      } else {
        setStateStatus(REDIRECT);

        alert.show(ALERT_TASK_NOT_FOUND_ERROR, {
          timeout: 3000,
          type: 'error',
        });
      }
    };

    const prepareData = async () => {
      saveCounter.current = 0;
      await Promise.all([
        getTask(taskId, auth.user.token),
        getUserStatistic(taskId, auth.user.id, auth.user.token),
      ]);
      if (selectedTask?.id) {
        await getCheckableItem(taskId, auth.user.token);
      }
    };

    const cleanUp = () => {
      dispatch({ type: CLEAR_CHECKITEM_DATA });
      dispatch({ type: CLEAR_SELECTED_TASK });
      dispatch({ type: CLEAR_SELECTED_USER_STAT });
    };

    switch (stateStatus) {
      case INITIAL:
        cleanUp();
        setStateStatus(PENDING);
        break;
      case PENDING:
        prepareData();
        break;
      case SKIP_CHECKITEM:
        saveSkipCheckItem(taskId, checkableItem, auth.user.token);
        break;
      default:
        break;
    }
  }, [stateStatus, auth, selectedTask]);
  return (
    <>
      {stateStatus === REDIRECT && <Redirect to={TASK_LIST} />}
      {stateStatus !== RENDERED && <Loading />}
      {stateStatus === RENDERED && (
        <div id="task_check_item">
          <Task
            checkableItem={checkableItem}
            checkResultConfig={checkResultConfig}
            checkResultType={checkResultType}
            saveResultsAndUpdateCheckitem={updateAllTaskResult}
            userStat={selectedUserStat}
            taskName={selectedTask.name}
            onSkipCheckItem={skipCheckItem}
            isSaving={isSaving}
          />
        </div>
      )}
    </>
  );
};

export default TaskCheckResult;
