import React, { useEffect, useState } from 'react';

import { Col, Row } from 'reactstrap';

import { isDuplicateArrayValue } from '../../utils/utils';
import ResultAdd from '../molecules/ResultAdd';
import ResultButtonGroup from '../molecules/ResultButtonGroup';
import ResultView from '../molecules/ResultView';

const TaskMultiLabelController = ({
  checkResultConfig,
  checkResultList,
  addCheckResult,
  deleteCheckResult,
  duplicateCheckResult,
  setValidCheckResult,
  setRejectDisabled,
  isRejected,
}) => {
  const itemsGroup = checkResultConfig.map(({ result }) => result);
  const groupsList = checkResultConfig.map(({ group }) => group);

  const concatenate = (datas, delimeter) =>
    datas.reduce(
      (acc, cur) => (acc ? `${acc}${delimeter}${cur}` : `${cur}`),
      ''
    );

  const createInitialSuggestionOptions = () => {
    const paths = [];
    const max = itemsGroup.length - 1;

    function helper(arr, i) {
      for (let j = 0, l = itemsGroup[i].length; j < l; j += 1) {
        const a = arr.slice(0); // clone arr
        a.push(itemsGroup[i][j]);
        if (i === max) paths.push(a);
        else helper(a, i + 1);
      }
    }

    helper([], 0);
    return paths.map((path) => ({
      name: concatenate(
        path.map(({ label }) => label),
        ' > '
      ),
      path,
    }));
  };

  const initialSuggestionOptions = createInitialSuggestionOptions();
  const checkResultListLabel = checkResultList.map(({ label }) =>
    concatenate(label, ' > ')
  );

  const [resultIdx, setResultIdx] = useState(0);
  const [resultLabel, setResultLabel] = useState([]);
  const [resultValue, setResultValue] = useState([]);
  const [resultGroup, setResultGroup] = useState([]);
  const [addDisabled, setAddDisabled] = useState(true);
  const [suggestionOptions, setSuggestionOptions] = useState(
    initialSuggestionOptions
  );

  const updateCheckResultFromSelectedOption = (selectedOption) => {
    const { name } = selectedOption;
    const { path } = initialSuggestionOptions.find(
      (option) => name === option.name
    );
    const label = path.map(({ label }) => label);
    const value = path.map(({ value }) => value);
    const group = groupsList;
    const checkResultListValue = checkResultList.map(({ value }) => value);
    const prefix = concatenate(label, ' > ');
    const options = initialSuggestionOptions.filter(
      ({ name }) =>
        name.startsWith(prefix) && !checkResultListLabel.includes(name)
    );

    /** Result */
    setResultIdx(itemsGroup.length);
    setResultLabel(label);
    setResultValue(value);
    setResultGroup(group);
    setValidCheckResult(false);

    /** Suggestion */
    setSuggestionOptions(options);

    /** Button */
    setAddDisabled(isDuplicateArrayValue(checkResultListValue, value));
    setRejectDisabled(true);
  };

  const updateResult = (checkResult) => {
    const label = [...resultLabel, checkResult.label];
    const value = [...resultValue, checkResult.value];
    const group = [...resultGroup, groupsList[resultIdx]];
    const checkResultListValue = checkResultList.map(({ value }) => value);
    const prefix = concatenate(label, ' > ');
    const options = initialSuggestionOptions.filter(
      ({ name }) =>
        name.startsWith(prefix) && !checkResultListLabel.includes(name)
    );

    /** Result */
    setResultIdx(resultIdx + 1);
    setResultLabel(label);
    setResultValue(value);
    setResultGroup(group);
    setValidCheckResult(label.length === 0);

    /** Suggestion */
    setSuggestionOptions(options);

    /** Button */
    setRejectDisabled(true);
    setAddDisabled(
      resultIdx + 1 < itemsGroup.length ||
        isDuplicateArrayValue(checkResultListValue, value)
    );
  };

  const duplicateResult = (id) => {
    const duplicatedCheckResult = duplicateCheckResult(id);
    const prefix = concatenate(duplicatedCheckResult.label.slice(0, -1), ' > ');
    const options = initialSuggestionOptions.filter(
      ({ name }) =>
        name.startsWith(prefix) && !checkResultListLabel.includes(name)
    );

    /** Result */
    setResultIdx(duplicatedCheckResult.value.length - 1);
    setResultLabel(duplicatedCheckResult.label.slice(0, -1));
    setResultValue(duplicatedCheckResult.value.slice(0, -1));
    setResultGroup(duplicatedCheckResult.group.slice(0, -1));
    setValidCheckResult(false);

    /** Suggestion */
    setSuggestionOptions(options);

    /** Button */
    setAddDisabled(true);
    setRejectDisabled(true);
  };

  const deleteResult = (indexLabel) => {
    if (indexLabel < 0) return;

    const label = resultLabel.splice(0, indexLabel);
    const value = resultValue.splice(0, indexLabel);
    const group = resultGroup.splice(0, indexLabel);
    const prefix = concatenate(label, ' > ');
    const options = initialSuggestionOptions.filter(
      ({ name }) =>
        name.startsWith(prefix) && !checkResultListLabel.includes(name)
    );

    /** Result */
    setResultIdx(indexLabel);
    setResultLabel(label);
    setResultValue(value);
    setResultGroup(group);
    setValidCheckResult(label.length === 0 && checkResultList.length !== 0);

    /** Suggestion */
    setSuggestionOptions(options);

    /** Button */
    setAddDisabled(true);
    setRejectDisabled(!(label.length === 0 && checkResultList.length === 0));
  };

  const clearResult = () => {
    /** Result */
    setResultIdx(0);
    setResultLabel([]);
    setResultValue([]);
    setResultGroup([]);

    /** Suggestion */
    setSuggestionOptions(initialSuggestionOptions);
  };

  const updateCheckResult = () => {
    clearResult();

    /** Result */
    addCheckResult(resultLabel, resultValue, resultGroup);
    setValidCheckResult(true);

    /** Button */
    setAddDisabled(true);
  };

  useEffect(() => {
    const prefix = concatenate(resultLabel, ' > ');
    const options = initialSuggestionOptions.filter(
      ({ name }) =>
        name.startsWith(prefix) && !checkResultListLabel.includes(name)
    );
    /** Suggestion */
    setSuggestionOptions(options);
  }, [
    checkResultList,
    checkResultListLabel,
    initialSuggestionOptions,
    resultLabel,
  ]);

  const items = resultIdx < itemsGroup.length ? itemsGroup[resultIdx] : [];
  const tags = resultLabel ? resultLabel.map((name, id) => ({ id, name })) : [];
  const options = suggestionOptions.map(({ name }, id) => ({
    id,
    name,
  }));

  return (
    <Row className="task-controller">
      <Col md={12}>
        {checkResultList.map(({ label, id }, idx) => (
          <ResultView
            key={idx}
            result={label}
            onClickDuplicateButton={() => duplicateResult(id)}
            onClickDeleteButton={() => deleteCheckResult(id)}
          />
        ))}
      </Col>
      <Col md={12}>
        <ResultAdd
          tags={tags}
          onClickDeleteButton={deleteResult}
          onClickAddButton={updateCheckResult}
          onClickSelectOption={updateCheckResultFromSelectedOption}
          disableAddButton={addDisabled}
          suggestions={options}
        />
      </Col>
      <Col md={12}>
        <ResultButtonGroup
          resultList={items}
          onClickEachButton={updateResult}
          disableResultButton={isRejected}
        />
      </Col>
    </Row>
  );
};

export default TaskMultiLabelController;
