import {
  RESET_USER,
  SET_USER,
  UPDATE_INVITE_LINK,
  UPDATE_USER_VALUE,
} from '../constants/actions';
import { formatUser } from '../utils/format';

const initialState = {
  user: {
    id: null,
    username: null,
    password: null,
    email: null,
    checkableItemLimit: 5,
    name: null,
    role: 'checker',
    isChangePassword: false,
  },
  invitationLink: '',
};

const setUser = (state, action) => {
  const user = formatUser(action.payload.user);
  return { ...state, user };
};

const resetUser = (state) => {
  const { user } = initialState;
  return { ...state, user };
};

const reducer = (state, action) => {
  switch (action.type) {
    case SET_USER:
      return setUser(state, action);
    case RESET_USER:
      return resetUser(state, action);
    case UPDATE_USER_VALUE:
      return {
        ...state,
        user: {
          ...state.user,
          [action.payload.field]: action.payload.value,
        },
      };
    case UPDATE_INVITE_LINK:
      return {
        ...state,
        invitationLink: action.payload.link,
      };
    default:
      return state;
  }
};

export default { state: initialState, reducer };
