import React, { useContext } from 'react';

import { useHistory } from 'react-router-dom';

import { USER_EDIT } from '../../constants/route';
import { AppContext } from '../../store';
import { canListTask, canListUser } from '../../utils/permission';
import { to } from '../../utils/utils';
import EditButton from '../atoms/EditButton';

const UserListBody = ({ data }) => {
  const { state } = useContext(AppContext);
  const { auth } = state;
  const history = useHistory();

  const goToEditUser = (userId) => {
    history.push(to(USER_EDIT, userId));
  };

  const statusIndicatorStyle = {
    margin: '0 8px 0 0',
    height: '8px',
    width: '8px',
  };

  return (
    <tbody>
      {data && data.length > 0 ? (
        data.map((row, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <tr key={idx}>
            <td width="140" className="text-center">
              {row.name}
            </td>
            <td width="80" className="text-center">
              @{row.username}
            </td>
            <td width="120" className="text-center">
              {row.createdBy.name}
            </td>
            <td width="120" className="text-center">
              @{row.createdBy.username}
            </td>
            <td width="50" className="text-center">
              {canListTask(auth) && (
                <EditButton
                  message="EDIT USER"
                  onClick={() => goToEditUser(row.id)}
                />
              )}
            </td>
          </tr>
        ))
      ) : (
        <tr>
          <td colSpan={6}>User not found.</td>
        </tr>
      )}
    </tbody>
  );
};

export default UserListBody;
