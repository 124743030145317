import {
  validateAlphanumeric,
  validateCheckableitemLimit,
  validateEmail,
  validateLength,
} from './utils';

const validateLogin = (auth) => auth.user && auth.isAuthUser === true;
const validateCanCreateTask = (auth) =>
  auth.user.permissions.includes('allow-all') === true ||
  auth.user.permissions.includes('create-tasks') === true;
const validateUserForm = ({
  username,
  password,
  email,
  name,
  checkableItemLimit,
}) => {
  const validateUsernameOrPassword = (username) =>
    validateAlphanumeric(username) && validateLength(username);
  const validateName = (name) => name !== null && name !== '';
  if (!validateUsernameOrPassword(username)) {
    return false;
  }
  if (!validateUsernameOrPassword(password)) {
    return false;
  }
  // if (!validateEmail(email)) {
  //   return false;
  // }
  if (!validateCheckableitemLimit(checkableItemLimit)) {
    return false;
  }
  if (!validateName(name)) {
    return false;
  }
  return true;
};

export { validateLogin, validateCanCreateTask, validateUserForm };
