/** Task */
export const TASK_IMPORT_CHECKITEM = '/task/:id/import-check-item';
export const TASK_EXPORT_CHECKITEM = '/task/:id/export-check-item';
export const TASK_STATISTIC = '/task/:id/statistic';
export const TASK_ASSIGN_USER = '/task/:id/assign-user';
export const TASK_CLEAR_RESERVED_CHECKITEM =
  '/task/:id/clear-reserved-check-item';
export const TASK_RECENT_ACTIVITY = '/task/:id/recent-activity';
export const TASK_EDIT = '/task/:id/edit';
export const TASK_CREATE = '/task/create';
export const TASK_CHECK_RESULT = '/task/:id';
export const TASK_LIST = '/task';

/** Checkitem */
export const CHECKITEM_EDIT = '/check-item/:id/edit';

/** User */
export const USER_CREATE = '/user/create';
export const USER_EDIT = '/user/:id/edit';
export const USER_LIST = '/user';

/** App */
export const LOGIN = '/login';
export const LOGOUT = '/logout';
export const HOME = '/';

/** PUBLIC ROUTE */
export const JOIN = '/join';
