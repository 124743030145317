import React, { useState } from 'react';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import SearchButton from '../atoms/SearchButton';
import TextInput from '../atoms/TextInput';

library.add(faSearch);

const SearchBox = ({
  value,
  onClickSearchButton,
  onKeyEnterDown = (key) => key,
  icon = false,
}) => {
  const [searchKeyword, setSearchKeyword] = useState(value);

  return (
    <div className="search-box">
      {icon && <FontAwesomeIcon icon={['far', 'search']} />}
      <TextInput
        placeholder="Search..."
        value={searchKeyword}
        onChange={(e) => setSearchKeyword(e.target.value)}
        onKeyDown={({ key }) => {
          if (key === 'Enter') {
            onKeyEnterDown(searchKeyword);
          }
        }}
      />
      {onClickSearchButton && (
        <SearchButton onClick={() => onClickSearchButton(searchKeyword)} />
      )}
    </div>
  );
};

export default SearchBox;
