import React from 'react';

import InstagramEmbed from 'react-instagram-embed';

const InstagramPostEmbed = ({ url }) => {
  const postUrl = url.match(/https:\/\/(www\.)?instagram.com\/p\/\w+/)[0];
  return (
    <div className="instagram-post-embed">
      <InstagramEmbed url={postUrl} maxWidth={350} />
    </div>
  );
};

export default InstagramPostEmbed;
