import React, { useState } from 'react';

import RejectButton from '../atoms/RejectButton';
import SaveButton from '../atoms/SaveButton';

const ActionButtonGroup = ({
  checkItemId,
  onSaveButtonClick,
  onRejectButtonClick,
  disableSaveButton,
  disableRejectButton,
}) => (
  <div className="action-button-group">
    <div className="btn-group-2">
      <RejectButton
        onClick={() => onRejectButtonClick(checkItemId)}
        disabled={disableRejectButton}
      />
      {onSaveButtonClick && (
        <SaveButton
          onClick={() => onSaveButtonClick(checkItemId)}
          disabled={disableSaveButton}
        />
      )}
    </div>
  </div>
);

export default ActionButtonGroup;
