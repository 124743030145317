import React from 'react';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';

library.add(faTrashAlt);

const DeleteIconButton = (props) => (
  <Button className="delete-button" color="danger" {...props}>
    <FontAwesomeIcon icon={['far', 'trash-alt']} />
  </Button>
);

export default DeleteIconButton;
