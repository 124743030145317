import React, { useContext, useEffect, useState } from 'react';

import { useAlert } from 'react-alert';
import { Redirect } from 'react-router-dom';

import Loading from '../components/molecules/Loading';
import Body from '../components/templates/Body';
import Footer from '../components/templates/Footer';
import Header from '../components/templates/Header';
import Layout from '../components/templates/Layout';
import TaskClearReservedCheckItemsContainer from '../components/templates/TaskClearReservedCheckItemsContainer';
import {
  CLEAR_RESERVED_CHECKITEMS,
  SET_SELECTED_TASK,
} from '../constants/actions';
import {
  ALERT_TASK_CLEAR_RESERVED_CHECKITEMS_ERROR,
  ALERT_TASK_CLEAR_RESERVED_CHECKITEMS_SUCCESS,
  ALERT_TASK_INACTIVE_ERROR,
  ALERT_TASK_NOT_FOUND_ERROR,
} from '../constants/messages';
import { TASK_LIST } from '../constants/route';
import {
  FETCHING,
  INITIAL,
  PENDING,
  REDIRECT,
  RENDERED,
} from '../constants/state';
import Api from '../services/api';
import { AppContext } from '../store';
import { isTaskScheduleActive } from '../utils/utils';

const TaskClearReservedCheckItem = ({ match }) => {
  const { state, dispatch } = useContext(AppContext);
  const [stateStatus, setStateStatus] = useState(INITIAL);
  const [users, setUsers] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState(null);
  const [task, setTask] = useState(null);
  const alert = useAlert();

  const { auth } = state;

  const { id: taskID } = match.params;

  const clearCheckItems = (selectedUsersToClear) => {
    setSelectedUsers(selectedUsersToClear);
    setStateStatus(CLEAR_RESERVED_CHECKITEMS);
  };

  useEffect(() => {
    const getUsersAssignedToTask = async (token, taskID) => {
      const api = new Api(token);
      const assignedUsers = await api.getUsersAssignedToTask(taskID);
      setUsers(assignedUsers);
    };

    const getTask = async (token, taskID) => {
      const api = new Api(token);
      const task = await api.getTask(taskID);

      if (task && task.id) {
        if (
          task &&
          task.currentSchedule &&
          !isTaskScheduleActive(task.currentSchedule.schedule)
        ) {
          setStateStatus(REDIRECT);
          alert.show(ALERT_TASK_INACTIVE_ERROR, {
            timeout: 3000,
            type: 'error',
          });
          return;
        }
        dispatch({
          type: SET_SELECTED_TASK,
          payload: { selectedTask: task },
        });
        setTask(task);
      } else {
        setStateStatus(REDIRECT);
        alert.show(ALERT_TASK_NOT_FOUND_ERROR, {
          timeout: 3000,
          type: 'error',
        });
      }
    };

    const clearReservedCheckItems = async (token, taskID, userIDs) => {
      const api = new Api(token);
      const response = await api.clearReservedCheckItems(taskID, userIDs);

      let clearCount;
      if (typeof response.clearReservedCheckItemsCount === 'object') {
        clearCount = Object.values(
          response.clearReservedCheckItemsCount
        ).reduce((a, b) => a + b);
      } else if (typeof response.clearReservedCheckItemsCount === 'number') {
        clearCount = response.clearReservedCheckItemsCount;
      } else {
        clearCount = null;
      }

      if (clearCount || clearCount === 0) {
        alert.show(
          ALERT_TASK_CLEAR_RESERVED_CHECKITEMS_SUCCESS.replace(
            ':total',
            clearCount
          ),
          {
            timeout: 3000,
            type: 'success',
          }
        );
        setStateStatus(PENDING);
      } else {
        alert.show(ALERT_TASK_CLEAR_RESERVED_CHECKITEMS_ERROR, {
          timeout: 3000,
          type: 'error',
        });
      }
      setStateStatus(RENDERED);
    };

    switch (stateStatus) {
      case INITIAL:
        setStateStatus(PENDING);
        break;
      case PENDING:
        setStateStatus(FETCHING);
        break;
      case FETCHING:
        getTask(auth.user.token, taskID);
        if (task) {
          getUsersAssignedToTask(auth.user.token, taskID);
        }
        if (users) {
          setStateStatus(RENDERED);
        }
        break;
      case CLEAR_RESERVED_CHECKITEMS:
        clearReservedCheckItems(auth.user.token, taskID, selectedUsers);
        break;
      default:
        break;
    }
  }, [stateStatus, auth.user.token, taskID, selectedUsers, alert, task, users]);

  return (
    <>
      {stateStatus === REDIRECT && <Redirect to={TASK_LIST} />}
      {stateStatus !== RENDERED && <Loading />}
      {stateStatus === RENDERED && (
        <div id="task_clear_reserved_checkitems">
          <Layout>
            <Header />
            <Body>
              <TaskClearReservedCheckItemsContainer
                users={users}
                clearCheckItems={clearCheckItems}
              />
            </Body>
            <Footer />
          </Layout>
        </div>
      )}
    </>
  );
};

export default TaskClearReservedCheckItem;
