import React from 'react';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faDownload } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';

library.add(faDownload);

const DownloadButton = ({ onClick, text }) => (
  <Button className="download-button" color="link" onClick={onClick}>
    <FontAwesomeIcon icon={['far', 'download']} />
    {'  '}
    {text}
  </Button>
);

export default DownloadButton;
