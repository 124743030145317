import React from 'react';

import { Provider as AlertProvider } from 'react-alert';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import AlertTemplate from './components/templates/Alert';
import {
  CHECKITEM_EDIT,
  HOME,
  JOIN,
  LOGIN,
  TASK_ASSIGN_USER,
  TASK_CHECK_RESULT,
  TASK_CLEAR_RESERVED_CHECKITEM,
  TASK_CREATE,
  TASK_EDIT,
  TASK_EXPORT_CHECKITEM,
  TASK_STATISTIC,
  TASK_IMPORT_CHECKITEM,
  TASK_LIST,
  TASK_RECENT_ACTIVITY,
  USER_CREATE,
  USER_EDIT,
  USER_LIST,
} from './constants/route';
import Join from './containers/Join';
import LoginManagement from './containers/LoginManagement';
import TaskImportCheckItem from './containers/TaskAddCheckItem';
import TaskAssignUser from './containers/TaskAssignUser';
import TaskCheckResult from './containers/TaskCheckResult';
import TaskClearReservedCheckItem from './containers/TaskClearReservedCheckItems';
import TaskCreate from './containers/TaskCreate';
import TaskEdit from './containers/TaskEdit';
import TaskEditCheckResults from './containers/TaskEditCheckResults';
import TaskExportCheckItem from './containers/TaskExport';
import TaskList from './containers/TaskList';
import TaskRecentActivity from './containers/TaskRecentActivity';
import TaskStatistic from './containers/TaskStatistic';
import UserCreate from './containers/UserCreate';
import UserEdit from './containers/UserEdit';
import UserList from './containers/UserList';
import { withAuthLoginRequired, withPermission } from './hoc/withAuth';
import { initializeGoogleAnalytics } from './services/googleAnalytics';
import { AppProvider } from './store';
import {
  canAssignUserToTask,
  canCheckResultInTask,
  canCreateTask,
  canCreateUser,
  canImportCheckItemToTask,
  canListTask,
  canListUser,
  canManageTask,
} from './utils/permission';

import 'bootstrap-daterangepicker/daterangepicker.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-tagsinput/react-tagsinput.css';

import './assets/css/react-tags.css';
import './assets/css/react-toggle.css';
import './assets/css/wisesight.css';
import './assets/scss/global.scss';

import './assets/scss/alert.scss';
import './assets/scss/button.scss';
import './assets/scss/create_user_management.scss';
import './assets/scss/form.scss';
import './assets/scss/header.scss';
import './assets/scss/login.scss';
import './assets/scss/task_add_checkitem.scss';
import './assets/scss/task_assign_user.scss';
import './assets/scss/task_clear_reserved_checkitems.scss';
import './assets/scss/task_check_item.scss';
import './assets/scss/task_create.scss';
import './assets/scss/task_list.scss';
import './assets/scss/task_export.scss';
import './assets/scss/task_recent_activity.scss';
import './assets/scss/task_statistic.scss';
import './assets/scss/user_create.scss';
import './assets/scss/user_list.scss';
import './assets/scss/util.scss';

initializeGoogleAnalytics();

const PrivateRoute = ({ component, path, location, ...args }) => (
  <Route
    component={withAuthLoginRequired(component, path, location)}
    {...args}
  />
);

const App = () => (
  <AppProvider>
    <AlertProvider template={AlertTemplate} className="alert-template">
      <Router>
        <Switch>
          <PrivateRoute
            path={TASK_IMPORT_CHECKITEM}
            component={withPermission(
              TaskImportCheckItem,
              canImportCheckItemToTask,
              TASK_LIST
            )}
          />
          <PrivateRoute
            path={TASK_EXPORT_CHECKITEM}
            component={withPermission(
              TaskExportCheckItem,
              canManageTask,
              TASK_LIST
            )}
          />
          <PrivateRoute
            path={TASK_STATISTIC}
            component={withPermission(
              TaskStatistic,
              canCheckResultInTask,
              TASK_LIST
            )}
          />
          <PrivateRoute
            path={TASK_CLEAR_RESERVED_CHECKITEM}
            component={withPermission(
              TaskClearReservedCheckItem,
              canManageTask,
              TASK_LIST
            )}
          />
          <PrivateRoute
            path={TASK_ASSIGN_USER}
            component={withPermission(
              TaskAssignUser,
              canAssignUserToTask,
              TASK_LIST
            )}
          />
          <PrivateRoute
            path={TASK_RECENT_ACTIVITY}
            component={withPermission(
              TaskRecentActivity,
              canCheckResultInTask,
              TASK_LIST
            )}
          />
          <PrivateRoute
            path={TASK_EDIT}
            component={withPermission(TaskEdit, canManageTask, TASK_LIST)}
          />
          <PrivateRoute
            path={TASK_CREATE}
            component={withPermission(TaskCreate, canCreateTask, TASK_LIST)}
          />
          <PrivateRoute
            path={TASK_CHECK_RESULT}
            component={withPermission(
              TaskCheckResult,
              canCheckResultInTask,
              TASK_LIST
            )}
          />
          <PrivateRoute
            path={TASK_LIST}
            component={withPermission(TaskList, canListTask, USER_LIST)}
          />
          <PrivateRoute
            path={CHECKITEM_EDIT}
            component={withPermission(
              TaskEditCheckResults,
              canCheckResultInTask,
              TASK_LIST
            )}
          />
          <PrivateRoute path={USER_EDIT} component={UserEdit} />
          <PrivateRoute
            path={USER_CREATE}
            component={withPermission(UserCreate, canCreateUser, USER_LIST)}
          />
          <PrivateRoute path={USER_LIST} component={UserList} />
          <PrivateRoute path={JOIN} component={Join} />
          <Route path={LOGIN} component={LoginManagement} />
          <Route path={HOME} component={LoginManagement} />
        </Switch>
      </Router>
    </AlertProvider>
  </AppProvider>
);

export default App;
