import React from 'react';

import { Col, Row } from 'reactstrap';

const Footer = () => (
  <Row className="footer">
    <Col md={12} />
  </Row>
);

export default Footer;
