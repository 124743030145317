import { readCsvFile, readExcelFile, workbookToJson } from '../utils/file';

async function loadCsvFile(file, reader) {
  return new Promise((resolve, reject) => {
    reader.addEventListener('load', async (event) => {
      const { data, errors } = await readCsvFile(event.target.result);

      if (errors && errors.length === 0) {
        resolve(data);
      } else {
        reject(errors);
      }
    });

    reader.readAsText(file);
  });
}

async function loadExcelFile(file, reader) {
  return new Promise((resolve) => {
    reader.addEventListener('load', async (event) => {
      const workbook = await readExcelFile(event.target.result);
      resolve(workbookToJson(workbook));
    });
    reader.readAsArrayBuffer(file);
  });
}

class TaskReader {
  constructor(file) {
    // eslint-disable-next-line no-undef
    this.reader = new FileReader();
    this.file = file;
    this.data = undefined;
    this.fileType = undefined;
  }

  setFileType() {
    switch (this.file.type) {
      case 'text/csv':
        this.fileType = 'csv';
        break;
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        this.fileType = 'excel';
        break;
      default:
        this.fileType = this.file.type;
    }
  }

  isValidFileType() {
    return this.fileType === 'csv' || this.fileType === 'excel';
  }

  async readFile(file) {
    this.setFileType();

    if (!this.isValidFileType()) {
      throw new Error('The file type does not support.');
    }

    if (this.fileType === 'csv') {
      this.data = await loadCsvFile(file, this.reader);
    }

    if (this.fileType === 'excel') {
      this.data = await loadExcelFile(file, this.reader);
    }

    return this.data;
  }
}

export { TaskReader, loadExcelFile, loadCsvFile };
