import React from 'react';

import { Col, Row, Table } from 'reactstrap';

import Pagination from '../molecules/Pagination';
import TableHeader from '../molecules/TableHeader';
import TaskClearReservedCheckItemsBody from '../molecules/TaskClearReservedCheckItemsBody';

const TaskClearReservedCheckItemsController = ({
  users,
  page,
  updateSelectUsersToClear,
  selectedUsersToClear,
}) => {
  const tableHeader = [
    { id: 'checker', label: '', width: 40 },
    { id: 'user_name', label: 'Name', width: '' },
    { id: 'user_username', label: 'Username', width: '' },
  ];

  const { currentPage, setCurrentPage, totalPage, segmentSize } = page;

  return (
    <Row className="task-table">
      <Col md={12} className="table">
        <Table
          className="task-clear-reserved-checkitems-table"
          responsive
          striped
          bordered
        >
          <TableHeader data={tableHeader} />
          <TaskClearReservedCheckItemsBody
            data={users}
            checkFunction={updateSelectUsersToClear}
            checkedData={selectedUsersToClear}
          />
        </Table>
      </Col>
      <Col md={12} className="pagination">
        <Pagination
          currentPage={currentPage}
          totalPage={totalPage}
          setCurrentPage={setCurrentPage}
          segmentSize={segmentSize}
        />
      </Col>
    </Row>
  );
};

export default TaskClearReservedCheckItemsController;
