import React, { useContext, useEffect, useState } from 'react';

import { Col, Collapse, Row } from 'reactstrap';

import Api from '../../services/api';
import { AppContext } from '../../store';
import EmbedButton from '../atoms/EmbedButton';
import LinkButton from '../atoms/LinkButton';
import ParentButton from '../atoms/ParentButton';
import CheckItOffView from '../molecules/CheckItOffView';
import EmbedView from '../molecules/EmbedView';
import ImageView from '../molecules/ImageView';
import InformationView from '../molecules/InformationView';
import ParentPost from '../molecules/ParentPost';
import ParentView from '../molecules/ParentView';
import RedsmithV1View from '../molecules/RedsmithV1View';
import TextView from '../molecules/TextView';
import ZocialEyeV1View from '../molecules/ZocialEyeV1View';
import ZocialEyeV2View from '../molecules/ZocialEyeV2View';

const TaskView = ({ checkableItem }) => {
  const {
    title,
    detail,
    imageUrl,
    type,
    channel,
    embeddedUrl,
    displayName,
    postTime,
    place,
    post,
    comment,
    mainKeyword,
    subKeyword,
  } = checkableItem;

  const { state } = useContext(AppContext);
  const [isShowEmbed, setIsShowEmbed] = useState(false);
  const [isShowParent, setIsShowParent] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState(null);
  const { auth } = state;
  const toggleParent = () => setIsShowParent(!isShowParent);
  const toggleEmbed = () => setIsShowEmbed(!isShowEmbed);

  const isValidSource = (channel) => {
    const validSource = [
      'facebook',
      'twitter',
      'instagram',
      'youtube',
      'tiktok',
    ];
    return validSource.includes(channel);
  };

  const openLinkNewTab = () => {
    window.open(embeddedUrl, '_blank');
  };

  const getRedirectUrl = async () => {
    if (channel !== 'facebook') {
      setRedirectUrl(embeddedUrl);
      return;
    }

    const api = new Api(auth.user.token);
    const url = await api.getRedirectUrl(channel, embeddedUrl);
    setRedirectUrl(url);
  };

  const initialComponent = () => {
    if (!redirectUrl && isShowEmbed) getRedirectUrl();
  };

  useEffect(initialComponent, [isShowEmbed]);

  const ViewMap = {
    text: (
      <TextView
        title={title}
        detail={detail}
        mainKeywords={mainKeyword}
        subKeywords={subKeyword}
      />
    ),
    'zocial-eye-v1.0': (
      <ZocialEyeV1View
        detail={detail}
        mainKeywords={mainKeyword}
        subKeywords={subKeyword}
      />
    ),
    'zocial-eye-v2': (
      <ZocialEyeV2View
        detail={detail}
        mainKeywords={mainKeyword}
        subKeywords={subKeyword}
      />
    ),
    'redsmith-v1': (
      <RedsmithV1View
        detail={detail}
        mainKeywords={mainKeyword}
        subKeywords={subKeyword}
      />
    ),
    'check-it-off': (
      <CheckItOffView
        detail={detail}
        mainKeywords={mainKeyword}
        subKeywords={subKeyword}
      />
    ),
  };
  return (
    <Row className="task-view">
      <Col md={12}>{ViewMap[type]}</Col>
      <Col md={12}>{imageUrl && <ImageView imageUrl={imageUrl} />}</Col>
      <Col md={12}>
        <InformationView
          place={place}
          name={displayName}
          postTime={postTime}
          channel={channel}
        />
      </Col>
      <Col md={12}>
        <ParentPost post={post} />
      </Col>
      <Col md={12}>
        {embeddedUrl && <LinkButton onClick={() => openLinkNewTab()} />}
        {((post && post.id) || (comment && comment.id)) && (
          <ParentButton onClick={toggleParent} />
        )}
        <Col md={12}>
          <Collapse isOpen={isShowParent}>
            <ParentView post={post} comment={comment} place={place} />
          </Collapse>
        </Col>
        {channel && embeddedUrl && isValidSource(channel) && (
          <EmbedButton onClick={toggleEmbed} />
        )}
        <Col md={12}>
          <Collapse isOpen={isShowEmbed && redirectUrl}>
            <EmbedView channel={channel || null} url={redirectUrl || null} />
          </Collapse>
        </Col>
      </Col>
    </Row>
  );
};

export default TaskView;
