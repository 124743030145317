import React from 'react';

import BackButton from '../atoms/BackButton';

const ActionButtonGroupNotFound = ({ onBackButtonClick }) => (
  <div className="action-button-group">
    <div className="btn-group-1">
      <BackButton onClick={onBackButtonClick} text="Recent activities" />
    </div>
  </div>
);

export default ActionButtonGroupNotFound;
