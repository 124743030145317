import React, { useEffect, useState } from 'react';

import { EmbeddedPost, FacebookProvider } from 'react-facebook';

const FacebookPostEmbed = ({ url }) => {
  const [stateStatus, setStateStatus] = useState('pending');
  const [display, setDisplay] = useState(false);

  useEffect(() => {
    if (stateStatus === 'pending' && url.length > 0) {
      setDisplay(true);
      setStateStatus('render');
    }
  }, [url, stateStatus, display]);

  const width =
    document.getElementsByClassName('embed-view') &&
    document.getElementsByClassName('embed-view').length > 0
      ? document.getElementsByClassName('embed-view')[0].clientWidth
      : 100;

  return display ? (
    <FacebookProvider appId="642611689566304">
      <EmbeddedPost href={url} width={width} />
    </FacebookProvider>
  ) : (
    ''
  );
};

export default FacebookPostEmbed;
