import React, { useContext, useEffect, useState } from 'react';

import { useAlert } from 'react-alert';

import Loading from '../components/molecules/Loading';
import TaskListComponent from '../components/views/TaskList';
import {
  CLEAR_SELECTED_TASK,
  RESET_TASK,
  SET_SELECTED_TASK_LIST_PAGE,
  SET_TASKS,
  SET_TASKS_COUNT,
} from '../constants/actions';
import {
  ALERT_CHECKITEM_EXPORT_CHECKED_NOT_FOUND_ERROR,
  ALERT_CHECKITEM_EXPORT_ERROR,
  ALERT_CHECKITEM_EXPORT_NOT_FOUND_ERROR,
  ALERT_CHECKITEM_EXPORT_SUCCESS,
} from '../constants/messages';
import {
  FETCHING,
  INITIAL,
  PENDING,
  RENDERED,
  SUCCESS,
} from '../constants/state';
import Api from '../services/api';
import { AppContext } from '../store';

const TaskList = () => {
  const { state, dispatch } = useContext(AppContext);
  const [stateStatus, setStateStatus] = useState(INITIAL);
  const alert = useAlert();

  const {
    task: { tasks, selectedTaskListPage, tasksQuery },
    auth: {
      user: { token },
    },
  } = state;

  const exportCheckItem = async (taskId, emails, exportType) => {
    const api = new Api(token);
    const result = await api.exportTaskCheckItems(taskId, emails, exportType);
    if (result && result.success === true) {
      alert.show(ALERT_CHECKITEM_EXPORT_SUCCESS, {
        timeout: 3000,
        type: 'success',
      });
    } else if (result.message === 'CheckedCheckItemNotFoundError') {
      alert.show(ALERT_CHECKITEM_EXPORT_CHECKED_NOT_FOUND_ERROR, {
        timeout: 3000,
        type: 'error',
      });
    } else if (result.message === 'CheckItemNotFoundError') {
      alert.show(ALERT_CHECKITEM_EXPORT_NOT_FOUND_ERROR, {
        timeout: 3000,
        type: 'error',
      });
    } else {
      alert.show(ALERT_CHECKITEM_EXPORT_ERROR, {
        timeout: 3000,
        type: 'error',
      });
    }
  };
  const setSelectedTaskListPage = (page) => {
    dispatch({
      type: SET_SELECTED_TASK_LIST_PAGE,
      payload: {
        selectedTaskListPage: page,
      },
    });
    setStateStatus(FETCHING);
  };

  useEffect(() => {
    dispatch({
      type: SET_SELECTED_TASK_LIST_PAGE,
      payload: {
        selectedTaskListPage: 1,
      },
    });
    setStateStatus(PENDING);
  }, [tasksQuery]);

  useEffect(() => {
    const countTasks = async (token, keyword) => {
      const api = new Api(token);
      const count = await api.countTasks(keyword);
      dispatch({
        type: SET_TASKS_COUNT,
        payload: {
          tasksCount: count,
        },
      });
      setStateStatus(FETCHING);
    };

    const getTasks = async (
      token,
      page = 1,
      limit = 10,
      keyword = undefined
    ) => {
      const api = new Api(token);
      const skip = (page - 1) * limit;
      const taskList = await api.getTasks(limit, skip, keyword);
      dispatch({
        type: CLEAR_SELECTED_TASK,
      });
      dispatch({ type: RESET_TASK });
      dispatch({
        type: SET_TASKS,
        payload: {
          tasks: taskList,
        },
      });

      setStateStatus(SUCCESS);
    };

    switch (stateStatus) {
      case INITIAL:
        setStateStatus(PENDING);
        break;
      case PENDING:
        countTasks(token, tasksQuery);
        break;
      case FETCHING:
        getTasks(token, selectedTaskListPage, 10, tasksQuery);
        break;
      case SUCCESS:
        setStateStatus(RENDERED);
        break;
      default:
        break;
    }
  }, [dispatch, selectedTaskListPage, stateStatus, tasksQuery, token]);

  return stateStatus !== INITIAL ? (
    <div id="task_list">
      <TaskListComponent
        data={stateStatus === RENDERED ? tasks : []}
        setCurrentPage={setSelectedTaskListPage}
        exportCheckItem={exportCheckItem}
      />
    </div>
  ) : (
    <Loading />
  );
};

export default TaskList;
