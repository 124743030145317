import React from 'react';

import { Col, Row } from 'reactstrap';

import EditCheckResultsActionButtonGroup from '../molecules/EditCheckResultsActionButtonGroup';

const TaskEditCheckResultsAction = ({
  onSaveButtonClick,
  onRejectButtonClick,
  onBackButtonClick,
  disableSaveButton,
  disableRejectButton,
}) => (
  <Row className="task-action">
    <Col md={12}>
      <EditCheckResultsActionButtonGroup
        onBackButtonClick={onBackButtonClick}
        onSaveButtonClick={onSaveButtonClick}
        onRejectButtonClick={onRejectButtonClick}
        disableSaveButton={disableSaveButton}
        disableRejectButton={disableRejectButton}
      />
    </Col>
  </Row>
);

export default TaskEditCheckResultsAction;
