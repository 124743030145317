import React from 'react';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faExternalLink } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';

library.add(faExternalLink);

const LinkButton = ({ onClick }) => (
  <Button color="link" className="embed-button" onClick={onClick} block>
    <FontAwesomeIcon icon={['fal', 'external-link']} /> Link
  </Button>
);

export default LinkButton;
