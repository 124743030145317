import React, { useContext, useEffect, useState } from 'react';

import queryString from 'query-string';
import { useAlert } from 'react-alert';
import { Redirect } from 'react-router-dom';

import Loading from '../components/molecules/Loading';
import {
  ALERT_JOIN_TASK_ERROR,
  ALERT_JOIN_TASK_EXPIRED,
  ALERT_JOIN_TASK_SUCCESS,
  ALERT_KEY_NOT_FOUND,
} from '../constants/messages';
import { TASK_LIST } from '../constants/route';
import { INITIAL, PENDING, REDIRECT } from '../constants/state';
import Api from '../services/api';
import { AppContext } from '../store';

const Join = ({ location }) => {
  const [stateStatus, setStateStatus] = useState(INITIAL);
  const { key } = queryString.parse(location.search);
  const { state } = useContext(AppContext);
  const { auth } = state;
  const alert = useAlert();

  useEffect(() => {
    const joinTask = async () => {
      const api = new Api(auth.user.token);
      const { success, isExpired } = await api.joinTask(key);
      if (success) {
        alert.show(ALERT_JOIN_TASK_SUCCESS, {
          timeout: 3000,
          type: 'success',
        });
      } else if (isExpired) {
        alert.show(ALERT_JOIN_TASK_EXPIRED, {
          timeout: 3000,
          type: 'error',
        });
      } else {
        alert.show(ALERT_JOIN_TASK_ERROR, {
          timeout: 3000,
          type: 'error',
        });
      }
      setStateStatus(REDIRECT);
    };

    switch (stateStatus) {
      case INITIAL:
        if (!key) {
          setStateStatus(REDIRECT);
          alert.show(ALERT_KEY_NOT_FOUND, {
            timeout: 3000,
            type: 'error',
          });
        } else {
          setStateStatus(PENDING);
        }
        break;
      case PENDING:
        joinTask();
        break;
      default:
        break;
    }
  });
  return stateStatus === REDIRECT ? <Redirect to={TASK_LIST} /> : <Loading />;
};

export default Join;
