import React from 'react';

import LogoImage from '../../assets/images/logo.png';

const Logo = () => (
  <div className="logo">
    <img src={LogoImage} alt="logo" />
  </div>
);

export default Logo;
