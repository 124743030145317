import React from 'react';

import FadeIn from 'react-fade-in';
import Lottie from 'react-lottie';

import * as loading from '../../assets/json/loading.json';
import * as success from '../../assets/json/success.json';
import { SUCCESS } from '../../constants/state';

const LoadingPage = ({ status }) => {
  const loadingOptions = {
    loop: true,
    autoplay: true,
    animationData: loading.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const successOptions = {
    loop: true,
    autoplay: true,
    animationData: success.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  let Loading;
  if (status === SUCCESS) {
    Loading = <Lottie options={successOptions} height={300} width={300} />;
  } else {
    Loading = <Lottie options={loadingOptions} height={300} width={300} />;
  }

  const style = {
    margin: 0,
    position: 'absolute',
    top: '50%',
    left: '50%',
    msTransform: 'translate(-50%, -50%)',
    transform: 'translate(-50%, -50%)',
  };
  return (
    <div style={style}>
      <FadeIn>
        <div style={{ display: 'flex' }}>{Loading}</div>
      </FadeIn>
    </div>
  );
};

export default LoadingPage;
