import React from 'react';

import CancelButton from '../atoms/CancelButton';
import SubmitButton from '../atoms/SubmitButton';
import ConfirmModal from './ConfirmModal';

const EditUserButtonGroup = ({
  onClickSaveUserButton,
  onClickDeleteUserButton,
  onClickCancelButton,
  disableSaveUserButton,
  disableDeleteUserButton,
  disableCancelButton,
}) => (
  <div className="action-button-group">
    <div className="btn-group-1">
      <CancelButton
        onClick={onClickCancelButton}
        disabled={disableCancelButton === true}
      />
    </div>
    <div className="btn-group-2">
      <ConfirmModal
        onConfirm={onClickDeleteUserButton}
        type="delete"
        modalMessage="Are you sure you want to delete this user?"
        buttonMessage="OK"
        detailMessage="Delete"
        disabled={disableDeleteUserButton === true}
      />
      <SubmitButton
        value="input"
        text="Save"
        onClick={onClickSaveUserButton}
        disabled={disableSaveUserButton === true}
      />
    </div>
  </div>
);

export default EditUserButtonGroup;
