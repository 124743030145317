import React from 'react';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faDownload } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';

library.add(faDownload);

const ExportStatisticButton = ({ disabled, onClick }) => {
  return (
      <Button
        className="export-statistic-button"
        onClick={onClick}
        disabled={disabled}
      >
        <FontAwesomeIcon
          icon={['far', 'download']}
          className="mr-2"
        />
        Export
      </Button>
  );
};

export default ExportStatisticButton;
