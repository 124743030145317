import React, { useEffect } from 'react';

import ReactTags from 'react-tag-autocomplete';

const TagsInputWithSuggestion = ({ ...props }) => {
  useEffect(() => {
    const inputElement = document.getElementsByClassName(
      'react-tags__search-input'
    )[0];
    inputElement.focus();
  }, []);

  useEffect(() => {
    const inputElement = document.getElementsByClassName(
      'react-tags__search-input'
    )[0];
    inputElement.disabled = props.disabled;
  }, [props]);

  return <ReactTags {...props} />;
};

export default TagsInputWithSuggestion;
