import React, { useContext } from 'react';

import { AppContext } from '../../store';
import ExportStatisticButton from '../atoms/ExportStatisticButton';
import TaskStatistic from '../molecules/TaskStatistic';
import TaskStatisticController from '../organisms/TaskStatisticController';

const TaskStatisticContainer = ({
  data,
  taskId,
  exportStatistic,
  isShowExportStatisticButton,
}) => {
  const { state } = useContext(AppContext);
  const {
    auth: { user },
    task: {
      selectedTask: { stats },
    },
  } = state;

  const permissions = user.permissions || [];

  return permissions ? (
    <div className="task-statistic-container">
      <div className="title">Task statistic</div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div className="panel">
          {stats && (
            <TaskStatistic
              checkedCount={stats.checked}
              totalCount={stats.total}
            />
          )}
          {isShowExportStatisticButton && (
            <ExportStatisticButton
              taskId={taskId}
              disabled={data.length === 0}
              onClick={() => exportStatistic()}
            />
          )}
        </div>
      </div>
      <TaskStatisticController data={data} />
    </div>
  ) : (
    ''
  );
};

export default TaskStatisticContainer;
