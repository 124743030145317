import React from 'react';

import AlertTemplate from 'react-alert-template-basic';

const CustomAlertTemplate = ({ message, options, style, close }) => {
  const customColor = {
    success: { backgroundColor: '#e7f2d0', color: '#3e5913' },
    info: {
      backgroundColor: '#D7E5EF',
      color: '#10282c',
    },
    warning: {
      backgroundColor: '#FDF3D0',
      color: '#3F381A',
    },
    error: {
      backgroundColor: '#F4CDD3',
      color: '#2E141C',
    },
  };

  return (
    <AlertTemplate
      message={message}
      options={options}
      style={{ ...style, ...customColor[options.type] }}
      close={close}
    />
  );
};

export default CustomAlertTemplate;
