import React, { useContext } from 'react';

import { Redirect } from 'react-router-dom';

import { LOGIN } from '../constants/route';
import { AppContext } from '../store';
import { isLoggedIn } from '../utils/permission';

const withAuthLoginRequired = (Component) => (props) => {
  const {
    location: { pathname, search: queryString },
  } = props;
  const { state } = useContext(AppContext);
  const { auth } = state;

  return isLoggedIn(auth) ? (
    <Component {...props} />
  ) : (
    <Redirect
      to={{
        pathname: LOGIN,
        afterLogin: `${pathname}${queryString}`,
      }}
    />
  );
};

const withPermission =
  (Component, permissionValidator, redirectTo) => (props) => {
    const { state } = useContext(AppContext);
    const { auth } = state;
    return permissionValidator(auth) ? (
      <Component {...props} />
    ) : (
      <Redirect to={redirectTo} />
    );
  };

export { withAuthLoginRequired, withPermission };
