import React from 'react';

import Body from '../templates/Body';
import Footer from '../templates/Footer';
import Header from '../templates/Header';
import Layout from '../templates/Layout';
import TaskStatisticContainer from '../templates/TaskStatisticContainer';

const TaskStatistic = ({
  data,
  taskId,
  exportStatistic,
  isShowExportStatisticButton,
}) => (
  <Layout>
    <Header />
    <Body>
      <TaskStatisticContainer
        data={data}
        taskId={taskId}
        exportStatistic={exportStatistic}
        isShowExportStatisticButton={isShowExportStatisticButton}
      />
    </Body>
    <Footer />
  </Layout>
);

export default TaskStatistic;
