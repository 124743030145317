import React, { useContext, useEffect, useState } from 'react';

import { useAlert } from 'react-alert';
import { Redirect } from 'react-router-dom';

import Loading from '../components/molecules/Loading';
import {
  ALERT_CHECKITEM_EXPORT_ERROR,
  ALERT_CHECKITEM_EXPORT_SUCCESS,
} from '../constants/messages';
import { TASK_LIST } from '../constants/route';
import {
  FETCHING,
  INITIAL,
  PENDING,
  REDIRECT,
  SUCCESS,
} from '../constants/state';
import Api from '../services/api';
import Excel from '../services/excel';
import { AppContext } from '../store';
import Parser from '../utils/parser';

const TaskExport = ({ match, location }) => {
  const { state } = useContext(AppContext);
  const [stateStatus, setStateStatus] = useState(INITIAL);
  const alert = useAlert();

  const {
    auth: {
      user: { token },
    },
  } = state;

  const { id: selectedTaskId, type: exportType } = match.params;
  const query = new URLSearchParams(location.search);

  useEffect(() => {
    const exportTaskCheckItems = async (token) => {
      const api = new Api(token);
      const result = await api.exportTaskCheckItems(selectedTaskId, exportType);
      if (result) {
        alert.show(ALERT_CHECKITEM_EXPORT_SUCCESS, {
          timeout: 3000,
          type: 'success',
        });
        setStateStatus(SUCCESS);
      } else {
        alert.show(ALERT_CHECKITEM_EXPORT_ERROR, {
          timeout: 3000,
          type: 'error',
        });
        setStateStatus(REDIRECT);
      }
    };

    switch (stateStatus) {
      case INITIAL:
        setStateStatus(PENDING);
        break;
      case PENDING:
        setStateStatus(FETCHING);
        break;
      case FETCHING:
        exportTaskCheckItems(token);
        break;
      case SUCCESS:
        setStateStatus(REDIRECT);
        break;
      default:
        break;
    }
  }, [alert, exportType, selectedTaskId, stateStatus, token]);

  return stateStatus === REDIRECT ? <Redirect path={TASK_LIST} /> : <Loading />;
};

export default TaskExport;
