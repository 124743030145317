import React, { useContext, useEffect, useState } from 'react';

import { Redirect } from 'react-router-dom';

import LoginPage from '../components/views/Login';
import { LOGIN, LOGOUT } from '../constants/actions';
import { JOIN, TASK_LIST } from '../constants/route';
import { INITIAL, PENDING, REDIRECT, RENDERED } from '../constants/state';
import AuthService from '../services/auth';
import { AppContext } from '../store';
import { isLoggedIn } from '../utils/permission';
import { getErrorMessage } from '../utils/responseMessage';

const LoginManagement = ({ location }) => {
  const { afterLogin } = location;
  const { state, dispatch } = useContext(AppContext);
  const [stateStatus, setStateStatus] = useState(INITIAL);
  const [errorMessage, setErrorMessage] = useState('');
  const userLogin = async (username, password) => {
    setErrorMessage('');
    const foundUser = await AuthService.login(username, password);
    const { error, user, token } = foundUser;

    if (error) {
      const message = getErrorMessage(error.code);
      setErrorMessage(message);
    } else {
      dispatch({
        type: LOGIN,
        payload: {
          user: {
            ...user,
            token,
          },
        },
      });

      setStateStatus(REDIRECT);
    }
  };

  const userLogout = () => {
    dispatch({ type: LOGOUT });
  };

  const { auth } = state;

  useEffect(() => {
    switch (stateStatus) {
      case INITIAL:
        setStateStatus(isLoggedIn(auth) === true ? REDIRECT : PENDING);
        break;
      case PENDING:
        userLogout();
        setStateStatus(RENDERED);
        break;
      default:
        break;
    }
  }, [stateStatus, auth]);

  return (
    <>
      {stateStatus === REDIRECT && (
        <Redirect
          to={afterLogin?.split('?')[0] === JOIN ? afterLogin : TASK_LIST}
        />
      )}
      {stateStatus === RENDERED && (
        <div id="login">
          <LoginPage errorMessage={errorMessage} userLogin={userLogin} />
        </div>
      )}
    </>
  );
};

export default LoginManagement;
