import React from 'react';

import EditTaskButtonGroup from '../molecules/EditTaskButtonGroup';
import TaskForm from '../organisms/TaskForm';
import Body from '../templates/Body';
import Footer from '../templates/Footer';
import Header from '../templates/Header';
import Layout from '../templates/Layout';

const TaskEdit = ({
  onClickSaveTaskButton,
  onClickDeleteTaskButton,
  onClickCancelButton,
  disableDeleteTaskButton,
}) => (
  <Layout>
    <Header />
    <Body>
      <div className="task-create-container">
        <div className="title">Edit task</div>
        <div className="task-create-form">
          <TaskForm mode="edit" />
          <EditTaskButtonGroup
            onClickSaveTaskButton={onClickSaveTaskButton}
            onClickDeleteTaskButton={onClickDeleteTaskButton}
            onClickCancelButton={onClickCancelButton}
            disableSaveTaskButton={false}
            disableDeleteTaskButton={disableDeleteTaskButton}
            disableCancelButton={false}
          />
        </div>
      </div>
    </Body>
    <Footer />
  </Layout>
);

export default TaskEdit;
