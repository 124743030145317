import React from 'react';

import Body from '../templates/Body';
import Footer from '../templates/Footer';
import Header from '../templates/Header';
import Layout from '../templates/Layout';
import TaskListContainer from '../templates/TaskListContainer';

const TaskList = ({ data, setCurrentPage, exportCheckItem }) => (
  <Layout>
    <Header />
    <Body>
      <TaskListContainer
        data={data}
        setCurrentPage={setCurrentPage}
        exportCheckItem={exportCheckItem}
      />
    </Body>
    <Footer />
  </Layout>
);

export default TaskList;
