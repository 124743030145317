import React from 'react';

import CancelButton from '../atoms/CancelButton';
import SubmitButton from '../atoms/SubmitButton';
import ConfirmModal from './ConfirmModal';

const EditTaskButtonGroup = ({
  onClickSaveTaskButton,
  onClickDeleteTaskButton,
  onClickCancelButton,
  disableSaveTaskButton,
  disableDeleteTaskButton,
  disableCancelButton,
}) => (
  <div className="action-button-group">
    <div className="btn-group-1">
      <CancelButton
        onClick={onClickCancelButton}
        disabled={disableCancelButton === true}
      />
    </div>
    <div className="btn-group-2">
      <ConfirmModal
        onConfirm={onClickDeleteTaskButton}
        type="delete"
        modalMessage="Are you sure you want to delete this task?"
        buttonMessage="OK"
        detailMessage="Delete"
        disabled={disableDeleteTaskButton === true}
      />
      <SubmitButton
        value="input"
        text="Save"
        onClick={onClickSaveTaskButton}
        disabled={disableSaveTaskButton === true}
      />
    </div>
  </div>
);

export default EditTaskButtonGroup;
