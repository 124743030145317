import React from 'react';

import { Col, Row } from 'reactstrap';

import DeleteIconButton from '../atoms/DeleteIconButton';
import DuplicateButton from '../atoms/DuplicateButton';
import TagsInput from '../atoms/TagsInput';

const ResultView = ({
  result,
  isRejected,
  onClickDuplicateButton,
  onClickDeleteButton,
}) => (
  <Row className="result-view">
    <TagsInput value={result} placeholder="" disabled />
    <DuplicateButton onClick={onClickDuplicateButton} disabled={isRejected} />
    <DeleteIconButton onClick={onClickDeleteButton} />
  </Row>
);

export default ResultView;
