import React from 'react';

import { Col, Row } from 'reactstrap';

const Body = ({ children, className }) => (
  <Row className={`body ${className || ''}`}>
    <Col md={12}>{children}</Col>
  </Row>
);

export default Body;
