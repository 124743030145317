import Excel from 'exceljs';
import saveAs from 'file-saver';

class ExcelService {
  static writeDataToWorkSheet(sheet, data) {
    data.forEach((checkItemWithReject) => {
      sheet.addRow(checkItemWithReject);
    });
  }

  static createDataSheet(workbook, data) {
    const dataSheet = workbook.addWorksheet('data');
    const dataWithoutRejected = data.filter((checkItem) =>
      Object.keys(checkItem).every((key) => key !== '$result-reject')
    );
    if (dataWithoutRejected.length > 0) {
      const columns = Object.keys(dataWithoutRejected[0]).map((key) => ({
        header: key.replace('$result-', ''),
        key,
        bold: true,
      }));
      dataSheet.getRow(1).fill = {
        font: {
          bold: true,
        },
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'cccccc' },
      };
      dataSheet.columns = columns;
      this.writeDataToWorkSheet(dataSheet, dataWithoutRejected);
    }
  }

  static createLogSheet(workbook, data) {
    const dataSheet = workbook.addWorksheet('log');
    if (data.length > 0) {
      const columns = Object.keys(data[0]).map((key) => ({
        header: key,
        key,
        bold: true,
      }));
      dataSheet.getRow(1).fill = {
        font: {
          bold: true,
        },
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'cccccc' },
      };
      dataSheet.columns = columns;
      this.writeDataToWorkSheet(dataSheet, data);
    }
  }

  static writeExcel(data) {
    const workbook = new Excel.Workbook();
    this.createDataSheet(workbook, data);
    return workbook;
  }

  static getHeaders(checkItemType) {
    switch (checkItemType) {
      case 'zocial-eye-v1.0':
        return [
          'Account',
          'Message',
          'Direct URL',
          'Post URL',
          'Comment URL',
          'Reply comment URL',
          'Source',
          'Post time',
          'Engagement',
          'Main keyword',
          'Sub keyword',
          'Follower count',
          'Sentiment',
          'Category',
          'Track post',
          'Track account',
          'Note',
          '_id',
        ];
      default:
        return null;
    }
  }

  static createTemplateSheet(workbook, headers) {
    const dataSheet = workbook.addWorksheet('template');
    if (headers.length > 0) {
      const columns = headers.map((key) => ({
        header: key,
        key,
        bold: true,
      }));
      dataSheet.getRow(1).fill = {
        font: {
          bold: true,
        },
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'cccccc' },
      };
      dataSheet.columns = columns;
    }
  }

  static writeTemplateFile(checkItemType) {
    const workbook = new Excel.Workbook();
    const headers = this.getHeaders(checkItemType);
    this.createTemplateSheet(workbook, headers);
    return workbook;
  }

  static async downloadExcelFile(workbook, fileName) {
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    saveAs(blob, fileName);
  }
}

export default ExcelService;
