import React, { useState } from 'react';

import { useHistory } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

import { TASK_LIST } from '../../constants/route';
import Pagination from '../../utils/pagination';
import { to } from '../../utils/utils';
import ButtonGroup from '../molecules/ButtonGroup';
import SearchBox from '../molecules/SearchBox';
import TaskInvitationLinkController from '../molecules/TaskInvitationLinkController';
import TaskAssignUserController from '../organisms/TaskAssignUserController';

const TaskAssignUserContainer = ({
  userList,
  initialAssignedUser,
  updateAssignedUser,
  createInvitationLink,
}) => {
  const [selectedUserToAssign, setSelectedUserToAssign] =
    useState(initialAssignedUser);
  const [disableSaveButton, setDisableSaveButton] = useState(true);
  const [queriedUsers, setQueriedUsers] = useState(userList);
  const limitPage = 1;
  const limitPageSize = 10;
  const [currentPage, setCurrentPage] = useState(limitPage);
  const total =
    queriedUsers && queriedUsers.length > 0 ? queriedUsers.length : 0;
  const pageSize = limitPageSize;
  const {
    start,
    end,
    size: totalPage,
  } = Pagination.calculate(total, currentPage, pageSize);
  const users = queriedUsers ? queriedUsers.slice(start, end) : [];
  const page = { currentPage, setCurrentPage, totalPage, segmentSize: 5 };
  const history = useHistory();

  const backToTaskList = () => {
    history.push(to(TASK_LIST));
  };

  const updateQueriedUsers = (filter) => {
    const queriedUsers = userList.filter(
      ({ name, username }) =>
        name.toLowerCase().trim().includes(filter.toLowerCase().trim()) ||
        username.toLowerCase().trim().includes(filter.toLowerCase().trim())
    );
    setQueriedUsers(queriedUsers);
  };

  const saveAssignUser = () => {
    updateAssignedUser(selectedUserToAssign);
  };

  const arrayEquals = (a, b) => {
    a.sort();
    b.sort();
    return (
      Array.isArray(a) &&
      Array.isArray(b) &&
      a.length === b.length &&
      a.every((val, index) => val === b[index])
    );
  };

  const updateSelectUserToAssign = (userID) => {
    const newAssignedUser = selectedUserToAssign.includes(userID)
      ? selectedUserToAssign.filter((item) => item !== userID)
      : [...selectedUserToAssign, userID];
    const isDuplicatedAssignedUser = arrayEquals(
      newAssignedUser,
      initialAssignedUser
    );
    setDisableSaveButton(isDuplicatedAssignedUser);
    setSelectedUserToAssign(newAssignedUser);
  };

  return (
    <div className="task-assign-user-container">
      <div className="title">Task assign user</div>
      <TaskInvitationLinkController
        createInvitationLink={createInvitationLink}
      />
      <SearchBox onKeyEnterDown={updateQueriedUsers} icon />
      <TaskAssignUserController
        users={users}
        page={page}
        updateSelectUserToAssign={updateSelectUserToAssign}
        selectedUserToAssign={selectedUserToAssign}
      />
      <Row className="task-action">
        <Col md={12}>
          <ButtonGroup
            onSaveButtonClick={saveAssignUser}
            onBackButtonClick={backToTaskList}
            disableSaveButton={disableSaveButton}
          />
        </Col>
      </Row>
    </div>
  );
};

export default TaskAssignUserContainer;
