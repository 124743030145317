import React, { useState } from 'react';

import { Form, FormGroup, FormText, Label } from 'reactstrap';

import { readCsvFile, readExcelFile } from '../../utils/file';
import { getExcelCellValue } from '../../utils/utils';
import FileInput from '../atoms/FileInput';
import TaskDownloadCheckItemTemplate from '../molecules/TaskDownloadCheckItemTemplate';
import TextImportOptions from '../molecules/TextImportOptions';

const TaskAddCheckItemByUploadFileController = ({
  taskConfig,
  setDisabledSaveButton,
  setModeChangeDisabled,
  data,
  setData,
}) => {
  const [columns, setColumns] = useState([]);
  const [isValidFile, setIsValidFile] = useState(true);

  const getColumnsFromData = async (data) => setColumns(Object.keys(data[0]));

  const loadCsvFile = async (file) => {
    const reader = new FileReader();
    reader.addEventListener('load', async (event) => {
      const data = await readCsvFile(event.target.result);
      const formattedData = data.data.map(({ '': _, ...d }) => ({
        ...d,
      }));
      getColumnsFromData(formattedData);
      setData(formattedData);
      setIsValidFile(true);
      if (taskConfig?.checkItemType !== 'text') {
        setDisabledSaveButton(false);
        setModeChangeDisabled(false);
      }
    });
    reader.readAsText(file);
  };

  const loadExcelFile = async (file) => {
    const reader = new FileReader();
    reader.addEventListener('load', async (event) => {
      const result = await readExcelFile(event.target.result);
      const datas = [];
      const worksheet = result.worksheets[0];
      const columns = worksheet.getRow(1);
      worksheet.spliceRows(0, 1);
      worksheet.eachRow((row) => {
        const checkItem = {};
        columns.values.forEach((col, i) => {
          if (i > 0) {
            const cell = row.getCell(i);
            const cellValue = getExcelCellValue(cell);
            checkItem[col] = cellValue;
          }
        });
        datas.push(checkItem);
      });

      getColumnsFromData(datas);
      setData(datas);
      setIsValidFile(true);
      if (taskConfig?.checkItemType !== 'text') {
        setDisabledSaveButton(false);
        setModeChangeDisabled(false);
      }
    });
    reader.readAsArrayBuffer(file);
  };

  const readFile = async (file) => {
    const excelType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const csvType = 'text/csv';
    setDisabledSaveButton(true);
    setModeChangeDisabled(true);

    if (!file) {
      setColumns([]);
      setIsValidFile(true);
      setData(null);
      setDisabledSaveButton(true);
      setModeChangeDisabled(true);
      return;
    }

    if (file.type && file.type !== excelType && file.type !== csvType) {
      setIsValidFile(false);
      setData(null);
      setDisabledSaveButton(true);
      setModeChangeDisabled(true);
      return;
    }

    if (file.type === excelType) {
      await loadExcelFile(file);
    }

    if (file.type === csvType) {
      await loadCsvFile(file);
    }
  };

  return (
    <div className="task-add-checkitem">
      <div className="task-add-checkitem-form">
        <TaskDownloadCheckItemTemplate />
        <Form className="form">
          <FormGroup>
            <Label for="task-config">Upload</Label>
            <FileInput
              id="exampleCustomFileBrowser"
              onChange={async (e) => {
                await readFile(e.target.files[0]);
              }}
            />
            <FormText color={isValidFile ? 'muted' : 'danger'}>
              {isValidFile
                ? 'Only excel and csv files are supported.'
                : 'File is not an excel or csv.'}
            </FormText>
          </FormGroup>
          {taskConfig?.checkItemType === 'text' && columns.length > 0 && (
            <TextImportOptions
              columns={columns}
              data={data}
              setData={setData}
              setDisabledSaveButton={setDisabledSaveButton}
            />
          )}
        </Form>
      </div>
    </div>
  );
};

export default TaskAddCheckItemByUploadFileController;
