import React, { useState, useContext } from 'react';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faFileExport } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OverlayTrigger, Tooltip, CloseButton } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Toggle from 'react-toggle';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import CancelButton from './CancelButton';
import SaveButton from './SaveButton';
import TextInput from './TextInput';
import { AppContext } from '../../store';
import { validateEmail } from '../../utils/utils';

library.add(faFileExport);

const ExportButton = ({ taskId, userEmail, exportCheckItem }) => {
  const { state, dispatch } = useContext(AppContext);

  const [isCheckedOnly, setIsCheckedOnly] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [emails, setEmails] = useState(userEmail ? [userEmail] : []);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(
    !validateEmail(userEmail)
  );
  const history = useHistory();

  const updateFieldValue = (field, value) => {
    dispatch({ type: 'UPDATE_TASK_EXPORT_VALUE', payload: { field, value } });
  };

  const toggle = () => setIsOpenModal(!isOpenModal);

  const handleExportCheckItem = (taskId, isCheckedOnly, emails) => {
    exportCheckItem(taskId, isCheckedOnly, emails);
    toggle();
  };

  const validateEmails = (emails) =>
    emails !== null && emails.every((email) => validateEmail(email));

  const inputEmail = ({ target }) => {
    const emails = target.value.split(',');
    setEmails(emails);
    setIsSaveButtonDisabled(!validateEmails(emails));
  };

  return (
    <>
      <OverlayTrigger
        key="top"
        placement="top"
        overlay={
          <Tooltip id={`${taskId}-tooltip-export-check-item-button`}>
            <strong>EXPORT CHECK ITEM</strong>
          </Tooltip>
        }
      >
        <Button
          className="start-button btn-outline"
          onClick={toggle}
          id={`${taskId}-export-check-item-button`}
        >
          <FontAwesomeIcon icon={['far', 'file-export']} />
        </Button>
      </OverlayTrigger>
      <Modal
        isOpen={isOpenModal}
        returnFocusAfterClose={false}
        toggle={toggle}
        id="task_export"
      >
        <ModalHeader>
          <div className="task-export-type-controller">
            All
            <Toggle
              defaultChecked={isCheckedOnly}
              icons={false}
              onChange={() => {
                setIsCheckedOnly(!isCheckedOnly);
              }}
            />
            Checked
          </div>
          <CloseButton onClick={toggle} />
        </ModalHeader>
        <ModalBody>
          <h4>Input the email and select the export type.</h4>
          <p className="text-small">
            Select Export only checked items or all items. You may export to
            multiple e-mail addresses by separating multiple e-mail addresses by
            commas.
          </p>

          <TextInput
            name="email"
            placeholder="Email"
            value={emails}
            onChange={inputEmail}
            className={validateEmails(emails) ? '' : 'is-invalid'}
          />
        </ModalBody>
        <ModalFooter>
          <CancelButton onClick={toggle} />
          <SaveButton
            onClick={() =>
              handleExportCheckItem(
                taskId,
                emails,
                isCheckedOnly ? 'checked' : 'all'
              )
            }
            message="Export"
            disabled={isSaveButtonDisabled}
          />
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ExportButton;
