import React, { useContext, useEffect, useState } from 'react';

import copy from 'copy-to-clipboard';
import delay from 'delay';
import { useAlert } from 'react-alert';
import { Redirect } from 'react-router-dom';

import Loading from '../components/molecules/Loading';
import TaskAssignUserComponent from '../components/views/TaskAssignUser';
import {
  CREATE_INVITE_LINK,
  SAVE_ASSIGN_USER,
  SET_SELECTED_TASK,
  UPDATE_INVITE_LINK,
} from '../constants/actions';
import {
  ALERT_TASK_CREATE_INVITATION_LINK_FAILED,
  ALERT_TASK_CREATE_INVITATION_LINK_SUCCESS,
  ALERT_TASK_NOT_FOUND_ERROR,
  ALERT_USER_ASSIGNED_ERROR,
  ALERT_USER_ASSIGNED_SUCCESS,
} from '../constants/messages';
import { TASK_LIST } from '../constants/route';
import {
  FETCHING,
  INITIAL,
  PENDING,
  REDIRECT,
  RENDERED,
} from '../constants/state';
import Api from '../services/api';
import { AppContext } from '../store';
import { canManageAnyTask } from '../utils/permission';

const TaskAssignUser = ({ match }) => {
  const { state, dispatch } = useContext(AppContext);
  const [stateStatus, setStateStatus] = useState(INITIAL);
  const [userList, setUserList] = useState(null);
  const [assignedUsers, setAssignedUsers] = useState(null);
  const [taskCreatedBy, setTaskCreatedBy] = useState(null);
  const alert = useAlert();

  const { auth } = state;

  const { id: taskId } = match.params;

  const updateAssignedUser = (selectedUserToAssign) => {
    setAssignedUsers(selectedUserToAssign);
    setStateStatus(SAVE_ASSIGN_USER);
  };

  const createInvitationLink = () => {
    setStateStatus(CREATE_INVITE_LINK);
  };

  useEffect(() => {
    const getUsers = async (token) => {
      const api = new Api(token);
      const users = await api.getUsers();
      const checkers = users.filter(
        (user) => user.id !== taskCreatedBy && !canManageAnyTask({ user })
      );
      setUserList(checkers);
    };

    const getTaskInvitationLink = async (token, taskId) => {
      const api = new Api(token);
      const key = await api.getTaskInvitationKey(taskId);
      const link = `${process.env.REACT_APP_CHK_APP_HOST}/join?key=${key}`;
      copy(link);

      if (link && link.length > 0) {
        dispatch({ type: UPDATE_INVITE_LINK, payload: { link } });
        alert.removeAll();
        await delay(200);
        alert.show(ALERT_TASK_CREATE_INVITATION_LINK_SUCCESS, {
          timeout: 3000,
          type: 'success',
        });
      } else {
        alert.removeAll();
        await delay(200);
        alert.show(ALERT_TASK_CREATE_INVITATION_LINK_FAILED, {
          timeout: 3000,
          type: 'error',
        });
      }

      setStateStatus(RENDERED);
    };

    const getTask = async (token, taskId) => {
      const api = new Api(token);
      const task = await api.getTask(taskId);

      if (task && task.id) {
        dispatch({
          type: SET_SELECTED_TASK,
          payload: { selectedTask: task },
        });
        setAssignedUsers(task.assignedTo);
        setTaskCreatedBy(task.createdBy.id);
      } else {
        setStateStatus(REDIRECT);
        alert.show(ALERT_TASK_NOT_FOUND_ERROR, {
          timeout: 3000,
          type: 'error',
        });
      }
    };

    const assignUsersToTask = async (token, taskId, assignedUsers) => {
      const unassignedUsers = await userList
        .map(({ id }) => id)
        .filter((id) => !assignedUsers.includes(id));

      const api = new Api(token);

      let responseAssigned = { status: 'success' };
      let responseUnassigned = { status: 'success' };

      if (assignedUsers.length > 0) {
        responseAssigned = await api.assignUsersToTask(taskId, assignedUsers);
      }

      if (unassignedUsers.length > 0) {
        responseUnassigned = await api.unassignUsersToTask(
          taskId,
          unassignedUsers
        );
      }

      if (
        responseAssigned.status === 'success' &&
        responseUnassigned.status === 'success'
      ) {
        alert.show(ALERT_USER_ASSIGNED_SUCCESS, {
          timeout: 3000,
          type: 'success',
        });
        setStateStatus(PENDING);
      } else {
        alert.show(ALERT_USER_ASSIGNED_ERROR, {
          timeout: 3000,
          type: 'error',
        });
      }
      setStateStatus(RENDERED);
    };

    switch (stateStatus) {
      case INITIAL:
        dispatch({
          type: UPDATE_INVITE_LINK,
          payload: {
            link: '',
          },
        });
        setStateStatus(PENDING);
        break;
      case PENDING:
        setStateStatus(FETCHING);
        break;
      case FETCHING:
        getTask(auth.user.token, taskId);
        if (taskCreatedBy) {
          getUsers(auth.user.token);
        }
        if (userList && assignedUsers) {
          setStateStatus(RENDERED);
        }
        break;
      case SAVE_ASSIGN_USER:
        assignUsersToTask(auth.user.token, taskId, assignedUsers);
        break;
      case CREATE_INVITE_LINK:
        getTaskInvitationLink(auth.user.token, taskId);
        break;
      default:
        break;
    }
  }, [stateStatus, auth.user.token, taskId, assignedUsers]);

  return (
    <>
      {stateStatus === REDIRECT && <Redirect to={TASK_LIST} />}
      {stateStatus === RENDERED || stateStatus === CREATE_INVITE_LINK ? (
        <div id="task_assign_user">
          <TaskAssignUserComponent
            userList={userList}
            initialAssignedUser={assignedUsers}
            updateAssignedUser={updateAssignedUser}
            createInvitationLink={createInvitationLink}
          />
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default TaskAssignUser;
