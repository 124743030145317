import React from 'react';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faCircleNotch } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';

library.add(faCircleNotch);

const SaveButton = ({ loading, message, ...props }) => (
  <Button className="save-button" {...props}>
    {loading === true ? (
      <>
        <FontAwesomeIcon icon="circle-notch" className="spin" />{' '}
        <span>Saving</span>
      </>
    ) : (
      <span>{message || 'Save'}</span>
    )}
  </Button>
);

export default SaveButton;
