import React from 'react';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';

library.add(faSearch);

const SearchButton = ({ message, ...props }) => (
  <Button className="search-button" {...props}>
    <span>{message || <FontAwesomeIcon icon={['far', 'search']} />}</span>
  </Button>
);

export default SearchButton;
