import React, { useContext, useEffect, useMemo } from 'react';

import {
  Form,
  FormGroup,
  FormFeedback,
  Input,
  Label,
  InputGroup,
  InputGroupAddon,
} from 'reactstrap';

import { AppContext } from '../../store';
import Datetime from '../../utils/datetime';
import SyncButton from '../atoms/SyncButton';
import ConfirmModal from '../molecules/ConfirmModal';

const TaskAddCheckItemBySyncZocialEyeController = ({
  setDisabledSaveButton,
  syncTask,
}) => {
  const { state, dispatch } = useContext(AppContext);
  const currentSyncScheduler = state.task.sync;

  const campaignID = useMemo(() => currentSyncScheduler?.campaignID || '', []);
  const cronInSecond = useMemo(
    () => currentSyncScheduler?.cronInSecond || 43200,
    []
  );
  const estimatedSyncCompletion = useMemo(
    () =>
      Datetime.toMomentWithTimezone(currentSyncScheduler?.nextRun, 'UTC').add(
        30,
        'minutes'
      ),
    [currentSyncScheduler?.nextRun]
  );

  const updateFieldValue = (field, value) => {
    dispatch({ type: 'UPDATE_SYNC_VALUE', payload: { field, value } });
  };

  useEffect(() => {
    const checkSyncSchedulerHaveChanged = (newSetting, oldSetting) => {
      const isTheSameScheduler =
        newSetting.campaignID === oldSetting.campaignID &&
        newSetting.cronInSecond === oldSetting.cronInSecond;
      setDisabledSaveButton(isTheSameScheduler);
    };

    checkSyncSchedulerHaveChanged(currentSyncScheduler, {
      campaignID,
      cronInSecond,
    });
  }, [currentSyncScheduler]);

  return (
    <div className="task-add-checkitem">
      <div className="task-add-checkitem-form">
        <Form className="form">
          <FormGroup>
            <Label for="sync-campaign-id">Campaign ID</Label>
            <Input
              id="sync-campaign-id"
              name="campaignID"
              type="text"
              placeholder=""
              invalid={currentSyncScheduler.campaignID === ''}
              value={currentSyncScheduler.campaignID}
              onChange={(e) => updateFieldValue(e.target.name, e.target.value)}
            />
            <FormFeedback>Invalid campaign id.</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="sync-interval">Interval</Label>
            <Input
              id="sync-interval"
              type="select"
              name="cronInSecond"
              onChange={(e) => {
                updateFieldValue('cronInSecond', Number(e.target.value));
              }}
            >
              <option value={10800} selected={cronInSecond === 10800}>
                every 3 hours
              </option>
              <option value={21600} selected={cronInSecond === 21600}>
                every 6 hours
              </option>
              <option value={43200} selected={cronInSecond === 43200}>
                every 12 hours
              </option>
              <option value={86400} selected={cronInSecond === 86400}>
                every 24 hours
              </option>
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="sync-last-run">Last Sync</Label>
            <Input
              id="sync-last-run"
              name="lastRun"
              type="text"
              defaultValue={
                currentSyncScheduler?.lastRun &&
                Datetime.toFormat(
                  currentSyncScheduler.lastRun,
                  'YYYY-MM-DD[T]HH:mm:ssz[Z]',
                  'MMMM DD, YYYY HH:mm',
                  'UTC',
                  'Asia/Bangkok'
                )
              }
              disabled
            />
          </FormGroup>
          <FormGroup>
            <Label for="sync-estimated-completion">
              Next Sync (Estimated completion time)
            </Label>
            <InputGroup>
              <Input
                id="sync-estimated-completion"
                name="nextRun"
                type="text"
                defaultValue={
                  currentSyncScheduler?.isFinished
                    ? 'Sync completed'
                    : currentSyncScheduler?.nextRun &&
                      Datetime.toFormat(
                        estimatedSyncCompletion,
                        'YYYY-MM-DD[T]HH:mm:ssz[Z]',
                        'MMMM DD, YYYY HH:mm',
                        'UTC',
                        'Asia/Bangkok'
                      )
                }
                disabled
              />
              <InputGroupAddon addonType="append">
                <ConfirmModal
                  onConfirm={syncTask}
                  type="custom"
                  button={SyncButton}
                  modalMessage="Are you sure you want to force re-sync this task?"
                  buttonMessage="OK"
                  detailMessage="FORCE RE-SYNC"
                  disabled={!currentSyncScheduler?.isFinished}
                />
              </InputGroupAddon>
            </InputGroup>
          </FormGroup>
        </Form>
      </div>
    </div>
  );
};

export default TaskAddCheckItemBySyncZocialEyeController;
