import Excel from 'exceljs';

const isDuplicateArrayValue = (nestedArr, arr) =>
  nestedArr.some((value) => JSON.stringify(value) === JSON.stringify(arr));

const isTaskScheduleActive = (schedule) => {
  const { start, end } = schedule;
  const now = new Date();
  return new Date(start) <= now && now <= new Date(end);
};

const validateAlphanumeric = (value) => {
  if (value === null) return true;
  const re = /^[a-z0-9.\-@]+$/i;
  return re.test(value);
};

const validateAlphanumericWithSpace = (value) => {
  if (value === null) return true;
  const re = /^[a-z0-9.\-\s]+$/i;
  return re.test(value);
};

const validateLength = (value) => {
  if (value === null) return true;
  return value.length >= 5;
};

const validateEmail = (email) => {
  if (email === null) return true;
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const getLeavesNode = (root) => {
  const leavesNode = [];

  root.walk((node) => {
    if (node.children.length === 0) leavesNode.push(node);
  });
  return leavesNode;
};

const to = (route, ...args) => {
  const pattern = /:.*?(?=\/|\?)|:.*?$/;
  const reducer = (acc, cur) => acc.replace(pattern, cur);
  const path = args.reduce(reducer, route);
  return path;
};

const validateCheckableitemLimit = (limit) => limit <= 20 && limit >= 1;

/**
 * Get ExcelJS cell value based on its type
 * @param {import('exceljs').Cell} cell
 * @returns {string}
 */
const getExcelCellValue = (cell) => {
  switch (cell.type) {
    case Excel.ValueType.Formula:
      if (cell.value.result) return cell.value.result;

      switch (cell.value.formula.toLowerCase()) {
        case '0':
          return 0;
        case 'false':
          return false;
        case '""':
          return '';
        default:
          return `=${cell.value.formula}`;
      }

    case Excel.ValueType.Hyperlink:
      return cell.value.hyperlink;

    case Excel.ValueType.Error:
      return cell.value.error;

    case Excel.ValueType.RichText:
      return cell.value.richText.map((richText) => richText.text).join('');

    case Excel.ValueType.String:
    case Excel.ValueType.Number:
    case Excel.ValueType.Merge:
    case Excel.ValueType.SharedString:
    case Excel.ValueType.Boolean:
    case Excel.ValueType.Date:
      return cell.value;

    case Excel.ValueType.Null:
    default:
      return null;
  }
};

export {
  to,
  isDuplicateArrayValue,
  isTaskScheduleActive,
  validateAlphanumeric,
  validateEmail,
  validateLength,
  validateAlphanumericWithSpace,
  getExcelCellValue,
  getLeavesNode,
  validateCheckableitemLimit,
};
