import React from 'react';

import { Card, CardBody, CardHeader, CardText, Col, Row } from 'reactstrap';

import ImageView from './ImageView';
import InformationView from './InformationView';

const ParentView = ({ post, comment, place }) => (
  <div className="parent-view">
    {post && (
      <Card>
        <CardHeader>Post</CardHeader>
        <CardBody>
          <CardText>
            <Row>
              <Col md={12}>{post.message}</Col>
              {post.imageUrl && (
                <Col md={12}>
                  <ImageView imageUrl={post.imageUrl} />
                </Col>
              )}
            </Row>
          </CardText>
          <CardText>
            <InformationView
              place={place}
              name={post.displayName}
              postTime={post.postTime}
            />
          </CardText>
        </CardBody>
      </Card>
    )}
    {comment && (
      <Card>
        <CardHeader>Comment</CardHeader>
        <CardBody>
          <Row>
            <Col md={12}>{comment.message}</Col>
            {comment.imageUrl && (
              <Col md={12}>
                <ImageView imageUrl={comment.imageUrl} />
              </Col>
            )}
          </Row>
          <CardText>
            <InformationView
              place={place}
              name={comment.displayName}
              postTime={comment.postTime}
            />
          </CardText>
        </CardBody>
      </Card>
    )}
  </div>
);

export default ParentView;
