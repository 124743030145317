import React from 'react';

const ReadMoreButton = ({ isTruncated, toggle }) => (
  <button
    id="readmore"
    type="button"
    onClick={() => toggle(!isTruncated)}
    style={{ cursor: 'pointer' }}
  >
    {isTruncated ? 'Show less' : 'Read more'}
  </button>
);
export default ReadMoreButton;
