import React from 'react';

import { Col, Row } from 'reactstrap';

import EditCheckResultsActionButtonGroupNotFound from '../molecules/EditCheckResultsActionButtonGroupNotFound';

const TaskEditCheckResultsActionNotFound = ({ onBackButtonClick }) => (
  <Row className="task-action">
    <Col md={12}>
      <EditCheckResultsActionButtonGroupNotFound
        onBackButtonClick={onBackButtonClick}
      />
    </Col>
  </Row>
);

export default TaskEditCheckResultsActionNotFound;
