import React from 'react';

const Image = ({ src, alt, onError = '' }) => (
  <img
    src={src}
    alt={alt}
    onError={(e) => {
      e.target.src = onError;
    }}
  />
);

export default Image;
