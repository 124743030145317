import React from 'react';

import { Button } from 'reactstrap';

const ResultButton = ({ label, ...props }) => (
  <Button className="result-button" color="secondary" {...props}>
    {label}
  </Button>
);

export default ResultButton;
