import React from 'react';

import { Button } from 'reactstrap';

const CancelButton = (props) => (
  <Button className="back-button" color="link" {...props}>
    Cancel
  </Button>
);

export default CancelButton;
