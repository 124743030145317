import React from 'react';

import FacebookPostEmbed from '../atoms/FacebookPostEmbed';
// import FacebookPostEmbed from '../atoms/FacebookPostSDKEmbed';
import InstagramPostEmbed from '../atoms/InstagramPostEmbed';
import XEmbed from '../atoms/XEmbed';
import YouTubeEmbed from '../atoms/YouTubeEmbed';

const EmbedView = ({ channel, url }) => {
  let Embed;

  if (url) {
    if (channel === 'facebook') {
      Embed = FacebookPostEmbed;
    } else if (channel === 'twitter') {
      Embed = XEmbed;
    } else if (channel === 'instagram') {
      Embed = InstagramPostEmbed;
    } else if (channel === 'youtube') {
      Embed = YouTubeEmbed;
    } else {
      Embed = () => <></>;
    }
  }

  return (
    <div
      className="embed-view"
      style={{
        margin: 'auto',
        maxWidth: '350px',
        height: '100%',
      }}
    >
      {url ? <Embed url={url} /> : ''}
    </div>
  );
};

export default EmbedView;
