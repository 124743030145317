import React, { useContext, useEffect, useState } from 'react';

import Loading from '../components/molecules/Loading';
import UserListComponent from '../components/views/UserList';
import { RESET_USER } from '../constants/actions';
import {
  FETCHING,
  INITIAL,
  PENDING,
  RENDERED,
  SUCCESS,
} from '../constants/state';
import Api from '../services/api';
import { AppContext } from '../store';

const UserList = () => {
  const { state, dispatch } = useContext(AppContext);
  const [stateStatus, setStateStatus] = useState(INITIAL);
  const [users, setUsers] = useState(null);

  const {
    auth: {
      user: { id, token },
    },
  } = state;

  useEffect(() => {
    const sortUsers = (users, ownId) => {
      let sortedUsers = [];
      users.forEach((user) => {
        if (user.id === ownId) {
          sortedUsers = [user, ...sortedUsers];
        } else {
          sortedUsers = [...sortedUsers, user];
        }
      });
      return sortedUsers;
    };

    const getUsers = async (token) => {
      const api = new Api(token);
      const userList = await api.getUsers();
      dispatch({ type: RESET_USER });
      setUsers(sortUsers(userList, id));
      setStateStatus(SUCCESS);
    };

    switch (stateStatus) {
      case INITIAL:
        setStateStatus(PENDING);
        break;
      case PENDING:
        setStateStatus(FETCHING);
        break;
      case FETCHING:
        getUsers(token);
        break;
      case SUCCESS:
        setStateStatus(RENDERED);
        break;
      default:
        break;
    }
  }, [dispatch, stateStatus, token]);

  return stateStatus === RENDERED ? (
    <div id="user_list">
      <UserListComponent data={users} />
    </div>
  ) : (
    <Loading status={stateStatus} />
  );
};

export default UserList;
