import React from 'react';

import { FormGroup, FormText, Input, Label } from 'reactstrap';

import DateRangePicker from '../atoms/DateRangePicker';

const ZocialEyeV2DataFilter = ({ dataFilter, setDataFilter }) => {
  const sentiments = {
    Negative: 'Negative',
    Neutral: 'Neutral',
    Positive: 'Positive',
  };
  const updateSentiment = (sentiment) => {
    let sentimentList = dataFilter.sentiment
      ? dataFilter.sentiment
      : Object.values(sentiments);
    if (sentimentList.includes(sentiment)) {
      sentimentList = sentimentList.filter((item) => item !== sentiment);
      // sentimentList = sentimentList.length > 0 ? sentimentList : undefined;
    } else {
      sentimentList = [...sentimentList, sentiment];
    }
    setDataFilter({
      version: dataFilter.version,
      postTime: dataFilter.postTime,
      sentiment: sentimentList,
      source: dataFilter.source,
    });
  };

  const sources = {
    Facebook: 'facebook',
    X: 'twitter',
    Instagram: 'instagram',
    YouTube: 'youtube',
    TikTok: 'tiktok',
    Forum: 'forum',
    News: 'news',
    Other: 'other',
  };
  const updateSource = (source) => {
    let sourceList = dataFilter.source
      ? dataFilter.source
      : Object.values(sources);
    if (sourceList.includes(source)) {
      sourceList = sourceList.filter((item) => item !== source);
    } else {
      sourceList = [...sourceList, source];
    }
    setDataFilter({
      version: dataFilter.version,
      postTime: dataFilter.postTime,
      sentiment: dataFilter.sentiment,
      source: sourceList,
    });
  };

  return (
    <>
      <FormGroup>
        <Label for="zocialeyedatafilter-posttime">Post time</Label>
        <DateRangePicker
          startDateTime={dataFilter.postTime.start}
          endDateTime={dataFilter.postTime.end}
          onApply={(_, picker) => {
            setDataFilter({
              version: dataFilter.version,
              postTime: {
                start: picker.startDate,
                end: picker.endDate,
              },
              sentiment: dataFilter.sentiment,
              source: dataFilter.source,
            });
          }}
        />
      </FormGroup>
      <FormGroup>
        <Label for="zocialeyedatafilter-sentiment">Sentiment</Label>
        <FormGroup check className="zocialeyedatafilter-sentiment-checkbox">
          {Object.keys(sentiments).map((key) => (
            <Label key={key}>
              <Input
                type="checkbox"
                name={sentiments[key]}
                checked={
                  dataFilter.sentiment &&
                  dataFilter.sentiment.includes(sentiments[key])
                }
                onChange={() => updateSentiment(sentiments[key])}
              />{' '}
              {key}
            </Label>
          ))}
        </FormGroup>
        {(dataFilter.sentiment === undefined ||
          dataFilter.sentiment.length === 0) && (
          <FormText color="danger">Must include 1 sentiment at least.</FormText>
        )}
      </FormGroup>
      <FormGroup>
        <Label for="zocialeyedatafilter-source">Source</Label>
        <FormGroup check className="zocialeyedatafilter-source-checkbox">
          {Object.keys(sources).map((key) => (
            <span key={key}>
              <Label>
                <Input
                  type="checkbox"
                  name={sources[key]}
                  checked={
                    dataFilter.source &&
                    dataFilter.source.includes(sources[key])
                  }
                  onChange={() => updateSource(sources[key])}
                />{' '}
                {key}
              </Label>
            </span>
          ))}
        </FormGroup>
        {(dataFilter.source === undefined ||
          dataFilter.source.length === 0) && (
          <FormText color="danger">Must include 1 source at least.</FormText>
        )}
      </FormGroup>
    </>
  );
};

export default ZocialEyeV2DataFilter;
