import React from 'react';

import Body from '../templates/Body';
import Footer from '../templates/Footer';
import Header from '../templates/Header';
import Layout from '../templates/Layout';
import TaskAssignUserContainer from '../templates/TaskAssignUserContainer';

const TaskAssignUser = ({
  userList,
  initialAssignedUser,
  updateAssignedUser,
  createInvitationLink,
}) => (
  <Layout>
    <Header />
    <Body>
      <TaskAssignUserContainer
        userList={userList}
        initialAssignedUser={initialAssignedUser}
        updateAssignedUser={updateAssignedUser}
        createInvitationLink={createInvitationLink}
      />
    </Body>
    <Footer />
  </Layout>
);

export default TaskAssignUser;
