import React from 'react';

import { Col, Row, Table } from 'reactstrap';

import Pagination from '../molecules/Pagination';
import TableHeader from '../molecules/TableHeader';
import UserListBody from '../molecules/UserListBody';

const UserListController = ({ users, page }) => {
  const tableHeader = [
    { id: 'name', label: 'Name', width: 140 },
    { id: 'username', label: 'Username', width: 80 },
    { id: 'created_by', label: 'Created by', width: 240 },
    { id: 'action', label: 'Action', width: 50 },
  ];
  const { currentPage, setCurrentPage, totalPage, segmentSize } = page;

  return (
    <Row className="user-table">
      <Col md={12} className="table">
        <Table className="user-list-table" responsive striped bordered>
          <TableHeader data={tableHeader} />
          <UserListBody data={users} />
        </Table>
      </Col>
      <Col md={12} className="pagination">
        <Pagination
          currentPage={currentPage}
          totalPage={totalPage}
          setCurrentPage={setCurrentPage}
          segmentSize={segmentSize}
        />
      </Col>
    </Row>
  );
};

export default UserListController;
