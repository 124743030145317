import React from 'react';

import CancelButton from '../atoms/CancelButton';
import SubmitButton from '../atoms/SubmitButton';

const CreateUserButtonGroup = ({
  onClickCreateUserButton,
  onClickCancelButton,
  disableCreateUserButton,
  disableCancelButton,
}) => (
  <div className="action-button-group">
    <div className="btn-group-1">
      <CancelButton
        onClick={onClickCancelButton}
        disabled={disableCancelButton === true}
      />
    </div>
    <div className="btn-group-2">
      <SubmitButton
        value="input"
        text="Create user"
        onClick={onClickCreateUserButton}
        disabled={disableCreateUserButton === true}
      />
    </div>
  </div>
);

export default CreateUserButtonGroup;
