import Excel from 'exceljs';
import Papa from 'papaparse';
import readXlsxFile from 'read-excel-file';

const read = async (pathFile) => {
  const xlsxFile = await readXlsxFile(pathFile);
  return xlsxFile;
};

async function readExcelFile(data, type = 'buffer') {
  const workbook = new Excel.Workbook();
  if (type === 'buffer') {
    await workbook.xlsx.load(data);
  } else if (type === 'file') {
    await workbook.xlsx.readFile(data);
  }

  return workbook;
}

function workbookToJson(workbook) {
  const worksheet = workbook.worksheets[0]; // Excel file will always have at least one worksheet
  const data = [];
  const firstRow = worksheet.getRow(1);
  const headers = firstRow.values;

  worksheet.spliceRows(0, 1);
  worksheet.eachRow((row) => {
    const rowObject = {};
    for (let i = 1; i < headers.length; i += 1) {
      rowObject[`${headers[i]}`] = row.values[i] || '';
    }
    data.push(rowObject);
  });

  return data;
}

async function readCsvFile(data) {
  return Papa.parse(data, { skipEmptyLines: true, header: true });
}

export { read, readExcelFile, readCsvFile, workbookToJson };
