import React from 'react';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faFileChartLine } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Button } from 'reactstrap';

library.add(faFileChartLine);

const StatisticButton = ({ taskId, onClick }) => {
  return (
    <OverlayTrigger
      key="top"
      placement="top"
      overlay={
        <Tooltip id={`${taskId}-tooltip-statistic-button`}>
          <strong>STATISTIC</strong>
        </Tooltip>
      }
    >
      <Button
        className="start-button btn-outline"
        onClick={onClick}
        id={`${taskId}-statistic-button`}
      >
        <FontAwesomeIcon icon={['far', 'file-chart-line']} />
      </Button>
    </OverlayTrigger>
  );
};

export default StatisticButton;
