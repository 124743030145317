/* eslint-disable no-param-reassign */
const getRandomInt = (max) => Math.floor(Math.random() * max);

const constructPath = (configuration, maxPath) => {
  // example result
  // [
  //   {
  //     name: 'ดีแทค > กิจกรรม > ไม่แจกของรางวัล > คำชื่นชม > การแนะนำ',
  //     path: [
  //       { label: 'ดีแทค', value: 'Brand|ดีแทค' },
  //       { label: 'กิจกรรม', value: 'Category|กิจกรรม' },
  //       { label: 'ไม่แจกของรางวัล', value: 'Sub-Category|ไม่แจกของรางวัล' },
  //       { label: 'คำชื่นชม', value: 'TOM|คำชื่นชม' },
  //       { label: 'การแนะนำ', value: 'Concern|การแนะนำ' },
  //     ],
  //   }
  // ]

  if (
    !configuration ||
    configuration.length === 0 ||
    !Number.isInteger(maxPath)
  ) {
    return [];
  }

  const categories = configuration.map((element) => element.result);

  const result = [];

  const createPath = (index, maxCat, key) => {
    if (index === maxCat) {
      return [];
    }

    const item = key
      ? categories[index][key][getRandomInt(categories[index][key].length)]
      : categories[index][getRandomInt(categories[index].length)];

    const nextItem = !Array.isArray(categories[index + 1])
      ? createPath(index + 1, categories.length, item.label)
      : createPath(index + 1, categories.length);

    if (index === 0) {
      return {
        name: [item, ...nextItem]
          .map((item) => item.label)
          .reduce((acc, label) => `${acc} > ${label}`),
        path: [item, ...nextItem],
      };
    }
    return [item, ...nextItem];
  };

  [...Array(maxPath).keys()].forEach((i) => {
    result.push(createPath(0, categories.length));
  });

  return result;
};

const deleteOtherkeys = (keys, data) =>
  Object.fromEntries(
    Object.entries(data).filter(([key, _val]) => keys.includes(key))
  );

const deleteOtherValues = (value, data, filterText) =>
  data.filter((item) => filterText(item.label, value));

const filterConfigBySelectedCategory = (selectedCategories, config) => {
  if (selectedCategories.length === 0) {
    return config;
  }

  const filteredConfig = JSON.parse(JSON.stringify(config));

  const isThereOptionInCategory = filteredConfig.some(
    (_element, index, filteredConfig) => {
      const data = filteredConfig[index + 1];
      return !!(
        data &&
        !Array.isArray(data.result) &&
        selectedCategories[index]
      );
    }
  );

  filteredConfig.reduce((prevMatchValue, element, index) => {
    const option = selectedCategories[index];
    const condition = (text, keyword) => text === keyword;

    if (option && Array.isArray(element.result)) {
      element.result = deleteOtherValues(option, element.result, condition);

      return option;
    }
    if (option) {
      element.result = deleteOtherkeys(prevMatchValue, element.result);
      element.result[prevMatchValue] = deleteOtherValues(
        option,
        element.result[prevMatchValue],
        condition
      );
      return element.result[prevMatchValue].map((item) => item.label);
    }

    if (!option && !Array.isArray(element.result) && isThereOptionInCategory) {
      element.result = deleteOtherkeys(prevMatchValue, element.result);
      return Array.isArray(prevMatchValue)
        ? prevMatchValue
            .map((cat) => element.result[cat].map((item) => item.label))
            .flat()
        : element.result[prevMatchValue].map((item) => item.label);
    }

    return null;
  }, selectedCategories[0]);

  return filteredConfig;
};

const filterConfigByQuery = (query, configuration) => {
  const condition = (text, keyword) => text.startsWith(keyword);

  const filteredConfig = JSON.parse(JSON.stringify(configuration));

  const matchValue = filteredConfig.reduceRight((acc, element, index) => {
    if (!acc) {
      return null;
    }

    if (Array.isArray(element.result)) {
      const found = element.result.some((value) => value.label.startsWith(acc));
      if (found) {
        element.result = deleteOtherValues(acc, element.result, condition);
        return null;
      }
      return acc;
    }

    const newQuery = Object.entries(element.result).reduce(
      (newQuery, [key, val]) => {
        if (newQuery) {
          return newQuery;
        }

        const found = val.some((value) => value.label.startsWith(acc));
        if (found) {
          const keyword = val.reduce(
            (acc, value) => (value.label.startsWith(acc) ? value : acc),
            acc
          ).label;

          filteredConfig[index].result = deleteOtherkeys(
            key,
            filteredConfig[index].result
          );

          filteredConfig[index].result[key] = deleteOtherValues(
            keyword,
            filteredConfig[index].result[key],
            condition
          );

          return key;
        }
        return null;
      },
      null
    );
    return newQuery || acc;
  }, query);

  return matchValue === query ? [] : filteredConfig;
};

const removeDuplicatedPath = (paths) =>
  paths.filter(
    (path, pos) => paths.map((path) => path.name).indexOf(path.name) === pos
  );

export {
  filterConfigBySelectedCategory,
  filterConfigByQuery,
  removeDuplicatedPath,
  constructPath,
};
