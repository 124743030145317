import React, { useContext, useEffect, useState } from 'react';

import { useAlert } from 'react-alert';
import { useHistory } from 'react-router-dom';

import CreateTaskButtonGroup from '../components/molecules/CreateTaskButtonGroup';
import Loading from '../components/molecules/Loading';
import TaskForm from '../components/organisms/TaskForm';
import Body from '../components/templates/Body';
import Footer from '../components/templates/Footer';
import Header from '../components/templates/Header';
import Layout from '../components/templates/Layout';
import { RESET_TASK } from '../constants/actions';
import {
  ALERT_TASK_CREATE_ERROR,
  ALERT_TASK_CREATE_SUCCESS,
  ALERT_TASK_NAME_EMPTY_ERROR,
} from '../constants/messages';
import { TASK_LIST } from '../constants/route';
import { INITIAL, PENDING, RENDERED } from '../constants/state';
import Api from '../services/api';
import { AppContext } from '../store';
import { to } from '../utils/utils';

const TaskCreate = () => {
  const { state, dispatch } = useContext(AppContext);
  const [stateStatus, setStateStatus] = useState(INITIAL);
  const [fileValid, setFileValid] = useState(true);
  const [nameValid, setNameValid] = useState(true);
  const history = useHistory();
  const alert = useAlert();

  const { auth } = state;

  const backToTaskList = () => {
    history.push(to(TASK_LIST));
  };

  const requestCreateNewTask = async (token, data) => {
    const api = new Api(token);

    if (!data.name) {
      alert.show(ALERT_TASK_NAME_EMPTY_ERROR, {
        timeout: 3000,
        type: 'error',
      });
      return;
    }

    const task = {
      taskName: data.name,
      resultType: data.type,
      resultConfiguration: data.config,
      checkItemType: data.checkItemType,
      taskStatus: data.status,
      paymentRate:
        data.paymentType === 'flat'
          ? data.flatPaymentRate
          : data.socialChannelPaymentRate,
      maximumReservedCheckItem: 18000,
    };

    let dataFilter = {};

    if (data.checkItemType === 'zocial-eye-v1.0') {
      dataFilter = {
        zocialEyeData: data.zocialEyeV1DataFilter,
      };
    } else if (data.checkItemType === 'text') {
      dataFilter = {
        textData: {},
      };
    } else if (data.checkItemType === 'redsmith-v1') {
      dataFilter = {
        redsmithData: data.redsmithV1DataFilter,
      };
    } else if (data.checkItemType === 'zocial-eye-v2') {
      dataFilter = {
        zocialEyeData: data.zocialEyeV2DataFilter,
      };
    } else if (data.checkItemType === 'check-it-off') {
      dataFilter = {
        data: data.checkItOffDataFilter,
      };
    }

    task.taskScheduler = {
      schedule: {
        start: data.taskPeriod.start,
        end: data.taskPeriod.end,
      },
      dataFilter,
    };

    try {
      const createdTask = await api.createTask(task);
      if (createdTask.id) {
        dispatch({
          type: RESET_TASK,
          payload: {},
        });
        backToTaskList();
        alert.show(ALERT_TASK_CREATE_SUCCESS, {
          timeout: 3000,
          type: 'success',
        });
      } else {
        alert.show(ALERT_TASK_CREATE_ERROR, {
          timeout: 3000,
          type: 'error',
        });
      }
    } catch (error) {
      alert.show(ALERT_TASK_CREATE_ERROR, {
        timeout: 3000,
        type: 'error',
      });
    }
  };

  const checkFormInput = (task) => {
    if (!task.name || task.name === '') {
      setNameValid(false);
    } else {
      setNameValid(true);
    }
    if (!task.config) {
      setFileValid(false);
    } else {
      setFileValid(true);
    }
  };

  const validateCreateTaskForm = (task) => {
    checkFormInput(task);

    if (!task.name || task.name === '' || !task.config) {
      return false;
    }
    if (task.checkItemType === 'zocial-eye-v1.0') {
      if (
        !task.zocialEyeV1DataFilter.sentiment.length ||
        !task.zocialEyeV1DataFilter.source.length
      ) {
        return false;
      }
    }
    if (task.checkItemType === 'zocial-eye-v2') {
      if (
        !task.zocialEyeV2DataFilter.sentiment.length ||
        !task.zocialEyeV2DataFilter.source.length
      ) {
        return false;
      }
    }
    if (task.checkItemType === 'redsmith-v1') {
      if (
        !task.redsmithV1DataFilter.sentiment.length ||
        !task.redsmithV1DataFilter.channel.length
      ) {
        return false;
      }
    }
    if (task.checkItemType === 'check-it-off') {
      if (
        !task.checkItOffDataFilter.sentimentFromZe.length ||
        !task.checkItOffDataFilter.channel.length
      ) {
        return false;
      }
    }
    return true;
  };

  const createNewTask = async () => {
    const {
      auth: {
        user: { token },
      },
      task: { task },
    } = state;

    if (!validateCreateTaskForm(task)) {
      alert.show(ALERT_TASK_CREATE_ERROR, {
        timeout: 3000,
        type: 'error',
      });
      return;
    }

    await requestCreateNewTask(token, task);
  };

  useEffect(() => {
    switch (stateStatus) {
      case INITIAL:
        setStateStatus(PENDING);
        break;
      case PENDING:
        setStateStatus(RENDERED);
        break;
      default:
        break;
    }
  }, [stateStatus, auth]);

  return stateStatus ? (
    <div id="task_create">
      <Layout>
        <Header />
        <Body>
          <div className="task-create-container">
            <div className="title">Create a task</div>
            <div className="task-create-form">
              <TaskForm
                mode="create"
                nameValid={nameValid}
                fileValid={fileValid}
                setFileValid={setFileValid}
              />
              <CreateTaskButtonGroup
                onClickCreateTaskButton={createNewTask}
                onClickCancelButton={backToTaskList}
                disableCreateTaskButton={false}
                disableCancelButton={false}
              />
            </div>
          </div>
        </Body>
        <Footer />
      </Layout>
    </div>
  ) : (
    <Loading />
  );
};

export default TaskCreate;
