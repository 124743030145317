import combineReducers from 'react-combine-reducers';

import appReducer from './app';
import authReducer from './auth';
import checkitemReducer from './checkitem';
import taskReducer from './task';
import userReducer from './user';

const [reducer, initialState] = combineReducers({
  task: [taskReducer.reducer, taskReducer.state],
  user: [userReducer.reducer, userReducer.state],
  auth: [authReducer.reducer, authReducer.state],
  app: [appReducer.reducer, appReducer.state],
  checkitem: [checkitemReducer.reducer, checkitemReducer.state],
});

export { reducer, initialState };
