async function request(url, method, data, headers) {
  try {
    // eslint-disable-next-line no-undef
    const response = await fetch(url, {
      method, // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        ...headers,
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    });
    return response.json();
  } catch (error) {
    return {
      error: {
        message: error,
        code: 'FetchError',
      },
    };
  }
}

async function login(username, password) {
  const url = `${process.env.REACT_APP_CHK_API_HOST}/user/login`;
  const data = {
    username,
    password,
  };
  return request(url, 'POST', data);
}

async function create(username, password, email, name, token) {
  const url = `${process.env.REACT_APP_CHK_API_HOST}/users`;
  const data = {
    username,
    password,
    email,
    name,
  };
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  return request(url, 'POST', data, headers);
}

export default {
  login,
  create,
};
