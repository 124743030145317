import React from 'react';

import BackButton from '../atoms/BackButton';
import ConfirmModal from './ConfirmModal';

const ClearReservedCheckItemsButtonGroup = ({
  onClickClearAllButton,
  onClickClearButton,
  onClickCancelButton,
  disableClearAllButton,
  disableClearButton,
  disableCancelButton,
}) => (
  <div className="action-button-group">
    <div className="btn-group-1">
      <BackButton
        onClick={onClickCancelButton}
        text="Task list"
        disabled={disableCancelButton === true}
      />
    </div>
    <div className="btn-group-2">
      <ConfirmModal
        onConfirm={onClickClearAllButton}
        type="delete"
        modalMessage="Are you sure you want to clear all reserved checkable-items for this task?"
        buttonMessage="OK"
        detailMessage="Clear All"
        disabled={disableClearAllButton === true}
      />
      <ConfirmModal
        onConfirm={onClickClearButton}
        type="save"
        modalMessage="Are you sure you want to clear reserved checkable-items for selected users?"
        buttonMessage="OK"
        detailMessage="Clear"
        disabled={disableClearButton === true}
      />
    </div>
  </div>
);

export default ClearReservedCheckItemsButtonGroup;
