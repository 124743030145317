import React from 'react';

import Image from '../atoms/Image';

const ImageView = ({ imageUrl, alt }) => (
  <div className="image-view">
    <Image src={imageUrl} alt={alt || imageUrl} onError="" />
  </div>
);
export default ImageView;
