import React from 'react';

import BackButton from '../atoms/BackButton';
import SaveButton from '../atoms/SaveButton';

const ButtonGroup = ({
  onSaveButtonClick,
  onBackButtonClick,
  disableSaveButton,
}) => (
  <div className="action-button-group">
    <div className="btn-group-1">
      <BackButton onClick={onBackButtonClick} text="Task list" />
    </div>
    <div className="btn-group-2">
      <SaveButton onClick={onSaveButtonClick} disabled={disableSaveButton} />
    </div>
  </div>
);

export default ButtonGroup;
