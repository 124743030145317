import React from 'react';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faCopy } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';

library.add(faCopy);

const DuplicateButton = (props) => (
  <Button className="duplicate-button" color="secondary" {...props}>
    <FontAwesomeIcon icon={['far', 'copy']} />
  </Button>
);

export default DuplicateButton;
