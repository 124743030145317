import React from 'react';

import { Container } from 'reactstrap';

import Sidebar from './Sidebar';

const Layout = ({ children }) => (
  <Container className="layout">
    <Sidebar />
    {children}
  </Container>
);

export default Layout;
