import React, { useContext } from 'react';

import { Col, Input, InputGroup, InputGroupAddon } from 'reactstrap';

import { AppContext } from '../../store';
import GenerateButton from '../atoms/GenerateButton';

const TaskInvitationLinkController = ({ createInvitationLink }) => {
  const { state } = useContext(AppContext);
  const { invitationLink } = state.user;

  return (
    <div
      className="task-generate-invitation-link"
      style={{ marginBottom: 20, maxWidth: 500 }}
    >
      <InputGroup>
        <Input
          id="invitation-link"
          name="invitation-link"
          type="text"
          value={invitationLink}
          placeholder="Click generate to create invitation link"
          disabled
        />
        <InputGroupAddon addonType="extend">
          <GenerateButton message="Generate" onClick={createInvitationLink} />
        </InputGroupAddon>
      </InputGroup>
    </div>
  );
};

export default TaskInvitationLinkController;
