import React from 'react';

import { useHistory } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

import { TASK_LIST } from '../../constants/route';
import { to } from '../../utils/utils';
import UserStatistic from '../molecules/UserStatistic';
import TaskCheckItem from '../organisms/TaskCheckItem';
import TaskPaging from '../organisms/TaskPaging';
import TaskPagingNotFound from '../organisms/TaskPagingNotFound';
import TaskViewNotFound from '../organisms/TaskViewNotFound';

const TaskContainer = ({
  checkableItem,
  checkResultConfig,
  updateTaskResult,
  saveResultsAndUpdateCheckitem,
  checkResultType,
  onSkipCheckItem,
  userStat,
  isSaving,
}) => {
  const history = useHistory();
  const backToTaskList = () => {
    history.push(to(TASK_LIST));
  };

  return (
    <Row className="task-container">
      <Col md={12}>
        <UserStatistic
          checkedCount={userStat.checkedItemCount}
          remainCount={userStat.remainItemCount}
        />
      </Col>
      {checkableItem && checkableItem.length > 0 ? (
        <>
          {checkableItem.map((item, idx) => (
            <TaskCheckItem
              idx={idx}
              item={item}
              userStat={userStat}
              checkResultConfig={checkResultConfig}
              checkResultType={checkResultType}
              updateTaskResult={updateTaskResult}
              onSkipCheckItem={onSkipCheckItem}
            />
          ))}
          <Col md={12}>
            <TaskPaging
              onBackButtonClick={backToTaskList}
              onNextButtonClick={saveResultsAndUpdateCheckitem}
              nextDisabled={isSaving}
            />
          </Col>
        </>
      ) : (
        <>
          <Col md={12}>
            <TaskViewNotFound />
          </Col>
          <Col md={12}>
            <TaskPagingNotFound onBackButtonClick={backToTaskList} />
          </Col>
        </>
      )}
    </Row>
  );
};

export default TaskContainer;
