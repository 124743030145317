import React from 'react';

import Body from '../templates/Body';
import Footer from '../templates/Footer';
import Header from '../templates/Header';
import Layout from '../templates/Layout';
import TaskContainer from '../templates/TaskContainer';

const Task = ({
  checkableItem,
  checkResultConfig,
  saveResultsAndUpdateCheckitem,
  checkResultType,
  taskName,
  userStat,
  onSkipCheckItem,
  isSaving,
}) => (
  <Layout>
    <Header />
    <Body>
      <div className="title">{taskName}</div>
      <TaskContainer
        checkableItem={checkableItem}
        checkResultConfig={checkResultConfig}
        saveResultsAndUpdateCheckitem={saveResultsAndUpdateCheckitem}
        checkResultType={checkResultType}
        onSkipCheckItem={onSkipCheckItem}
        userStat={userStat}
        isSaving={isSaving}
      />
    </Body>
    <Footer />
  </Layout>
);

export default Task;
