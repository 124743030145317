import React from 'react';

import { useHistory } from 'react-router-dom';
import { Col, Row, Table } from 'reactstrap';

import { TASK_LIST } from '../../constants/route';
import { to } from '../../utils/utils';
import BackButton from '../atoms/BackButton';
import TableHeader from '../molecules/TableHeader';
import TaskStatisticBody from '../molecules/TaskStatisticBody';

const TaskStatisticController = ({ data }) => {
  const history = useHistory();
  const tableHeader = [
    { id: 'userID', label: 'User ID', width: 140 },
    { id: 'userName', label: 'Username', width: 100 },
    { id: 'checkedItemCount', label: 'Checked item count', width: 80 },
  ];

  const goToTaskList = () => {
    history.push(to(TASK_LIST));
  };

  return (
    <>
      <Row className="task-table">
        <Col md={12} className="table">
          <Table
            className="task-statistic-table"
            responsive
            striped
            bordered
          >
            <TableHeader data={tableHeader} />
            <TaskStatisticBody data={data} />
          </Table>
        </Col>
      </Row>
      <Row className="back-to-task-list">
        <Col md={12}>
          <BackButton onClick={goToTaskList} text="Back" />
        </Col>
      </Row>
    </>
  );
};

export default TaskStatisticController;
