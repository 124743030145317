import React, { useContext, useEffect, useState } from 'react';

import { useAlert } from 'react-alert';
import { Redirect, useHistory } from 'react-router-dom';

import Loading from '../components/molecules/Loading';
import TaskEditComponent from '../components/views/TaskEdit';
import { RESET_TASK, SET_SELECTED_TASK, SET_TASK } from '../constants/actions';
import {
  ALERT_TASK_DELETE_SUCCESS,
  ALERT_TASK_NOT_FOUND_ERROR,
  ALERT_TASK_UPDATE_ERROR,
  ALERT_TASK_UPDATE_SUCCESS,
} from '../constants/messages';
import { TASK_LIST } from '../constants/route';
import {
  FETCHING,
  INITIAL,
  PENDING,
  REDIRECT,
  RENDERED,
  SUCCESS,
} from '../constants/state';
import Api from '../services/api';
import { AppContext } from '../store';
import { canDeleteTask } from '../utils/permission';
import { to } from '../utils/utils';

const determineZocialEyeDataFilter = (data) => {
  if (data.checkItemType === 'zocial-eye-v1.0')
    return data.zocialEyeV1DataFilter;

  if (data.checkItemType === 'zocial-eye-v2') return data.zocialEyeV2DataFilter;

  return undefined;
};
const TaskEdit = ({ match }) => {
  const { state, dispatch } = useContext(AppContext);
  const [stateStatus, setStateStatus] = useState(INITIAL);
  const alert = useAlert();
  const history = useHistory();

  const { auth } = state;
  const { id: taskId } = match.params;

  const backToTaskList = () => {
    history.push(to(TASK_LIST));
  };

  const requestUpdateTask = async (token, data) => {
    const api = new Api(token);
    const task = {
      taskId: data.id,
      taskName: data.name,
      taskStatus: data.status,
    };
    const taskConfiguration = {
      taskId: data.id,
      checkItemType: data.checkItemType,
      paymentRate:
        data.paymentType === 'flat'
          ? data.flatPaymentRate
          : data.socialChannelPaymentRate,
      maximumReservedCheckItem: 18000,
      resultType: data.type,
      resultConfiguration: data.config,
    };

    const taskDataFilter = {
      zocialEyeData: determineZocialEyeDataFilter(data),
      redsmithData:
        data.checkItemType === 'redsmith-v1'
          ? data.redsmithV1DataFilter
          : undefined,
      textData: data.checkItemType === 'text' ? {} : undefined,
      data:
        data.checkItemType === 'check-it-off'
          ? data.checkItOffDataFilter
          : undefined,
    };

    const taskScheduler = {
      taskId: data.id,
      taskStartDateTime: data.taskPeriod.start,
      taskEndDateTime: data.taskPeriod.end,
      taskDataFilter,
    };

    try {
      const updatedTaskInfo = await api.updateTaskInformation(task);
      const updatedTaskConfiguration = await api.updateTaskConfiguration(
        taskConfiguration
      );
      const updatedTaskScheduler = await api.updateTaskScheduler(taskScheduler);

      if (
        updatedTaskInfo.id &&
        updatedTaskConfiguration.id &&
        updatedTaskScheduler
      ) {
        dispatch({ type: RESET_TASK });
        backToTaskList();
        alert.show(ALERT_TASK_UPDATE_SUCCESS, {
          timeout: 3000,
          type: 'success',
        });
      } else {
        alert.show(ALERT_TASK_UPDATE_ERROR, {
          timeout: 3000,
          type: 'error',
        });
      }
    } catch (error) {
      alert.show(ALERT_TASK_UPDATE_ERROR, {
        timeout: 3000,
        type: 'error',
      });
    }
  };

  const validateUpdateTaskForm = (task) => {
    if (!task.name || task.name === '') {
      return false;
    }
    if (task.checkItemType === 'zocial-eye-v1.0') {
      if (
        !task.zocialEyeV1DataFilter.sentiment.length ||
        !task.zocialEyeV1DataFilter.source.length
      ) {
        return false;
      }
    }
    if (task.checkItemType === 'zocial-eye-v2') {
      if (
        !task.zocialEyeV2DataFilter.sentiment.length ||
        !task.zocialEyeV2DataFilter.source.length
      ) {
        return false;
      }
    }
    if (task.checkItemType === 'redsmith-v1') {
      if (
        !task.redsmithV1DataFilter.sentiment.length ||
        !task.redsmithV1DataFilter.channel.length
      ) {
        return false;
      }
    }
    if (task.checkItemType === 'check-it-off') {
      if (
        !task.checkItOffDataFilter.sentimentFromZe.length ||
        !task.checkItOffDataFilter.channel.length
      ) {
        return false;
      }
    }
    return true;
  };

  const updateTask = async () => {
    const {
      auth: {
        user: { token },
      },
      task: { task },
    } = state;
    if (!validateUpdateTaskForm(task)) {
      alert.show(ALERT_TASK_UPDATE_ERROR, {
        timeout: 3000,
        type: 'error',
      });
      return;
    }
    await requestUpdateTask(token, task);
  };

  const deleteTask = async () => {
    const api = new Api(auth.user.token);
    const deletedResult = await api.deleteTask({ taskId });
    if (deletedResult) {
      alert.show(ALERT_TASK_DELETE_SUCCESS, {
        timeout: 3000,
        type: 'success',
      });
    }
    backToTaskList();
  };

  useEffect(() => {
    const getTaskById = async (token, taskId) => {
      const api = new Api(token);
      const task = await api.getTask(taskId);

      if (task && task.id) {
        dispatch({
          type: SET_SELECTED_TASK,
          payload: { selectedTask: task },
        });
        dispatch({
          type: SET_TASK,
          payload: { task },
        });
        setStateStatus(SUCCESS);
      } else {
        setStateStatus(REDIRECT);
        alert.show(ALERT_TASK_NOT_FOUND_ERROR, {
          timeout: 3000,
          type: 'error',
        });
      }
    };

    switch (stateStatus) {
      case INITIAL:
        setStateStatus(PENDING);
        break;
      case PENDING:
        setStateStatus(FETCHING);
        break;
      case FETCHING:
        getTaskById(auth.user.token, taskId);
        break;
      case SUCCESS:
        setStateStatus(RENDERED);
        break;
      default:
        break;
    }
  }, [stateStatus, auth, dispatch, alert, taskId]);

  return (
    <>
      {stateStatus === REDIRECT && <Redirect to={TASK_LIST} />}
      {stateStatus === RENDERED ? (
        <div id="task_create">
          <TaskEditComponent
            onClickSaveTaskButton={updateTask}
            onClickDeleteTaskButton={deleteTask}
            onClickCancelButton={backToTaskList}
            disableDeleteTaskButton={!canDeleteTask(auth)}
          />
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default TaskEdit;
