import React from 'react';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faWindowAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';

library.add(faWindowAlt);

const EmbedButton = ({ onClick }) => (
  <Button color="link" className="embed-button" onClick={onClick} block>
    <FontAwesomeIcon icon={['far', 'window-alt']} /> Show original post
  </Button>
);

export default EmbedButton;
