import React from 'react';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faClipboard } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col } from 'reactstrap';

library.add(faClipboard);

const slicePost = (text) => {
  if (text.length < 300) {
    return text;
  }

  return `${text.slice(0, 300)}...`;
};

const ParentPost = ({ post }) => (
  <div className="parent-post-view">
    {post?.message ? (
      <>
        <div>
          <FontAwesomeIcon icon={['fas', 'clipboard']} />
          Post
        </div>
        <Col md={12}>{slicePost(post.message)}</Col>
      </>
    ) : (
      ''
    )}
  </div>
);

export default ParentPost;
