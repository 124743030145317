import React, { useContext, useEffect, useState } from 'react';

import Excel from '../../services/excel';
import { AppContext } from '../../store';
import DownloadButton from '../atoms/DownloadButton';

const TaskDownloadCheckItemTemplate = () => {
  const { state } = useContext(AppContext);
  const [isDownload, setIsDownload] = useState(false);

  const {
    selectedTask: { name, config },
  } = state.task;

  useEffect(() => {
    const writeExcel = async (checkItemType) => {
      const fileName = `${name}_template.xlsx`;
      const workbook = Excel.writeTemplateFile(checkItemType);
      Excel.downloadExcelFile(workbook, fileName);
      setIsDownload(false);
    };
    if (isDownload && config) {
      writeExcel(config.checkItemType);
    }
  }, [isDownload, config, name]);

  const downloadFile = () => {
    setIsDownload(true);
  };

  return (
    <div className="task-download-checkitem-template">
      <DownloadButton text="Download template file" onClick={downloadFile} />
    </div>
  );
};

export default TaskDownloadCheckItemTemplate;
