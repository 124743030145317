import React, { useState } from 'react';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faClipboardList } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Button } from 'reactstrap';

library.add(faClipboardList);

const RecentActivityButton = ({ disabled, onClick, taskId }) => (
  <OverlayTrigger
    key="top"
    placement="top"
    overlay={
      <Tooltip id={`${taskId}-tooltip-recent-history-button}`}>
        <strong>RECENT ACTIVITIES</strong>
      </Tooltip>
    }
  >
    <Button
      className="start-button btn-outline"
      disabled={disabled}
      onClick={onClick}
      id={`${taskId}-recent-history-button`}
    >
      <FontAwesomeIcon icon={['far', 'clipboard-list']} />
    </Button>
  </OverlayTrigger>
);

export default RecentActivityButton;
