import React from 'react';

const TableHeader = ({ data }) => (
  <thead>
    <tr>
      {data.map(({ label, id, width }, idx) => (
        <th className={`${id} text-center`} key={id} width={width}>
          {label}
        </th>
      ))}
    </tr>
  </thead>
);

export default TableHeader;
