import React from 'react';

import CheckboxInput from '../atoms/CheckboxInput';

const TaskAssignUserBody = ({ data, checkFunction, checkedData }) => (
  <tbody>
    {data && data.length > 0 ? (
      data.map((row, idx) => (
        <tr key={idx}>
          <td width="40">
            <CheckboxInput
              onChange={() => checkFunction(row.id)}
              checked={checkedData.includes(row.id)}
            />
          </td>
          <td width="" className="text-center">
            {row.name}
          </td>
          <td width="" className="text-center">
            @{row.username}
          </td>
        </tr>
      ))
    ) : (
      <tr>
        <td colSpan={6}>User not found.</td>
      </tr>
    )}
  </tbody>
);

export default TaskAssignUserBody;
