import React, { useContext } from 'react';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import ReadMore from 'read-more-react';

import {
  TASK_ASSIGN_USER,
  TASK_CHECK_RESULT,
  TASK_CLEAR_RESERVED_CHECKITEM,
  TASK_EDIT,
  TASK_IMPORT_CHECKITEM,
  TASK_RECENT_ACTIVITY,
  TASK_STATISTIC,
} from '../../constants/route';
import { trackRecentActivitiesButton } from '../../services/googleAnalytics';
import { AppContext } from '../../store';
import currencyNumber from '../../utils/currencyNumber';
import {
  canAssignUserToTask,
  canCheckResultInTask,
  canImportCheckItemToTask,
  canManageTask,
} from '../../utils/permission';
import { isTaskScheduleActive, to } from '../../utils/utils';
import AddCheckItemButton from '../atoms/AddCheckItemButton';
import AssignUserButton from '../atoms/AssignUserButton';
import ClearReservedCheckItemsButton from '../atoms/ClearReservedCheckItemsButton';
import EditButton from '../atoms/EditButton';
import ExportButton from '../atoms/ExportButton';
import RecentActivityButton from '../atoms/RecentActivityButton';
import StartButton from '../atoms/StartButton';
import StatisticButton from '../atoms/StatisticButton';

library.add(faSpinner);

const TaskListBody = ({ data, exportCheckItem }) => {
  const { state } = useContext(AppContext);
  const { auth } = state;
  const history = useHistory();

  const goToCheckItemManagement = (taskId) => {
    history.push(to(TASK_CHECK_RESULT, taskId));
  };

  const goToRecentActivity = (taskId) => {
    trackRecentActivitiesButton();
    history.push(to(TASK_RECENT_ACTIVITY, taskId));
  };

  const goToEditTask = (taskId) => {
    history.push(to(TASK_EDIT, taskId));
  };

  const goToTaskAssignUser = (taskId) => {
    history.push(to(TASK_ASSIGN_USER, taskId));
  };

  const goToAddCheckItem = (taskId) => {
    history.push(to(TASK_IMPORT_CHECKITEM, taskId));
  };

  const goToClearReservedCheckItem = (taskId) => {
    history.push(to(TASK_CLEAR_RESERVED_CHECKITEM, taskId));
  };

  const goToTaskStatistic = (taskId) => {
    window.open(to(TASK_STATISTIC, taskId), '_blank');
  };

  const shouldBeDisabled = (task) =>
    task.progress !== 'enabled' ||
    !isTaskScheduleActive(task.schedule.schedule);

  const statusIndicatorStyle = {
    margin: '0 8px 0 0',
    height: '8px',
    width: '8px',
  };

  const IconDisplayStyle = { display: 'flex', justifyContent: 'center' };

  // eslint-disable-next-line react/no-unstable-nested-components
  const SpinnerRow = () => (
    <tr>
      <td colSpan={6}>
        <div className="fa-3x" style={IconDisplayStyle}>
          <FontAwesomeIcon icon={['far', 'spinner']} pulse />
        </div>
      </td>
    </tr>
  );

  // eslint-disable-next-line react/no-unstable-nested-components
  const NotFoundDataRow = () => (
    <tr>
      <td colSpan={6}>not found data.</td>
    </tr>
  );

  const DataRows = () =>
    data.map((row, idx) => (
      // eslint-disable-next-line react/no-array-index-key
      <tr key={`${row.id}_${idx}`}>
        <td width="160">
          <div className="task-name">
            <div className="status">
              {row.progress === 'enabled' ? (
                <status-indicator positive style={statusIndicatorStyle} />
              ) : (
                <status-indicator style={statusIndicatorStyle} />
              )}
            </div>
            <ReadMore
              text={row.name}
              min={80}
              ideal={90}
              max={100}
              less="Show less"
              readMoreText={<div className="read-more-button">Read more</div>}
            />
          </div>
        </td>
        <td width="40" className="text-center">
          {currencyNumber(row.checker.count)}
        </td>
        <td width="80" className="text-center">
          {row.createdBy}
        </td>
        <td width="90" className="text-center">
          {row.deadline}
        </td>
        <td width="130" className="text-center">
          {canCheckResultInTask(auth) && (
            <StartButton
              onClick={() => goToCheckItemManagement(row.id)}
              disabled={shouldBeDisabled(row)}
              taskId={row.id}
            />
          )}
          {canCheckResultInTask(auth) && (
            <RecentActivityButton
              onClick={() => goToRecentActivity(row.id)}
              disabled={shouldBeDisabled(row)}
              taskId={row.id}
            />
          )}
          {canManageTask(auth) && (
            <EditButton
              message="EDIT TASK"
              taskId={row.id}
              onClick={() => goToEditTask(row.id)}
            />
          )}
          {canAssignUserToTask(auth) && (
            <AssignUserButton
              taskId={row.id}
              onClick={() => goToTaskAssignUser(row.id)}
            />
          )}
          {canImportCheckItemToTask(auth) && (
            <AddCheckItemButton
              taskId={row.id}
              onClick={() => goToAddCheckItem(row.id)}
              disabled={shouldBeDisabled(row)}
            />
          )}
          {canManageTask(auth) && (
            <ExportButton
              taskId={row.id}
              userEmail={auth.user.email}
              exportCheckItem={exportCheckItem}
            />
          )}
          {canCheckResultInTask(auth) && (
            <StatisticButton
              taskId={row.id}
              onClick={() => goToTaskStatistic(row.id)}
            />
          )}
          {canManageTask(auth) && (
            <ClearReservedCheckItemsButton
              taskId={row.id}
              onClick={() => goToClearReservedCheckItem(row.id)}
              disabled={shouldBeDisabled(row)}
            />
          )}
        </td>
      </tr>
    ));

  // eslint-disable-next-line react/no-unstable-nested-components
  const Rows = () => {
    if (data && data.length > 0) return <DataRows />;
    if (data && data.length === 0) return <SpinnerRow />;
    return <NotFoundDataRow />;
  };

  return (
    <tbody>
      <Rows />
    </tbody>
  );
};

export default TaskListBody;
