import React from 'react';

import { useHistory } from 'react-router-dom';

import { CHECKITEM_EDIT } from '../../constants/route';
import { to } from '../../utils/utils';
import EditButton from '../atoms/EditButton';
import ReadMore from '../atoms/ReadMore';

const TaskRecentActivityBody = ({ data }) => {
  const history = useHistory();

  const goToCheckResultEdit = (checkItemId) => {
    history.push(to(CHECKITEM_EDIT, checkItemId));
  };

  return (
    <tbody>
      {data && data.length > 0 ? (
        data.map((row, idx) => (
          <tr key={idx}>
            <td width="360">
              <ReadMore lines={2} more="Read more" less="Show less">
                {row.detail}
              </ReadMore>
            </td>
            <td width="100" className="text-center">
              {row.checkedAt}
            </td>
            <td width="80" className="text-center">
              <EditButton
                message="EDIT CHECK ITEM"
                onClick={() => goToCheckResultEdit(row.id)}
              />
            </td>
          </tr>
        ))
      ) : (
        <tr>
          <td colSpan={6}>No recent activity</td>
        </tr>
      )}
    </tbody>
  );
};

export default TaskRecentActivityBody;
