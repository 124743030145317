import React, { useContext, useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { Col, Label, Row } from 'reactstrap';

import { UPDATE_CHECK_RESULT, UPDATE_NOTE } from '../../constants/actions';
import { TASK_LIST } from '../../constants/route';
import { AppContext } from '../../store';
import { to } from '../../utils/utils';
import TaskAction from './TaskAction';
import TaskHierarchyController from './TaskHierarchyController';
import TaskMultiLabelController from './TaskMultiLabelController';
import TaskNote from './TaskNote';
import TaskView from './TaskView';

const TaskCheckItem = ({
  idx,
  item,
  userStat,
  checkResultConfig,
  checkResultType,
  onSkipCheckItem,
}) => {
  const { state, dispatch } = useContext(AppContext);
  const history = useHistory();
  const [disabledSaveButton, setDisabledSaveButton] = useState(true);
  const [isRejected, setIsRejected] = useState(false);
  const [rejectDisabled, setRejectDisabled] = useState(false);
  const [note, setNote] = useState('');
  const [checkResultList, setCheckResultList] = useState([]);

  const foundResult = state.checkitem.results.find(
    (checkResult) => checkResult.checkitemId === item.id
  );

  const { checkResult } = foundResult;

  const rejectTaskResult = () => {
    setIsRejected(true);
    dispatch({
      type: UPDATE_CHECK_RESULT,
      payload: {
        checkitemId: item.id,
        checkResult: [
          {
            id: 0,
            label: ['Reject'],
            value: ['reject'],
            group: ['reject'],
          },
        ],
        actionType: 'create',
        actionTime: new Date()
      },
    });
    setDisabledSaveButton(true);
    setRejectDisabled(true);
  };

  const backToTaskList = () => {
    history.push(to(TASK_LIST));
  };

  const setValidCheckResult = (status) => {
    setDisabledSaveButton(!status);
  };

  const duplicateCheckResult = (id) => {
    const duplicatedCheckResult = checkResultList.find(
      (checkResult) => checkResult.id === id
    );

    setDisabledSaveButton(true);
    return duplicatedCheckResult;
  };

  const deleteCheckResult = (id) => {
    const filteredCheckResultList = checkResultList.filter(
      (checkResult) => checkResult.id !== id
    );

    dispatch({
      type: UPDATE_CHECK_RESULT,
      payload: {
        checkitemId: item.id,
        checkResult: filteredCheckResultList,
        actionType: 'create',
        actionTime: new Date()
      },
    });
    setIsRejected(false);
    setCheckResultList(filteredCheckResultList);
    setDisabledSaveButton(filteredCheckResultList.length === 0);
    setRejectDisabled(filteredCheckResultList.length !== 0);
  };

  const updateNote = (message) => {
    setNote(message);
    dispatch({
      type: UPDATE_NOTE,
      payload: { checkitemId: item.id, note: message },
    });
  };

  const addCheckResult = (label, value, group = null) => {
    const tempIds = [...Array(checkResultList.length + 1)].map(
      (_, idx) => idx + 1
    );
    const currentIds = checkResultList.map(({ id }) => id);
    const checkId = tempIds.find((id) => !currentIds.includes(id));

    const temp = [...checkResultList, { id: checkId, label, value, group }];
    setCheckResultList([...temp]);

    setRejectDisabled(true);
    dispatch({
      type: UPDATE_CHECK_RESULT,
      payload: {
        checkitemId: item.id,
        checkResult: temp,
        actionType: 'create',
        actionTime: new Date()
      },
    });
  };

  return (
    <Row className={idx % 2 === 0 ? 'check-item even' : 'check-item odd'}>
      <Col md={12}>
        <TaskView checkableItem={item} />
      </Col>
      <Col md={12}>
        <Label for="check-item-result">Result</Label>
        {checkResultType === 'MULTIPLE_LABEL' && (
          <TaskMultiLabelController
            checkResultConfig={checkResultConfig}
            checkResultList={checkResult}
            addCheckResult={addCheckResult}
            deleteCheckResult={deleteCheckResult}
            duplicateCheckResult={duplicateCheckResult}
            setValidCheckResult={setValidCheckResult}
            setRejectDisabled={setRejectDisabled}
            isRejected={isRejected}
          />
        )}
        {checkResultType === 'HIERARCHY_LABEL' && (
          <TaskHierarchyController
            checkResultConfig={checkResultConfig}
            checkResultList={checkResult}
            addCheckResult={addCheckResult}
            deleteCheckResult={deleteCheckResult}
            duplicateCheckResult={duplicateCheckResult}
            setValidCheckResult={setValidCheckResult}
            setRejectDisabled={setRejectDisabled}
            isRejected={isRejected}
          />
        )}
      </Col>
      <Col md={12}>
        <Label for="check-item-note">Note</Label>
        <TaskNote updateNote={(message) => updateNote(message)} note={note} />
      </Col>
      <Col md={12}>
        <TaskAction
          checkItemId={idx}
          onBackButtonClick={backToTaskList}
          onRejectButtonClick={rejectTaskResult}
          onSkipCheckItemButtonClick={onSkipCheckItem}
          disableSkipButton={userStat.skippingCount >= 10}
          disableSaveButton={disabledSaveButton}
          disableRejectButton={rejectDisabled}
        />
      </Col>
    </Row>
  );
};

export default TaskCheckItem;
