import React from 'react';

import YouTube from 'react-youtube';

const YouTubeEmbed = ({ url }) => {
  const videoId = url.match(/\?v=([\w\-_]+)/)[1];
  return (
    <div className="youtube-embed">
      <YouTube videoId={videoId} opts={{ width: 350 }} />
    </div>
  );
};

export default YouTubeEmbed;
