import React from 'react';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';

library.add(faChevronLeft);

const BackButton = ({ onClick, text }) => (
  <Button className="back-button" color="link" onClick={onClick}>
    <FontAwesomeIcon icon={['far', 'chevron-left']} />
    {'  '}
    {text}
  </Button>
);

export default BackButton;
