import React from 'react';

import Body from '../templates/Body';
import Footer from '../templates/Footer';
import Header from '../templates/Header';
import Layout from '../templates/Layout';
import UserListContainer from '../templates/UserListContainer';

const UserList = ({ data }) => (
  <Layout>
    <Header />
    <Body>
      <UserListContainer data={data} />
    </Body>
    <Footer />
  </Layout>
);

export default UserList;
