import React from 'react';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faPencil } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Button } from 'reactstrap';

library.add(faPencil);

const EditButton = ({ disabled, onClick, taskId, message }) => (
  <OverlayTrigger
    key="top"
    placement="top"
    overlay={
      <Tooltip id={`${taskId}-tooltip-edit-task-button`}>
        <strong>{message}</strong>
      </Tooltip>
    }
  >
    <Button
      className="start-button btn-outline"
      disabled={disabled}
      onClick={onClick}
      id={`${taskId}-edit-task-button`}
    >
      <FontAwesomeIcon icon={['far', 'pencil']} />
    </Button>
  </OverlayTrigger>
);

export default EditButton;
