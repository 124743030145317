import React from 'react';

import { Col, Row } from 'reactstrap';

import PagingButtonGroup from '../molecules/PagingButtonGroup';

const TaskPaging = ({ onBackButtonClick, onNextButtonClick, nextDisabled }) => (
  <Row className="task-action">
    <Col md={12}>
      <PagingButtonGroup
        onBackButtonClick={onBackButtonClick}
        onNextButtonClick={onNextButtonClick}
        nextDisabled={nextDisabled}
      />
    </Col>
  </Row>
);

export default TaskPaging;
