import React from 'react';

import BackButton from '../atoms/BackButton';
import RejectButton from '../atoms/RejectButton';
import SaveButton from '../atoms/SaveButton';

const ActionButtonGroup = ({
  onSaveButtonClick,
  onRejectButtonClick,
  onBackButtonClick,
  disableSaveButton,
  disableRejectButton,
}) => (
  <div className="action-button-group">
    <div className="btn-group-1">
      <BackButton onClick={onBackButtonClick} text="Recent activities" />
    </div>
    <div className="btn-group-2">
      <RejectButton
        onClick={onRejectButtonClick}
        disabled={disableRejectButton}
      />
      <SaveButton onClick={onSaveButtonClick} disabled={disableSaveButton} />
    </div>
  </div>
);

export default ActionButtonGroup;
