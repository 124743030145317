import React from 'react';

import { useHistory } from 'react-router-dom';
import { Col, Row, Table } from 'reactstrap';

import { TASK_LIST } from '../../constants/route';
import { to } from '../../utils/utils';
import BackButton from '../atoms/BackButton';
import Pagination from '../molecules/Pagination';
import TableHeader from '../molecules/TableHeader';
import TaskRecentActivityBody from '../molecules/TaskRecentActivityBody';

const TaskRecentActivityController = ({ logs, page }) => {
  const history = useHistory();
  const tableHeader = [
    { id: 'detail', label: 'Detail', width: 360 },
    { id: 'checked_at', label: 'Checked at', width: 100 },
    { id: 'action', label: 'Action', width: 80 },
  ];

  const { currentPage, setCurrentPage, totalPage, segmentSize } = page;

  const goToTaskList = () => {
    history.push(to(TASK_LIST));
  };

  return (
    <>
      <Row className="task-table">
        <Col md={12} className="table">
          <Table
            className="task-recent-activity-table"
            responsive
            striped
            bordered
          >
            <TableHeader data={tableHeader} />
            <TaskRecentActivityBody data={logs} />
          </Table>
        </Col>
        <Col md={12} className="pagination">
          <Pagination
            currentPage={currentPage}
            totalPage={totalPage}
            setCurrentPage={setCurrentPage}
            segmentSize={segmentSize}
          />
        </Col>
      </Row>
      <Row className="back-to-task-list">
        <Col md={12}>
          <BackButton onClick={goToTaskList} text="Back" />
        </Col>
      </Row>
    </>
  );
};

export default TaskRecentActivityController;
