import React from 'react';

import ReactTagsInput from 'react-tagsinput';

const TagsInput = ({ placeholder, disabled, ...props }) => (
  <ReactTagsInput
    {...props}
    inputProps={{
      placeholder,
      disabled,
    }}
  />
);

export default TagsInput;
