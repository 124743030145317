import React, { useContext } from 'react';

import {} from '@fortawesome/pro-light-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faBars,
  faClipboardListCheck,
  faUsers,
} from '@fortawesome/pro-regular-svg-icons';
import { faAngleLeft } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation } from 'react-router-dom';

import LogoImageSmall from '../../assets/images/logo-inverted-icon.png';
import LogoImageLarge from '../../assets/images/logo-inverted.png';
import { SET_SIDEBAR } from '../../constants/actions';
import { TASK_LIST, USER_LIST } from '../../constants/route';
import { AppContext } from '../../store';

library.add(faAngleLeft, faBars, faUsers, faClipboardListCheck);

const Sidebar = () => {
  const { state, dispatch } = useContext(AppContext);
  const sideBarClass = `sidebar ${state.app.expandSideBar}`;
  const location = useLocation();
  const { pathname } = location;

  const toggleSideBar = () => {
    const status =
      state.app.expandSideBar === 'expand' ? 'collapsed' : 'expand';
    dispatch({
      type: SET_SIDEBAR,
      payload: {
        expandSideBar: status,
      },
    });
  };
  return (
    <div className={sideBarClass}>
      <header>
        <div className="logo">
          <img src={LogoImageLarge} alt="logo" className="large" />
          <img src={LogoImageSmall} alt="logo" className="small" />
        </div>
        <button
          type="button"
          className="mobileMenuToggler"
          onClick={() => toggleSideBar()}
        >
          <FontAwesomeIcon icon={['fas', 'angle-left']} />
          <FontAwesomeIcon icon={['far', 'bars']} />
        </button>
      </header>
      <ul className="mainMenu">
        <li
          className={
            pathname.match(/^(\/task)/) ? 'dropdown active' : 'dropdown'
          }
        >
          <a href={TASK_LIST}>
            <div className="ch">
              <FontAwesomeIcon icon={['far', 'clipboard-list-check']} />
              <span className="name">Task</span>
            </div>
            <div className="dropdownContent">Task</div>
          </a>
        </li>
        <li
          className={
            pathname.match(/^(\/user)/) ? 'dropdown active' : 'dropdown'
          }
        >
          <a href={USER_LIST}>
            <div className="ch">
              <FontAwesomeIcon icon={['far', 'users']} />
              <span className="name">User</span>
            </div>
            <div className="dropdownContent">User</div>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
