import React, { useContext, useEffect, useState } from 'react';

import { useAlert } from 'react-alert';
import { Redirect, useHistory } from 'react-router-dom';

import Loading from '../components/molecules/Loading';
import UserEditComponent from '../components/views/UserEdit';
import { RESET_USER, SET_USER } from '../constants/actions';
import {
  ALERT_USER_DELETE_ERROR,
  ALERT_USER_DELETE_SUCCESS,
  ALERT_USER_NOT_FOUND_ERROR,
  ALERT_USER_PASSWORD_UPDATE_SUCCESS,
  ALERT_USER_PASSWORD_UPDATE_WARNING,
  ALERT_USER_PROFILE_UPDATE_SUCCESS,
  ALERT_USER_UPDATE_ERROR,
} from '../constants/messages';
import { USER_LIST } from '../constants/route';
import {
  FETCHING,
  INITIAL,
  PENDING,
  REDIRECT,
  RENDERED,
  SUCCESS,
} from '../constants/state';
import Api from '../services/api';
import { AppContext } from '../store';
import { to } from '../utils/utils';
import { validateUserForm } from '../utils/validator';

const TaskEdit = ({ match }) => {
  const { state, dispatch } = useContext(AppContext);
  const [stateStatus, setStateStatus] = useState(INITIAL);
  const alert = useAlert();
  const history = useHistory();

  const {
    auth,
    user: { user },
  } = state;

  const { id: userId } = match.params;

  const backToTaskList = () => {
    history.push(to(USER_LIST));
  };

  const requestUpdateUser = async (token, data) => {
    const api = new Api(token);
    const user = {
      userId: data.id,
      userPassword: data.password,
      userEmail: data.email,
      userName: data.name,
      userCheckableItemLimit: data.checkableItemLimit,
    };

    try {
      let updatedUserProfile = await api.updateUserProfile(user);
      const isChangedLimit =
        updatedUserProfile.checkableItemLimit !== data.checkableItemLimit;
      if (isChangedLimit) {
        updatedUserProfile = await api.updateUserCheckableItemLimit(user);
      }
      const isUpdateOwnProfile = auth.user.id === updatedUserProfile.id;
      if (isUpdateOwnProfile)
        localStorage.setItem(
          'user',
          JSON.stringify({ ...auth.user, ...updatedUserProfile })
        );
      if (updatedUserProfile.id) {
        if (data.isChangePassword) {
          const updatedUserPassword = await api.updateUserPassword(user);
          if (updatedUserPassword) {
            dispatch({ type: RESET_USER });
            backToTaskList();
            alert.show(ALERT_USER_PASSWORD_UPDATE_SUCCESS, {
              timeout: 3000,
              type: 'success',
            });
          } else {
            alert.show(ALERT_USER_PASSWORD_UPDATE_WARNING, {
              timeout: 3000,
              type: 'warning',
            });
          }
        } else {
          dispatch({ type: RESET_USER });
          backToTaskList();
          alert.show(ALERT_USER_PROFILE_UPDATE_SUCCESS, {
            timeout: 3000,
            type: 'success',
          });
        }
      } else {
        alert.show(ALERT_USER_UPDATE_ERROR, {
          timeout: 3000,
          type: 'error',
        });
      }
    } catch (error) {
      alert.show(ALERT_USER_UPDATE_ERROR, {
        timeout: 3000,
        type: 'error',
      });
    }
  };

  const deleteUser = async () => {
    const {
      auth: {
        user: { token },
      },
      user: { user },
    } = state;
    const api = new Api(token);
    try {
      const deletedResult = await api.deleteUser(user.id);
      if (deletedResult) {
        backToTaskList();
        alert.show(ALERT_USER_DELETE_SUCCESS, {
          timeout: 3000,
          type: 'success',
        });
      } else {
        alert.show(ALERT_USER_DELETE_ERROR, {
          timeout: 3000,
          type: 'error',
        });
      }
    } catch (error) {
      alert.show(ALERT_USER_DELETE_ERROR, {
        timeout: 3000,
        type: 'error',
      });
    }
  };

  const updateUser = async () => {
    const {
      auth: {
        user: { token },
      },
      user: { user },
    } = state;

    await requestUpdateUser(token, user);
  };

  useEffect(() => {
    const getUserById = async (token, taskId) => {
      const api = new Api(token);
      const user = await api.getUser(taskId);

      if (user && user.id) {
        dispatch({
          type: SET_USER,
          payload: { user },
        });
        setStateStatus(SUCCESS);
      } else {
        setStateStatus(REDIRECT);
        alert.show(ALERT_USER_NOT_FOUND_ERROR, {
          timeout: 3000,
          type: 'error',
        });
      }
    };

    switch (stateStatus) {
      case INITIAL:
        setStateStatus(PENDING);
        break;
      case PENDING:
        setStateStatus(FETCHING);
        break;
      case FETCHING:
        getUserById(auth.user.token, userId);
        break;
      case SUCCESS:
        setStateStatus(RENDERED);
        break;
      default:
        break;
    }
  }, [stateStatus, auth, dispatch, alert, userId]);

  return (
    <>
      {stateStatus === REDIRECT && <Redirect to={USER_LIST} />}
      {stateStatus === RENDERED ? (
        <div id="user_create">
          <UserEditComponent
            onClickDeleteUserButton={deleteUser}
            onClickSaveUserButton={updateUser}
            onClickCancelButton={backToTaskList}
            validUser={validateUserForm(user)}
          />
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default TaskEdit;
