import React from 'react';

import { Col, Row } from 'reactstrap';

import TextView from '../molecules/TextView';

const TaskViewNotFound = () => (
  <Row className="task-view">
    <Col md={12}>
      <TextView detail="No data found" />
    </Col>
  </Row>
);

export default TaskViewNotFound;
