import React from 'react';

import BackButton from '../atoms/BackButton';

const PagingButtonGroupNotFound = ({ onBackButtonClick }) => (
  <div className="action-button-group">
    <div className="btn-group-1">
      <BackButton onClick={onBackButtonClick} text="Task list" />
    </div>
  </div>
);

export default PagingButtonGroupNotFound;
