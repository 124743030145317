import {
  CLEAR_CHECKITEM_DATA,
  SET_CHECKITEM_CONFIG,
  SET_CHECKITEM_DATA,
  ADD_INITIAL_CHECK_RESULT,
  UPDATE_CHECK_RESULT,
  UPDATE_NOTE,
} from '../constants/actions';
import { formatCheckItem } from '../utils/format';

const initialState = {
  data: [],
  results: [],
  config: {},
  type: 'HIERARCHY_LABEL',
};

const setCheckItemData = (state, action) => {
  let updatedState = {
    ...state,
    data: [],
  };

  if (action.payload && action.payload.data) {
    updatedState = {
      ...updatedState,
      data: action.payload.data.map((i) => formatCheckItem(i)),
    };
  }
  return updatedState;
};

const addInitialCheckResult = (state, action) => {
  const { checkitemId } = action.payload;
  const found = state.results.find(
    (checkResult) => checkResult.checkitemId === checkitemId
  );
  if (found) {
    return state;
  }
  return {
    ...state,
    results: [
      ...state.results,
      {
        checkitemId: action.payload.checkitemId,
        checkResult: [],
        note: '',
        actionType: null,
        actionTime: null,
      },
    ],
  };
};

const updateCheckResult = (state, action) => {
  if (
    action?.payload?.checkitemId &&
    action?.payload?.checkResult &&
    action?.payload?.actionType &&
    action?.payload?.actionTime
  ) {
    const { checkitemId, checkResult, actionType, actionTime } = action.payload;
    const elementIdx = state.results.findIndex(
      (result) => result.checkitemId === checkitemId
    );
    const foundedCheckResult = state.results[elementIdx];
    foundedCheckResult.checkResult = checkResult;
    foundedCheckResult.actionType = actionType;
    foundedCheckResult.actionTime = actionTime;
    state.results.splice(elementIdx, 1);
    state.results.push(foundedCheckResult);
  }
  return state;
};

const updateNote = (state, action) => {
  if (action?.payload?.checkitemId && action?.payload?.note) {
    const { checkitemId, note } = action.payload;
    const elementIdx = state.results.findIndex(
      (result) => result.checkitemId === checkitemId
    );
    const foundedCheckResult = state.results[elementIdx];
    foundedCheckResult.note = note;
    state.results.splice(elementIdx, 1);
    state.results.push(foundedCheckResult);
  }
  return state;
};

const clearCheckItemData = (state) => {
  const newState = {
    ...state,
    data: [],
    results: [],
  };

  return newState;
};

const setCheckItemConfig = (state, action) => ({
  ...state,
  type: action.payload.type,
  config: action.payload.config,
});

const reducer = (state, action) => {
  switch (action.type) {
    case SET_CHECKITEM_DATA:
      return setCheckItemData(state, action);
    case CLEAR_CHECKITEM_DATA:
      return clearCheckItemData(state);
    case SET_CHECKITEM_CONFIG:
      return setCheckItemConfig(state, action);
    case ADD_INITIAL_CHECK_RESULT:
      return addInitialCheckResult(state, action);
    case UPDATE_CHECK_RESULT:
      return updateCheckResult(state, action);
    case UPDATE_NOTE:
      return updateNote(state, action);
    default:
      return state;
  }
};

export default { state: initialState, reducer };
