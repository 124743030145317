import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';

const GenerateButton = (props) => {
  const { loading, message } = props;
  return (
    <Button className="generate-button" {...props}>
      {loading === true ? (
        <>
          <FontAwesomeIcon icon="circle-notch" className="spin" />{' '}
          <span>Generate</span>
        </>
      ) : (
        <span>{message || 'Save'}</span>
      )}
    </Button>
  );
};

export default GenerateButton;
