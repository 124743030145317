// State to display result status
export const SUCCESS = 'SUCCESS';
export const ERROR = 'ERROR';

// State to display current status
export const INITIAL = 'INITIAL';
export const PENDING = 'PENDING';
export const LOADING = 'LOADING';
export const FETCHING = 'FETCHING';
export const CREATING = 'CREATING';
export const RENDERED = 'RENDERED';

// State to redirect to somewhere
export const REDIRECT = 'REDIRECT';

// State to reset to initial data
export const RESET = 'RESET';
