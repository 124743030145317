import React from 'react';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';

library.add(faChevronRight);

const NextButton = ({ onClick, text, disabled }) => (
  <Button
    className="next-button"
    color="link"
    onClick={onClick}
    disabled={disabled}
  >
    {text}
    {'  '}
    <FontAwesomeIcon icon={['far', 'chevron-right']} />
  </Button>
);

export default NextButton;
