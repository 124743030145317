import React, { useContext } from 'react';

import { useHistory } from 'react-router-dom';

import { SET_TASKS_QUERY } from '../../constants/actions';
import { TASK_CREATE } from '../../constants/route';
import { AppContext } from '../../store';
import { canCreateTask } from '../../utils/permission';
import { to } from '../../utils/utils';
import CreateNewTaskButton from '../atoms/CreateNewTaskButton';
import SearchBox from '../molecules/SearchBox';
import TaskListController from '../organisms/TaskListController';

const TaskListContainer = ({ data, setCurrentPage, exportCheckItem }) => {
  const { state, dispatch } = useContext(AppContext);
  const {
    task: { tasksCount, selectedTaskListPage: currentPage, tasksQuery },
    auth,
  } = state;
  const history = useHistory();

  const permissions = auth.user.permissions || [];
  const pageSize = 10;
  const totalPage = tasksCount ? Math.ceil(tasksCount / pageSize) : 1;
  const page = {
    currentPage,
    setCurrentPage,
    totalPage,
    segmentSize: 5,
  };

  const goToCreateTask = () => {
    history.push(to(TASK_CREATE));
  };

  const updateQueriedTasks = (query) => {
    dispatch({
      type: SET_TASKS_QUERY,
      payload: {
        tasksQuery: query,
      },
    });
  };

  return permissions ? (
    <div className="task-list-container">
      <div className="title">Task list</div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div className="panel">
          {canCreateTask(auth) && (
            <CreateNewTaskButton onClick={goToCreateTask} />
          )}
          <SearchBox
            value={tasksQuery}
            onClickSearchButton={updateQueriedTasks}
            onKeyEnterDown={updateQueriedTasks}
          />
        </div>
      </div>
      <TaskListController
        tasks={data}
        page={page}
        exportCheckItem={exportCheckItem}
      />
    </div>
  ) : (
    ''
  );
};

export default TaskListContainer;
