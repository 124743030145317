import React from 'react';

import Body from '../templates/Body';
import Footer from '../templates/Footer';
import Header from '../templates/Header';
import Layout from '../templates/Layout';
import TaskRecentActivityContainer from '../templates/TaskRecentActivityContainer';

const TaskRecentActivity = ({ data }) => (
  <Layout>
    <Header />
    <Body>
      <TaskRecentActivityContainer data={data} />
    </Body>
    <Footer />
  </Layout>
);

export default TaskRecentActivity;
