import React from 'react';

// import ErrorAlert from '../atoms/ErrorAlert';
import Logo from '../atoms/Logo';
import Body from '../templates/Body';
import Footer from '../templates/Footer';
import Layout from '../templates/Layout';
import LoginForm from '../templates/LoginForm';

const LoginLogo = () => <Logo />;
const LoginPage = ({ errorMessage, userLogin }) => (
  <Layout>
    <Body>
      <LoginLogo />
      <LoginForm userLogin={userLogin} errorMessage={errorMessage} />
    </Body>
    <Footer />
  </Layout>
);

export default LoginPage;
