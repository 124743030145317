import React, { useContext } from 'react';

import { useHistory } from 'react-router-dom';
import Toggle from 'react-toggle';
import { Col, Row } from 'reactstrap';

import { TASK_LIST } from '../../constants/route';
import { AppContext } from '../../store';
import { to } from '../../utils/utils';
import ButtonGroup from '../molecules/ButtonGroup';
import TaskAddCheckItemBySyncZocialEyeController from '../organisms/TaskAddCheckItemBySyncZocialEyeController';
import TaskAddCheckItemByUploadFileController from '../organisms/TaskAddCheckItemByUploadFileController';

const TaskAddCheckItemContainer = ({
  saveCheckItem,
  syncTask,
  disabledSaveButton,
  setDisabledSaveButton,
  modeChangeDisabled,
  setModeChangeDisabled,
  isSyncMode,
  setIsSyncMode,
  data,
  setData,
}) => {
  const { state } = useContext(AppContext);

  const history = useHistory();

  const {
    selectedTask: { config, stats },
  } = state.task;
  const backToTaskList = () => {
    history.push(to(TASK_LIST));
  };

  return (
    <div className="task-add-checkitem-container">
      <div className="title">
        Import items
        {(config?.checkItemType === 'zocial-eye-v1.0' ||
          config?.checkItemType === 'zocial-eye-v2' ||
          config?.checkItemType === 'redsmith-v1' ||
          config?.checkItemType === 'check-it-off') &&
          stats?.total === 0 && (
            <div className="task-add-checkitem-mode-controller">
              Upload
              <Toggle
                defaultChecked={isSyncMode}
                icons={false}
                onChange={() => {
                  setIsSyncMode(!isSyncMode);
                }}
                disabled={modeChangeDisabled}
              />
              Sync
            </div>
          )}
      </div>
      {isSyncMode ? (
        <TaskAddCheckItemBySyncZocialEyeController
          setDisabledSaveButton={setDisabledSaveButton}
          syncTask={syncTask}
        />
      ) : (
        <TaskAddCheckItemByUploadFileController
          taskConfig={config}
          setDisabledSaveButton={setDisabledSaveButton}
          setModeChangeDisabled={setModeChangeDisabled}
          data={data}
          setData={setData}
        />
      )}
      <Row className="task-action">
        <Col md={12}>
          <ButtonGroup
            onSaveButtonClick={isSyncMode ? syncTask : saveCheckItem}
            disableSaveButton={disabledSaveButton}
            onBackButtonClick={backToTaskList}
          />
        </Col>
      </Row>
    </div>
  );
};

export default TaskAddCheckItemContainer;
