import React, { useContext, useEffect, useState } from 'react';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faAngleDown, faSignOut } from '@fortawesome/pro-light-svg-icons';
import { faBars, faUser } from '@fortawesome/pro-regular-svg-icons';
import {
  faCaretLeft,
  faCaretRight,
  faLock,
  faUserCircle,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Redirect } from 'react-router-dom';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Navbar,
  UncontrolledDropdown,
} from 'reactstrap';

import LogoImageSmall from '../../assets/images/logo-inverted-icon.png';
import { LOGOUT, SET_SIDEBAR } from '../../constants/actions';
import { INITIAL, PENDING, REDIRECT, RENDERED } from '../../constants/state';
import { AppContext } from '../../store';

library.add(
  faBars,
  faUser,
  faLock,
  faUserCircle,
  faCaretRight,
  faCaretLeft,
  faAngleDown,
  faSignOut
);

const TabletHeader = ({
  name,
  onClickLogout,
  onClickToggleSideBar,
  sideBarCaret,
}) => (
  <Navbar>
    <button
      type="button"
      className="sidebarToggler"
      onClick={onClickToggleSideBar}
    >
      <FontAwesomeIcon icon={['far', 'bars']} />
      <FontAwesomeIcon icon={['fas', sideBarCaret]} />
    </button>
    <div className="mobileLogo">
      <img src={LogoImageSmall} alt="logo" className="small" />
    </div>
    <div className="top-right tablet">
      <UncontrolledDropdown nav inNavbar>
        <DropdownToggle nav>
          {name} <FontAwesomeIcon icon={['fal', 'angle-down']} />
        </DropdownToggle>
        <DropdownMenu right className="dropdown-content">
          <DropdownItem onClick={onClickLogout}>
            <FontAwesomeIcon icon={['fal', 'sign-out']} /> Logout
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  </Navbar>
);

const Header = () => {
  const [stateStatus, setStateStatus] = useState(INITIAL);
  const { state, dispatch } = useContext(AppContext);
  const Logout = () => {
    dispatch({
      type: LOGOUT,
    });

    setStateStatus(REDIRECT);
  };

  const sidebarCaretDirection =
    state.app.expandSideBar === 'expand' ? 'caret-left' : 'caret-right';
  const toggleSideBar = () => {
    const status =
      state.app.expandSideBar === 'expand' ? 'collapsed' : 'expand';
    dispatch({
      type: SET_SIDEBAR,
      payload: {
        expandSideBar: status,
      },
    });
  };

  const { auth } = state;
  const name = auth.user ? auth.user.name : '';

  useEffect(() => {
    switch (stateStatus) {
      case INITIAL:
        setStateStatus(PENDING);
        break;
      case PENDING:
        setStateStatus(RENDERED);
        break;
      default:
        break;
    }
  }, [stateStatus]);

  return stateStatus === REDIRECT ? (
    <Redirect
      to={{
        pathname: '/login',
      }}
    />
  ) : stateStatus === RENDERED ? (
    <div className="header">
      <div>
        <TabletHeader
          name={name}
          onClickLogout={Logout}
          onClickToggleSideBar={toggleSideBar}
          sideBarCaret={sidebarCaretDirection}
        />
      </div>
    </div>
  ) : (
    ''
  );
};

export default Header;
