import ReactGA4 from 'react-ga4';

const initializeGoogleAnalytics = () => {
  ReactGA4.initialize(
    process.env.REACT_APP_CHK_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID
  );
};

const trackRecentActivitiesButton = () => {
  ReactGA4.event({
    category: 'Recent Activities Button',
    action: 'Recent Activities Click',
  });
};

export { initializeGoogleAnalytics, trackRecentActivitiesButton };
