import React, { useContext, useState } from 'react';

import { useHistory } from 'react-router-dom';

import { USER_CREATE } from '../../constants/route';
import { AppContext } from '../../store';
import Pagination from '../../utils/pagination';
import { canCreateUser } from '../../utils/permission';
import { to } from '../../utils/utils';
import CreateNewUserButton from '../atoms/CreateNewUserButton';
import SearchBox from '../molecules/SearchBox';
import UserListController from '../organisms/UserListController';

const UserListContainer = ({ data }) => {
  const { state } = useContext(AppContext);
  const { auth } = state;
  const history = useHistory();

  const permissions = auth.user.permissions || [];
  const [queriedUsers, setQueriedUsers] = useState(data);
  const limitPage = 1;
  const limitPageSize = 10;
  const [currentPage, setCurrentPage] = useState(limitPage);
  const total =
    queriedUsers && queriedUsers.length > 0 ? queriedUsers.length : 0;
  const pageSize = limitPageSize;
  const {
    start,
    end,
    size: totalPage,
  } = Pagination.calculate(total, currentPage, pageSize);
  const users = queriedUsers ? queriedUsers.slice(start, end) : [];
  const page = { currentPage, setCurrentPage, totalPage, segmentSize: 5 };

  const goToCreateUser = () => {
    history.push(to(USER_CREATE));
  };

  const updateQueriedUsers = (filter) => {
    const queriedUsers = data.filter(
      ({ name, username }) =>
        name.toLowerCase().trim().includes(filter.toLowerCase().trim()) ||
        username.toLowerCase().trim().includes(filter.toLowerCase().trim())
    );
    setQueriedUsers(queriedUsers);
  };

  return permissions ? (
    <div className="user-list-container">
      <div className="title">User list</div>
      <div className="panel">
        {canCreateUser(auth) && (
          <CreateNewUserButton onClick={goToCreateUser} />
        )}
        <SearchBox onKeyEnterDown={updateQueriedUsers} />
      </div>
      <UserListController users={users} page={page} />
    </div>
  ) : (
    ''
  );
};

export default UserListContainer;
