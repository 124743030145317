import React, { useState } from 'react';

import { Col, Row } from 'reactstrap';

import { validateAlphanumeric } from '../../utils/utils';
import ErrorAlert from '../atoms/ErrorAlert';
import LoginButton from '../atoms/LoginButton';
import TextInput from '../atoms/TextInput';

const LoginGroup = ({ userLogin, errorMessage }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [disableLoginButton, setDisableLoginButton] = useState(true);

  const getClassname = (value) => {
    let className = '';

    if (!value) {
      className = '';
    } else if (validateAlphanumeric(value)) {
      // className = 'is-valid';
      className = '';
    } else {
      // className = 'is-invalid';
      className = '';
    }

    return className;
  };

  const usernameClassName = getClassname(username);
  const passwordClassName = getClassname(password);

  const inputUsername = ({ target }) => {
    setUsername(target.value);
    setDisableLoginButton(
      !validateAlphanumeric(target.value) || !validateAlphanumeric(password)
    );
  };

  const inputPassword = ({ target }) => {
    setPassword(target.value);
    setDisableLoginButton(
      !validateAlphanumeric(username) || !validateAlphanumeric(target.value)
    );
  };

  const updateUserLogin = () => {
    userLogin(username, password);
    setUsername('');
    setPassword('');
    setDisableLoginButton(true);
  };

  return (
    <Row className="login-group">
      <Col xs={12} className="form-group">
        <TextInput
          label="Username"
          name="username"
          placeholder="Username"
          value={username}
          onChange={inputUsername}
          className={usernameClassName}
        />
      </Col>
      <Col xs={12} className="form-group">
        <TextInput
          label="Password"
          type="password"
          name="password"
          placeholder="Password"
          value={password}
          onChange={inputPassword}
          onKeyDown={({ key }) => {
            if (key === 'Enter') {
              updateUserLogin();
            }
          }}
          className={passwordClassName}
        />
      </Col>
      <Col xs={12}>{errorMessage && <ErrorAlert message={errorMessage} />}</Col>
      <Col xs={12} className="form-group">
        {/* <LoginButton onClick={updateUserLogin} disabled={disableLoginButton} /> */}
        <LoginButton onClick={updateUserLogin} />
      </Col>
    </Row>
  );
};

export default LoginGroup;
