import React, { useContext, useEffect, useState } from 'react';

import { useAlert } from 'react-alert';
import { Redirect } from 'react-router-dom';

import Loading from '../components/molecules/Loading';
import TaskRecentActivityComponent from '../components/views/TaskRecentActivity';
import { SET_SELECTED_TASK } from '../constants/actions';
import {
  ALERT_TASK_INACTIVE_ERROR,
  ALERT_TASK_NOT_AVAILABLE,
  ALERT_TASK_NOT_FOUND_ERROR,
} from '../constants/messages';
import { TASK_LIST } from '../constants/route';
import {
  FETCHING,
  INITIAL,
  PENDING,
  REDIRECT,
  RENDERED,
  SUCCESS,
} from '../constants/state';
import Api from '../services/api';
import { AppContext } from '../store';
import Datetime from '../utils/datetime';
import { formatCheckItem } from '../utils/format';
import { isTaskScheduleActive } from '../utils/utils';

const TaskRecentActivity = ({ match }) => {
  const { state, dispatch } = useContext(AppContext);
  const [stateStatus, setStateStatus] = useState(INITIAL);
  const [logs, setLogs] = useState(null);
  const alert = useAlert();

  const {
    auth: {
      user: { token },
    },
  } = state;

  const { id: taskId } = match.params;

  useEffect(() => {
    const getRecentActivityLogs = async (token) => {
      const api = new Api(token);
      const task = await api.getTask(taskId);

      if (task && task.id) {
        dispatch({
          type: SET_SELECTED_TASK,
          payload: { selectedTask: task },
        });
      } else {
        setStateStatus(REDIRECT);
        alert.show(ALERT_TASK_NOT_FOUND_ERROR, {
          timeout: 3000,
          type: 'error',
        });
        return;
      }

      if (
        task &&
        task.currentSchedule &&
        !isTaskScheduleActive(task.currentSchedule.schedule)
      ) {
        setStateStatus(REDIRECT);
        alert.show(ALERT_TASK_INACTIVE_ERROR, {
          timeout: 3000,
          type: 'error',
        });
        return;
      }

      const activityLogs = await api.getTaskActivityLogs(taskId);
      if (!activityLogs) {
        setStateStatus(REDIRECT);
        alert.show(ALERT_TASK_NOT_AVAILABLE, {
          timeout: 3000,
          type: 'error',
        });
        return;
      }

      const activityLogsWithDetail = await Promise.all(
        activityLogs.map((log) =>
          api
            .getCheckedCheckItem(log.checkItemID, log.taskID)
            .then((checkItem) => ({
              ...formatCheckItem(checkItem),
              checkedAt: Datetime.toFormat(
                log.actionAt,
                'YYYY-MM-DD[T]HH:mm:ssz[Z]',
                'YYYY-MM-DD HH:mm:ss',
                'UTC',
                'Asia/Bangkok'
              ),
            }))
        )
      );
      setLogs(activityLogsWithDetail);
      setStateStatus(SUCCESS);
    };

    switch (stateStatus) {
      case INITIAL:
        setStateStatus(PENDING);
        break;
      case PENDING:
        setStateStatus(FETCHING);
        break;
      case FETCHING:
        getRecentActivityLogs(token);
        break;
      case SUCCESS:
        setStateStatus(RENDERED);
        break;
      default:
        break;
    }
  }, [alert, stateStatus, taskId, token]);

  return (
    <>
      {stateStatus === REDIRECT && <Redirect to={TASK_LIST} />}
      {stateStatus === RENDERED ? (
        <div id="task_recent_activity">
          <TaskRecentActivityComponent data={logs} />
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default TaskRecentActivity;
