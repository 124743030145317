import React, { useState } from 'react';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faCommentPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Button } from 'reactstrap';

library.add(faCommentPlus);

const AddCheckItemButton = ({ disabled, onClick, taskId }) => (
  <OverlayTrigger
    key="top"
    placement="top"
    overlay={
      <Tooltip id={`${taskId}-tooltip-add-check-item-button}`}>
        <strong>INSERT CHECK ITEM</strong>
      </Tooltip>
    }
  >
    <Button
      className="start-button btn-outline"
      disabled={disabled}
      onClick={onClick}
      id={`${taskId}--add-check-item-button`}
    >
      <FontAwesomeIcon icon={['far', 'comment-plus']} />
    </Button>
  </OverlayTrigger>
);

export default AddCheckItemButton;
