import React from 'react';

import CreateUserButtonGroup from '../molecules/CreateUserButtonGroup';
import UserForm from '../organisms/UserForm';
import Body from '../templates/Body';
import Footer from '../templates/Footer';
import Header from '../templates/Header';
import Layout from '../templates/Layout';

const UserCreate = ({
  onClickCreateUserButton,
  onClickCancelButton,
  validUser,
}) => (
  <Layout>
    <Header />
    <Body>
      <div className="user-create-container">
        <div className="title">Create a user</div>
        <div className="user-create-form">
          <UserForm />
          <CreateUserButtonGroup
            onClickCreateUserButton={onClickCreateUserButton}
            onClickCancelButton={onClickCancelButton}
            disableCreateUserButton={!validUser}
            disableCancelButton={false}
          />
        </div>
      </div>
    </Body>
    <Footer />
  </Layout>
);

export default UserCreate;
