import React, { useContext, useState } from 'react';

import { AppContext } from '../../store';
import Pagination from '../../utils/pagination';
import TaskRecentActivityController from '../organisms/TaskRecentActivityController';

const TaskRecentActivityContainer = ({ data }) => {
  const { state } = useContext(AppContext);
  const {
    auth: { user },
  } = state;

  const permissions = user.permissions || [];
  const limitPage = 1;
  const limitPageSize = 10;
  const [currentPage, setCurrentPage] = useState(limitPage);
  const total = data && data.length > 0 ? data.length : 0;
  const pageSize = limitPageSize;
  const {
    start,
    end,
    size: totalPage,
  } = Pagination.calculate(total, currentPage, pageSize);
  const logs = data ? data.slice(start, end) : [];
  const page = { currentPage, setCurrentPage, totalPage, segmentSize: 5 };

  return permissions ? (
    <div className="task-recent-activity-container">
      <div className="title">Recent activities</div>
      <TaskRecentActivityController logs={logs} page={page} />
    </div>
  ) : (
    ''
  );
};

export default TaskRecentActivityContainer;
