import React from 'react';

import BackButton from '../atoms/BackButton';
import NextButton from '../atoms/NextButton';

const PagingButtonGroup = ({
  onBackButtonClick,
  onNextButtonClick,
  nextDisabled,
}) => (
  <div className="action-button-group">
    <div className="btn-group-1">
      <BackButton onClick={onBackButtonClick} text="Task list" />
    </div>
    <div className="btn-group-2">
      <NextButton
        onClick={onNextButtonClick}
        text="Save All"
        disabled={nextDisabled}
      />
    </div>
  </div>
);

export default PagingButtonGroup;
