import React from 'react';

import { Button } from 'reactstrap';

const SubmitButton = ({ value, text, disabled, onClick }) => (
  <Button
    value={value}
    disabled={disabled}
    onClick={onClick}
    color="link"
    className="submit-button"
  >
    {text}
  </Button>
);
export default SubmitButton;
