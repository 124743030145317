import React from 'react';

import EditUserButtonGroup from '../molecules/EditUserButtonGroup';
import UserForm from '../organisms/UserForm';
import Body from '../templates/Body';
import Footer from '../templates/Footer';
import Header from '../templates/Header';
import Layout from '../templates/Layout';

const UserEdit = ({
  onClickSaveUserButton,
  onClickDeleteUserButton,
  onClickCancelButton,
  validUser,
}) => (
  <Layout>
    <Header />
    <Body>
      <div className="user-create-container">
        <div className="title">Edit user</div>
        <div className="user-create-form">
          <UserForm mode="edit" />
          <EditUserButtonGroup
            onClickSaveUserButton={onClickSaveUserButton}
            onClickDeleteUserButton={onClickDeleteUserButton}
            onClickCancelButton={onClickCancelButton}
            disableSaveUserButton={!validUser}
            disableCancelButton={false}
          />
        </div>
      </div>
    </Body>
    <Footer />
  </Layout>
);

export default UserEdit;
