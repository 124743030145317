import React from 'react';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faCalendarAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment-timezone';
import ReactDateRangePicker from 'react-bootstrap-daterangepicker';
import { Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';

library.add(faCalendarAlt);

function getDateTimeFormat(enableTimePicker, enableTime24Picker) {
  if (!enableTimePicker) {
    return 'DD MMM YYYY';
  }
  return enableTime24Picker ? 'DD MMM YYYY HH:mm' : 'DD MMM YYYY hh:mm';
}

const DateRangePicker = ({
  startDateTime,
  endDateTime,
  timePicker,
  timePicker24Hour,
  onApply,
}) => {
  const enableTimePicker = timePicker !== false;
  const enableTime24Picker = timePicker24Hour !== false;
  const startDate =
    startDateTime ||
    moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  const endDate =
    endDateTime ||
    moment()
      .set({ hour: 23, minute: 59, second: 59, millisecond: 59 })
      .add(1, 'month');
  const displayValue = `${startDate.format(
    getDateTimeFormat(enableTimePicker, enableTime24Picker)
  )} to ${endDate.format(
    getDateTimeFormat(enableTimePicker, enableTime24Picker)
  )}`;

  return (
    <ReactDateRangePicker
      onApply={onApply}
      startDate={startDate}
      endDate={endDate}
      timePicker={enableTimePicker}
      timePicker24Hour={enableTime24Picker}
    >
      <InputGroup>
        <Input type="text" value={displayValue} className="col-12" readOnly />
        <InputGroupAddon addonType="append">
          <InputGroupText>
            <FontAwesomeIcon icon={['far', 'calendar-alt']} />
          </InputGroupText>
        </InputGroupAddon>
      </InputGroup>
    </ReactDateRangePicker>
  );
};

export default DateRangePicker;
