import React from 'react';

import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

const TablePagination = ({
  currentPage,
  totalPage,
  setCurrentPage,
  segmentSize,
}) => {
  const segmentIdx = Math.floor((currentPage - 1) / segmentSize);
  const totalSegment = Math.floor((totalPage - 1) / segmentSize);

  const pageList = [...Array(segmentSize)].map((_, idx) => {
    const pageNumber = segmentIdx * segmentSize + idx + 1;

    if (pageNumber > totalPage) {
      return null;
    }
    return (
      <PaginationItem active={pageNumber === currentPage} key={pageNumber}>
        <PaginationLink onClick={() => setCurrentPage(pageNumber)}>
          {pageNumber}
        </PaginationLink>
      </PaginationItem>
    );
  });

  const previous = currentPage > 1 && segmentIdx > 0 && (
    <PaginationItem>
      <PaginationLink
        onClick={() => setCurrentPage(segmentIdx * segmentSize)}
        previous
      />
    </PaginationItem>
  );

  const next = currentPage < totalPage && segmentIdx < totalSegment && (
    <PaginationItem>
      <PaginationLink
        onClick={() => setCurrentPage((segmentIdx + 1) * segmentSize + 1)}
        next
      />
    </PaginationItem>
  );

  const first = currentPage > 1 && segmentIdx > 0 && (
    <PaginationItem>
      <PaginationLink onClick={() => setCurrentPage(1)} first />
    </PaginationItem>
  );

  const last = currentPage < totalPage && segmentIdx < totalSegment && (
    <PaginationItem>
      <PaginationLink onClick={() => setCurrentPage(totalPage)} last />
    </PaginationItem>
  );

  return (
    <Pagination className="table-pagination">
      {first}
      {previous}
      {pageList}
      {next}
      {last}
    </Pagination>
  );
};

export default TablePagination;
