import React from 'react';

import { Button } from 'reactstrap';

const RejectButton = ({ message, ...props }) => (
  <Button className="reject-button" color="danger" {...props}>
    {message || 'Reject'}
  </Button>
);

export default RejectButton;
