import {
  SET_APP_LOADING,
  SET_APP_STATE,
  SET_SIDEBAR,
} from '../constants/actions';

const initialState = {
  state: 'initial',
  isLoading: false,
  expandSideBar: 'collapsed',
};

const reducer = (state, action) => {
  switch (action.type) {
    case SET_APP_STATE:
      return { ...state, state: action.payload.state };
    case SET_APP_LOADING:
      return { ...state, loading: action.payload.loading };
    case SET_SIDEBAR:
      return { ...state, expandSideBar: action.payload.expandSideBar };
    default:
      return state;
  }
};

export default { state: initialState, reducer };
