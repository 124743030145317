import React, { useContext, useEffect, useState } from 'react';

import { useAlert } from 'react-alert';
import { Redirect } from 'react-router-dom';

import Loading from '../components/molecules/Loading';
import TaskStatisticComponent from '../components/views/TaskStatistic';
import { SET_SELECTED_TASK } from '../constants/actions';
import {
  ALERT_STATISTIC_EXPORT_ERROR,
  ALERT_STATISTIC_EXPORT_SUCCESS,
  ALERT_TASK_NOT_FOUND_ERROR,
} from '../constants/messages';
import { TASK_LIST } from '../constants/route';
import {
  FETCHING,
  INITIAL,
  PENDING,
  REDIRECT,
  RENDERED,
  SUCCESS,
} from '../constants/state';
import Api from '../services/api';
import Excel from '../services/excel';
import { AppContext } from '../store';
import { canManageTask } from '../utils/permission';

const TaskStatistic = ({ match }) => {
  const { state, dispatch } = useContext(AppContext);
  const [stateStatus, setStateStatus] = useState(INITIAL);
  const [labelStatistic, setLabelStatistic] = useState([]);
  const [isShowExportStatisticButton, setIsShowExportStatisticButton] =
    useState(false);
  const alert = useAlert();

  const {
    auth: {
      user: { token },
    },
  } = state;

  const { id: selectedTaskId } = match.params;

  const writeExcel = async (data, log) => {
    try {
      log.forEach((row) => {
        row['duration (s)'] = row.duration;
        delete row.duration;
      });

      const workBook = Excel.writeExcel(data);
      Excel.createLogSheet(workBook, log);
      await Excel.downloadExcelFile(workBook, selectedTaskId);
      alert.show(ALERT_STATISTIC_EXPORT_SUCCESS, {
        timeout: 3000,
        type: 'success',
      });
    } catch (error) {
      alert.show(ALERT_STATISTIC_EXPORT_ERROR, {
        timeout: 3000,
        type: 'error',
      });
    }
  };

  const getLabelStatistic = async (token) => {
    const api = new Api(token);
    const result = await api.getLabelStatistic(selectedTaskId);
    return result;
  };

  const exportStatistic = async () => {
    const { userStatistics, userLogs } = await getLabelStatistic(token);
    setLabelStatistic(userStatistics);
    writeExcel(userStatistics, userLogs);
  };

  useEffect(() => {
    const fetchStatistic = async () => {
      const { userStatistics, userLogs } = await getLabelStatistic(token);
      if (userStatistics || userLogs) {
        setLabelStatistic(userStatistics);
      }
    };

    const getTask = async (token, taskID) => {
      const api = new Api(token);
      const task = await api.getTask(taskID);

      if (task && task.id) {
        dispatch({
          type: SET_SELECTED_TASK,
          payload: { selectedTask: task },
        });
        await fetchStatistic();
        setStateStatus(SUCCESS);
      } else {
        setStateStatus(REDIRECT);
        alert.show(ALERT_TASK_NOT_FOUND_ERROR, {
          timeout: 3000,
          type: 'error',
        });
      }
    };

    switch (stateStatus) {
      case INITIAL:
        setStateStatus(PENDING);
        setIsShowExportStatisticButton(canManageTask(state.auth));
        break;
      case PENDING:
        setStateStatus(FETCHING);
        break;
      case FETCHING:
        getTask(token, selectedTaskId);
        break;
      case SUCCESS:
        setStateStatus(RENDERED);
        break;
      default:
        break;
    }
  }, [stateStatus, token]);

  return (
    <>
      {stateStatus === REDIRECT && <Redirect to={TASK_LIST} />}
      {stateStatus === RENDERED ? (
        <div id="task_statistic">
          <TaskStatisticComponent
            data={labelStatistic}
            taskId={selectedTaskId}
            exportStatistic={exportStatistic}
            isShowExportStatisticButton={isShowExportStatisticButton}
          />
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default TaskStatistic;
