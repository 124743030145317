import React, { useState } from 'react';

import { useHistory } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

import { TASK_LIST } from '../../constants/route';
import Pagination from '../../utils/pagination';
import { to } from '../../utils/utils';
import ClearReservedCheckItemsButtonGroup from '../molecules/ClearReservedCheckItemsButtonGroup';
import SearchBox from '../molecules/SearchBox';
import TaskClearReservedCheckItemsController from '../organisms/TaskClearReservedCheckItemsController';

const TaskClearReservedCheckItemsContainer = ({ users, clearCheckItems }) => {
  const [selectedUsersToClear, setSelectedUsersToClear] = useState([]);
  const [disableClearButton, setDisableClearButton] = useState(true);
  const [queriedUsers, setQueriedUsers] = useState(users);

  const limitPage = 1;
  const limitPageSize = 10;
  const [currentPage, setCurrentPage] = useState(limitPage);

  const total =
    queriedUsers && queriedUsers.length > 0 ? queriedUsers.length : 0;
  const pageSize = limitPageSize;
  const {
    start,
    end,
    size: totalPage,
  } = Pagination.calculate(total, currentPage, pageSize);
  const displayedUsers = queriedUsers ? queriedUsers.slice(start, end) : [];
  const page = { currentPage, setCurrentPage, totalPage, segmentSize: 5 };
  const history = useHistory();

  const backToTaskList = () => {
    history.push(to(TASK_LIST));
  };

  const updateQueriedUsers = (filter) => {
    const queriedUsers = users.filter(
      ({ name, username }) =>
        name.toLowerCase().trim().includes(filter.toLowerCase().trim()) ||
        username.toLowerCase().trim().includes(filter.toLowerCase().trim())
    );
    setQueriedUsers(queriedUsers);
  };

  const clearForSelectedUsers = () => {
    clearCheckItems(selectedUsersToClear);
  };

  const clearAll = () => {
    clearCheckItems(null);
  };

  const updateSelectUsersToClear = (userID) => {
    const updatedSelectUsers = selectedUsersToClear.includes(userID)
      ? selectedUsersToClear.filter((item) => item !== userID)
      : [...selectedUsersToClear, userID];
    setDisableClearButton(updatedSelectUsers.length === 0);
    setSelectedUsersToClear(updatedSelectUsers);
  };

  return (
    <div className="task-clear-reserved-checkitems-container">
      <div className="title">Clear reserved checkable-items</div>

      <SearchBox onKeyEnterDown={updateQueriedUsers} icon />
      <TaskClearReservedCheckItemsController
        users={displayedUsers}
        page={page}
        updateSelectUsersToClear={updateSelectUsersToClear}
        selectedUsersToClear={selectedUsersToClear}
      />
      <Row className="task-action">
        <Col md={12}>
          <ClearReservedCheckItemsButtonGroup
            onClickClearAllButton={clearAll}
            onClickClearButton={clearForSelectedUsers}
            onClickCancelButton={backToTaskList}
            disableClearAllButton={false}
            disableClearButton={disableClearButton}
            disableCancelButton={false}
          />
        </Col>
      </Row>
    </div>
  );
};

export default TaskClearReservedCheckItemsContainer;
