import React from 'react';

import Body from '../templates/Body';
import Footer from '../templates/Footer';
import Header from '../templates/Header';
import Layout from '../templates/Layout';
import TaskEditCheckResultsContainer from '../templates/TaskEditCheckResultsContainer';

const TaskEditCheckResults = ({
  checkableItem,
  checkResultConfig,
  updateTaskResult,
  checkResultType,
  userStat,
  initialCheckResultList,
  initialNote,
}) => (
  <Layout>
    <Header />
    <Body>
      <div className="title">Task</div>
      <TaskEditCheckResultsContainer
        checkableItem={checkableItem}
        checkResultConfig={checkResultConfig}
        updateTaskResult={updateTaskResult}
        checkResultType={checkResultType}
        userStat={userStat}
        initialCheckResultList={initialCheckResultList}
        initialNote={initialNote}
      />
    </Body>
    <Footer />
  </Layout>
);

export default TaskEditCheckResults;
