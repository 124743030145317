import React, { useEffect, useState } from 'react';

import { Media } from 'reactstrap';
import truncate from 'truncate';

import Highlighter from '../atoms/Highlighter';
import ReadMore from '../atoms/ReadMoreButton';

const ZocialEyeV1View = ({ detail, mainKeywords, subKeywords }) => {
  const [message, setMessage] = useState(detail);
  const [seeMore, setSeeMore] = useState();

  useEffect(() => {
    if (seeMore) {
      setMessage(detail);
    } else {
      setMessage(truncate(detail, 400));
    }
  }, [detail, seeMore]);

  useEffect(() => {
    if (detail.length > 400) {
      setSeeMore(false);
    }
  }, [detail]);

  return (
    <div className="zocial-eye-v1-view">
      <Media>
        <Media body>
          <p className="media-panel">
            {seeMore !== undefined ? (
              <>
                <span>
                  <Highlighter
                    text={message}
                    mainKeywords={mainKeywords}
                    subKeywords={subKeywords}
                  />
                </span>{' '}
                <ReadMore isTruncated={seeMore} toggle={setSeeMore} />
              </>
            ) : (
              <Highlighter
                text={message}
                mainKeywords={mainKeywords}
                subKeywords={subKeywords}
              />
            )}
          </p>
        </Media>
      </Media>
    </div>
  );
};

export default ZocialEyeV1View;
