import React from 'react';

import { Col, Row } from 'reactstrap';

import AddButton from '../atoms/AddButton';
import TagsInputWithSuggestion from '../atoms/TagsInputWithSuggestion';

const ResultAdd = ({
  tags,
  onClickDeleteButton,
  onClickAddButton,
  onClickSelectOption,
  disableTagsInput,
  disableAddButton,
  suggestions,
  suggestionsTransform,
}) => (
  <Row className="result-add">
    <TagsInputWithSuggestion
      tags={tags}
      onAddition={onClickSelectOption}
      onDelete={onClickDeleteButton}
      suggestions={suggestions}
      suggestionsTransform={suggestionsTransform}
      minQueryLength={1}
      placeholderText=""
      disabled={disableTagsInput}
    />
    <AddButton onClick={onClickAddButton} disabled={disableAddButton} />
  </Row>
);

export default ResultAdd;
