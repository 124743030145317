import React from 'react';

import currencyNumber from '../../utils/currencyNumber';

const UserStatistic = ({ checkedCount, remainCount }) => (
  <div className="user-statistic">
    <div>
      <span className="label">Checked:</span>
      <span className="value">{currencyNumber(checkedCount) || 0}</span>
    </div>
    <div className="divider" />
    <div>
      <span className="label">Remain:</span>
      <span className="value">{currencyNumber(remainCount) || 0}</span>
    </div>
  </div>
);

export default UserStatistic;
