import React, { useState } from 'react';

import TextTruncate from 'react-text-truncate';

const Readmore = ({ children, lines, more, less }) => {
  const [seeMore, setSeeMore] = useState(false);
  return seeMore ? (
    <>
      {children}
      <button
        id="readme"
        onClick={() => setSeeMore(!seeMore)}
        style={{ cursor: 'pointer' }}
        type="button"
      >
        {` ${less}`}
      </button>
    </>
  ) : (
    <TextTruncate
      line={lines}
      element="span"
      truncateText="…"
      text={children}
      textTruncateChild={
        <button
          id="readmore"
          onClick={() => setSeeMore(!seeMore)}
          style={{ cursor: 'pointer' }}
          type="button"
        >
          {more}
        </button>
      }
    />
  );
};
export default Readmore;
