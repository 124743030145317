import React from 'react';

import ResultButton from '../atoms/ResultButton';

const ResultButtonGroup = ({ resultList, onClickEachButton }) => (
  <div className="result-button-group">
    {resultList.map((result, idx) => (
      <ResultButton
        key={idx}
        onClick={() => onClickEachButton(result)}
        label={result.label}
      />
    ))}
  </div>
);

export default ResultButtonGroup;
