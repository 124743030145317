import React, { useState } from 'react';

import { Modal, ModalBody, ModalFooter } from 'reactstrap';

import CancelButton from '../atoms/CancelButton';
import DeleteButton from '../atoms/RejectButton';
import SaveButton from '../atoms/SaveButton';

const ConfirmModal = ({
  onConfirm,
  type,
  modalMessage,
  buttonMessage,
  disabled,
  button,
  detailMessage,
}) => {
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);
  const confirm = () => {
    toggle();
    onConfirm();
  };

  let Button;
  if (type === 'save') {
    Button = SaveButton;
  } else if (type === 'delete') {
    Button = DeleteButton;
  } else if (type === 'cancel') {
    Button = CancelButton;
  } else {
    Button = button;
  }

  return (
    <>
      <Button
        onClick={toggle}
        message={detailMessage}
        disabled={disabled === true}
      />
      <Modal isOpen={modal} toggle={toggle} id="task_create">
        <ModalBody>{modalMessage}</ModalBody>
        <ModalFooter>
          <CancelButton onClick={toggle} />
          <SaveButton onClick={confirm} message={buttonMessage} />
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ConfirmModal;
