import React, { useContext } from 'react';

import {
  Form,
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  Label,
} from 'reactstrap';

import { AppContext } from '../../store';
import {
  validateAlphanumeric,
  validateCheckableitemLimit,
  validateEmail,
  validateLength,
} from '../../utils/utils';

const UserForm = ({ mode }) => {
  const { state, dispatch } = useContext(AppContext);

  const {
    user: {
      username,
      password,
      email,
      name,
      checkableItemLimit,
      role,
      isChangePassword,
    },
  } = state.user;

  const updateFieldValue = (field, value) => {
    dispatch({ type: 'UPDATE_USER_VALUE', payload: { field, value } });
  };
  return (
    <div className="user-create-form">
      <Form className="form">
        <FormGroup>
          <Label for="user-username">Username</Label>
          <Input
            id="user-username"
            name="username"
            type="text"
            invalid={
              !validateAlphanumeric(username) || !validateLength(username)
            }
            value={username}
            onChange={(e) => updateFieldValue(e.target.name, e.target.value)}
            disabled={mode === 'edit'}
          />
          <FormFeedback>Invalid username.</FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label for="user-password">
            Password
            {mode === 'edit' && (
              <FormGroup check className="confirm-change-password-checkbox">
                <FormText>
                  <Input
                    type="checkbox"
                    name="isChangePassword"
                    checked={isChangePassword}
                    onChange={(e) => {
                      updateFieldValue(e.target.name, !isChangePassword);
                      updateFieldValue('password', null);
                      document.getElementById('user-password').value = null;
                    }}
                  />{' '}
                  Confirm to change password
                </FormText>
              </FormGroup>
            )}
          </Label>
          <Input
            id="user-password"
            name="password"
            type="password"
            invalid={
              !validateAlphanumeric(password) || !validateLength(password)
            }
            value={password}
            onChange={(e) => updateFieldValue(e.target.name, e.target.value)}
            disabled={mode === 'edit' && !isChangePassword}
          />
          <FormFeedback>Invalid password.</FormFeedback>
        </FormGroup>
        <FormGroup>
          <FormGroup>
            <Label for="user-role" className="select">
              Role
              <Input
                id="user-role"
                name="role"
                type="select"
                value={role}
                onChange={(e) =>
                  updateFieldValue(e.target.name, e.target.value)
                }
                disabled={mode === 'edit'}
              >
                <option value="checker">Checker</option>
                <option value="manager">Manager</option>
              </Input>
            </Label>
          </FormGroup>
        </FormGroup>
        <FormGroup>
          <Label for="user-email">Email</Label>
          <Input
            id="user-email"
            name="email"
            type="email"
            invalid={!validateEmail(email)}
            value={email}
            onChange={(e) => updateFieldValue(e.target.name, e.target.value)}
          />
          <FormFeedback>Invalid email.</FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label for="user-name">Name</Label>
          <Input
            id="user-name"
            name="name"
            type="text"
            invalid={name === ''}
            value={name}
            onChange={(e) => updateFieldValue(e.target.name, e.target.value)}
          />
          <FormFeedback>Invalid name.</FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label for="user-checkableitem-limit">Checkable-item Limit</Label>
          <Input
            id="user-checkableitem-limit"
            name="checkableItemLimit"
            type="number"
            max={20}
            min={1}
            invalid={!validateCheckableitemLimit(checkableItemLimit)}
            value={checkableItemLimit}
            onChange={(e) => updateFieldValue(e.target.name, e.target.value)}
          />
          <FormFeedback>Invalid checkable-item limit.</FormFeedback>
        </FormGroup>
      </Form>
    </div>
  );
};

export default UserForm;
