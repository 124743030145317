import React, { useContext, useEffect, useState } from 'react';

import { useAlert } from 'react-alert';
import { useHistory } from 'react-router-dom';

import Loading from '../components/molecules/Loading';
import UserCreateComponent from '../components/views/UserCreate';
import { RESET_USER } from '../constants/actions';
import {
  ALERT_USER_CREATE_ERROR,
  ALERT_USER_CREATE_SUCCESS,
} from '../constants/messages';
import { USER_LIST } from '../constants/route';
import { INITIAL, PENDING, RENDERED } from '../constants/state';
import Api from '../services/api';
import { AppContext } from '../store';
import { to } from '../utils/utils';
import { validateUserForm } from '../utils/validator';

const UserCreate = () => {
  const { state, dispatch } = useContext(AppContext);
  const [stateStatus, setStateStatus] = useState(INITIAL);
  const history = useHistory();
  const alert = useAlert();

  const {
    auth,
    user: { user },
  } = state;

  const backToTaskList = () => {
    history.push(to(USER_LIST));
  };

  const requestCreateNewUser = async (token, data) => {
    const api = new Api(token);
    const user = {
      username: data.username,
      password: data.password,
      email: data.email,
      name: data.name,
      role: data.role,
      checkableItemLimit: data.checkableItemLimit,
    };

    try {
      const createdUser = await api.createUser(user);
      if (createdUser.id) {
        dispatch({
          type: RESET_USER,
          payload: {},
        });
        backToTaskList();
        alert.show(ALERT_USER_CREATE_SUCCESS, {
          timeout: 3000,
          type: 'success',
        });
      } else {
        alert.show(ALERT_USER_CREATE_ERROR, {
          timeout: 3000,
          type: 'error',
        });
      }
    } catch (error) {
      alert.show(ALERT_USER_CREATE_ERROR, {
        timeout: 3000,
        type: 'error',
      });
    }
  };

  const createNewUser = async () => {
    const {
      auth: {
        user: { token },
      },
      user: { user },
    } = state;
    await requestCreateNewUser(token, user);
  };

  useEffect(() => {
    switch (stateStatus) {
      case INITIAL:
        setStateStatus(PENDING);
        break;
      case PENDING:
        setStateStatus(RENDERED);
        break;
      default:
        break;
    }
  }, [stateStatus, auth]);

  return stateStatus ? (
    <div id="user_create">
      <UserCreateComponent
        onClickCreateUserButton={createNewUser}
        onClickCancelButton={backToTaskList}
        validUser={validateUserForm(user)}
      />
    </div>
  ) : (
    <Loading />
  );
};

export default UserCreate;
