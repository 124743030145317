import React, { useContext, useEffect, useState } from 'react';

import { useAlert } from 'react-alert';
import {
  Form,
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  Label,
} from 'reactstrap';

import TaskDataFilterForm from './TaskDataFilterForm';
import TaskPaymentRateForm from './TaskPaymentRateForm';
import { TaskReader } from '../../services/file';
import { AppContext } from '../../store';
import Parser from '../../utils/parser';
import DateRangePicker from '../atoms/DateRangePicker';
import FileInput from '../atoms/FileInput';

const TaskForm = ({ mode, nameValid, fileValid, setFileValid }) => {
  const { state, dispatch } = useContext(AppContext);
  const [configFromFile, setConfigFromFile] = useState(null);
  const alert = useAlert();
  const windowSize = {
    width:
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth,
    height:
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight,
  };

  const {
    task: {
      name,
      type,
      checkItemType,
      zocialEyeV1DataFilter,
      taskPeriod,
      paymentType,
      flatPaymentRate,
      socialChannelPaymentRate,
      status,
      redsmithV1DataFilter,
      zocialEyeV2DataFilter,
      checkItOffDataFilter,
    },
  } = state.task;

  const updateFieldValue = (field, value) => {
    dispatch({ type: 'UPDATE_TASK_VALUE', payload: { field, value } });
  };

  const handleChangeFile = async (file) => {
    if (!file) {
      updateFieldValue('config', null);
      return;
    }

    let resultConfig;
    try {
      const taskReader = new TaskReader(file, Parser);
      resultConfig = await taskReader.readFile(file);
    } catch (error) {
      alert.show(error.message, {
        timeout: 3000,
        type: 'error',
      });
      setConfigFromFile(null);
      setFileValid(false);
      updateFieldValue('config', null);

      console.error(error.message);
    }

    if (resultConfig) {
      setConfigFromFile(resultConfig);
    }
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (configFromFile) {
      const resultConfig =
        type === 'HIERARCHY_LABEL'
          ? Parser.parseConfigHierarchyLabel(configFromFile)
          : Parser.parseConfigMultipleLabel(configFromFile);
      if (resultConfig) {
        updateFieldValue('config', resultConfig);
        // drawGraph(windowSize.width, windowSize.height, resultConfig, type);
        if (!fileValid) setFileValid(true);
      } else {
        updateFieldValue('config', null);
        setFileValid(false);
      }
      // Handler to call on window resize
      const handleResize = () => {
        // Set window width/height to state

        const width =
          window.innerWidth ||
          document.documentElement.clientWidth ||
          document.body.clientWidth;

        const height =
          window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight;

        // drawGraph(width, height, resultConfig, type);
      };

      // Add event listener
      window.addEventListener('resize', handleResize);

      // Call handler right away so state gets updated with initial window size
      handleResize();

      // Remove event listener on cleanup
      return () => window.removeEventListener('resize', handleResize);
    }
  }, [configFromFile, fileValid, type]);

  return (
    <div className="task-create-form">
      <Form className="form">
        <FormGroup>
          <Label for="task-name">Name</Label>
          <Input
            id="task-name"
            name="name"
            type="text"
            invalid={name === null ? !nameValid : name === ''}
            value={name}
            onChange={(e) => updateFieldValue(e.target.name, e.target.value)}
          />
          <FormFeedback>Invalid task name.</FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label for="task-config">Config</Label>
          <FileInput
            id="exampleCustomFileBrowser"
            name="customFile"
            onChange={(e) =>
              mode !== 'edit' && handleChangeFile(e.target.files[0])
            }
            disabled={mode === 'edit'}
            invalid={!fileValid}
          />
          <FormText color={fileValid || mode === 'edit' ? 'muted' : 'danger'}>
            {fileValid || mode === 'edit'
              ? 'Only excel and csv files are supported.'
              : 'The file does not match its type.'}
          </FormText>
          <div id="chart" />
        </FormGroup>
        <FormGroup>
          <Label for="taskPeriod">Task Period</Label>
          <div className="task-period">
            <DateRangePicker
              startDateTime={taskPeriod.start}
              endDateTime={taskPeriod.end}
              onApply={(_, picker) => {
                updateFieldValue('taskPeriod', {
                  start: picker.startDate,
                  end: picker.endDate,
                });
              }}
            />
          </div>
        </FormGroup>
        <TaskDataFilterForm
          mode={mode}
          checkItemType={checkItemType}
          setCheckItemType={(value) => updateFieldValue('checkItemType', value)}
          setCheckItemTypeDisabled={mode === 'edit'}
          zocialEyeV1DataFilter={zocialEyeV1DataFilter}
          setZocialEyeV1DataFilter={(value) =>
            updateFieldValue('zocialEyeV1DataFilter', value)
          }
          redsmithV1DataFilter={redsmithV1DataFilter}
          setRedsmithV1DataFilter={(value) =>
            updateFieldValue('redsmithV1DataFilter', value)
          }
          zocialEyeV2DataFilter={zocialEyeV2DataFilter}
          setZocialEyeV2DataFilter={(value) =>
            updateFieldValue('zocialEyeV2DataFilter', value)
          }
          checkItOffDataFilter={checkItOffDataFilter}
          setCheckItOffDataFilter={(value) =>
            updateFieldValue('checkItOffDataFilter', value)
          }
        />
        <FormGroup>
          <Label for="task-status" className="select">
            Task Status
            <Input
              id="task-status"
              name="status"
              type="select"
              value={status}
              onChange={(e) => updateFieldValue(e.target.name, e.target.value)}
            >
              <option value="enabled">Enable</option>
              <option value="disabled">Disable</option>
            </Input>
          </Label>
        </FormGroup>
      </Form>
    </div>
  );
};

export default TaskForm;
