import React from 'react';

import { Col, Row, Table } from 'reactstrap';

import Pagination from '../molecules/Pagination';
import TableHeader from '../molecules/TableHeader';
import TaskAssignUserBody from '../molecules/TaskAssignUserBody';

const TaskAssignUserController = ({
  users,
  page,
  updateSelectUserToAssign,
  selectedUserToAssign,
}) => {
  const tableHeader = [
    { id: 'checker', label: '', width: 40 },
    { id: 'user_name', label: 'Name', width: '' },
    { id: 'user_username', label: 'Username', width: '' },
  ];

  const { currentPage, setCurrentPage, totalPage, segmentSize } = page;

  return (
    <Row className="task-table">
      <Col md={12} className="table">
        <Table className="task-assign-user-table" responsive striped bordered>
          <TableHeader data={tableHeader} />
          <TaskAssignUserBody
            data={users}
            checkFunction={updateSelectUserToAssign}
            checkedData={selectedUserToAssign}
          />
        </Table>
      </Col>
      <Col md={12} className="pagination">
        <Pagination
          currentPage={currentPage}
          totalPage={totalPage}
          setCurrentPage={setCurrentPage}
          segmentSize={segmentSize}
        />
      </Col>
    </Row>
  );
};

export default TaskAssignUserController;
