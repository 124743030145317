import ReactHtmlParser from 'react-html-parser';

const Highlighter = ({ text, mainKeywords = [], subKeywords = [] }) => {
  const subString = (string, idxStart, idxEnd) =>
    string.substring(idxStart, idxEnd);

  const getIndexOfNextChar = (wordIndex, word) => wordIndex + word.length;

  const isThaiMark = (char) => {
    const thaiMarks = ['่', '้', '๊', '๋'];
    return thaiMarks.includes(char);
  };

  const isThaiVowel = (char) => {
    const thaiVowels = ['็', 'ิ', 'ั', 'ุ', 'ู', 'ำ'];
    return thaiVowels.includes(char);
  };

  const isSpecialVowel = (char) => {
    const specialVowel = ['ำ'];
    return specialVowel.includes(char);
  };

  const addVowelAndMarkOfWord = (keywordIndex, message, word) => {
    let newWord = word;
    let nextChar = message[getIndexOfNextChar(keywordIndex, newWord)];
    if (isThaiMark(nextChar)) {
      newWord += nextChar;
      nextChar = message[getIndexOfNextChar(keywordIndex, newWord)];
      if (isSpecialVowel(nextChar)) {
        newWord += nextChar;
      }
    } else if (isThaiVowel(nextChar)) {
      newWord += nextChar;
      nextChar = message[getIndexOfNextChar(keywordIndex, newWord)];
      if (isThaiMark(nextChar)) {
        newWord += nextChar;
      }
    }
    return newWord;
  };

  const highlightKeyword = (message, keyword, keywordType) => {
    // replace keyword on messages with span tag
    if (!keyword || keyword.trim() === '') return text;

    let startSearchIndex = 0;
    let replacedMessage = `${message}`;

    const isFoundKeyword = (startSearchIndex) =>
      replacedMessage.toLowerCase().indexOf(keyword, startSearchIndex) >= 0;

    while (isFoundKeyword(startSearchIndex)) {
      const keywordIndex = replacedMessage
        .toLowerCase()
        .indexOf(keyword, startSearchIndex);

      const originalWord = subString(
        replacedMessage,
        keywordIndex,
        keywordIndex + keyword.length
      );
      const addedWord = addVowelAndMarkOfWord(
        keywordIndex,
        replacedMessage,
        originalWord
      );
      const keywordStyleType = {
        mainKeyword: 'font-weight: bold; color: #3a77c5;',
        subKeyword: 'font-weight: bold; color: #379d4a;',
      };
      const keywordHighligh = `<span style="${keywordStyleType[keywordType]}">${addedWord}</span>`;
      replacedMessage = `${subString(
        replacedMessage,
        0,
        keywordIndex
      )}${keywordHighligh}${subString(
        replacedMessage,
        keywordIndex + addedWord.length,
        replacedMessage.length
      )}`;
      startSearchIndex = keywordIndex + keywordHighligh.length;
    }
    return replacedMessage;
  };

  let message = `<span>${text}<span>`;

  subKeywords
    .filter((subKeyword) => !mainKeywords.includes(subKeyword))
    .forEach((filteredSubKeyword) => {
      message = highlightKeyword(message, filteredSubKeyword, 'subKeyword');
    });

  mainKeywords.forEach((keyword) => {
    message = highlightKeyword(message, keyword, 'mainKeyword');
  });

  return ReactHtmlParser(message);
};

export default Highlighter;
