import React from 'react';

import { Col, Row } from 'reactstrap';

import PagingButtonGroupNotFound from '../molecules/PagingButtonGroupNotFound';

const TaskPagingNotFound = ({ onBackButtonClick }) => (
  <Row className="task-action">
    <Col md={12}>
      <PagingButtonGroupNotFound onBackButtonClick={onBackButtonClick} />
    </Col>
  </Row>
);

export default TaskPagingNotFound;
