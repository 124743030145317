import React from 'react';

import { Input, Label } from 'reactstrap';

const TextInput = ({
  label,
  name,
  type,
  value,
  onChange,
  className,
  onKeyDown,
  disabled,
  placeholder,
}) => (
  <>
    {label && <Label for={name}>{label}</Label>}
    <Input
      name={name}
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      className={className}
      onKeyDown={onKeyDown}
      disabled={disabled}
    />
  </>
);

export default TextInput;
