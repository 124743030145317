import React from 'react';

import { FormGroup, Input, Label } from 'reactstrap';

import CheckItOffDataFilter from '../molecules/CheckItOffDataFilter';
import RedsmithV1DataFilter from '../molecules/RedsmithV1DataFilter';
import ZocialEyeV1DataFilter from '../molecules/ZocialEyeV1DataFilter';
import ZocialEyeV2DataFilter from '../molecules/ZocialEyeV2DataFilter';

const TaskDataFilterForm = ({
  mode,
  checkItemType,
  zocialEyeV1DataFilter,
  setZocialEyeV1DataFilter,
  setCheckItemType,
  setCheckItemTypeDisabled,
  redsmithV1DataFilter,
  setRedsmithV1DataFilter,
  zocialEyeV2DataFilter,
  setZocialEyeV2DataFilter,
  checkItOffDataFilter,
  setCheckItOffDataFilter,
}) => {
  const checkItemTypes = {
    'check-it-off': 'Redsmith 2024',
    'redsmith-v1': 'Redsmith v.1',
    text: 'Text',
    'zocial-eye-v2': 'Zocial Eye v2',
  };

  if (mode === 'edit') {
    checkItemTypes['zocial-eye-v1.0'] = 'Zocial Eye v1.0';
  }

  return (
    <>
      <FormGroup>
        <Label for="task-checkitem-type" className="select">
          Check item type
          <Input
            type="select"
            name="checkItemType"
            id="task-checkitem-type"
            defaultValue={checkItemType}
            onChange={(e) => setCheckItemType(e.target.value)}
            disabled={setCheckItemTypeDisabled}
          >
            {setCheckItemTypeDisabled && (
              <option value={checkItemType}>
                {checkItemTypes[checkItemType]}
              </option>
            )}
            {!setCheckItemTypeDisabled &&
              Object.keys(checkItemTypes).map((key) => (
                <option key={key} value={key}>
                  {checkItemTypes[key]}
                </option>
              ))}
          </Input>
        </Label>
      </FormGroup>
      {checkItemType === 'zocial-eye-v1.0' && (
        <ZocialEyeV1DataFilter
          dataFilter={zocialEyeV1DataFilter}
          setDataFilter={setZocialEyeV1DataFilter}
        />
      )}
      {checkItemType === 'zocial-eye-v2' && (
        <ZocialEyeV2DataFilter
          dataFilter={zocialEyeV2DataFilter}
          setDataFilter={setZocialEyeV2DataFilter}
        />
      )}
      {checkItemType === 'redsmith-v1' && (
        <RedsmithV1DataFilter
          dataFilter={redsmithV1DataFilter}
          setDataFilter={setRedsmithV1DataFilter}
        />
      )}
      {checkItemType === 'check-it-off' && (
        <CheckItOffDataFilter
          dataFilter={checkItOffDataFilter}
          setDataFilter={setCheckItOffDataFilter}
        />
      )}
    </>
  );
};

export default TaskDataFilterForm;
