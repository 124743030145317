import React from 'react';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';

library.add(faPlusCircle);

const CreateNewUserButton = ({ disabled, onClick }) => (
  <Button
    className="create-new-user"
    color="success"
    disabled={disabled}
    onClick={onClick}
  >
    <FontAwesomeIcon icon="plus-circle" />
    Create a new user
  </Button>
);

export default CreateNewUserButton;
