import React from 'react';

import { Button } from 'reactstrap';

const LoginButton = ({ disabled, onClick }) => (
  <Button
    className="login-button"
    color="secondary"
    disabled={disabled}
    onClick={onClick}
    block
  >
    Login
  </Button>
);

export default LoginButton;
