import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { Col, Label, Row } from 'reactstrap';

import { TASK_LIST, TASK_RECENT_ACTIVITY } from '../../constants/route';
import { to } from '../../utils/utils';
import UserStatistic from '../molecules/UserStatistic';
import TaskAction from '../organisms/TaskEditCheckResultsAction';
import TaskActionNotFound from '../organisms/TaskEditCheckResultsActionNotFound';
import TaskHierarchyController from '../organisms/TaskHierarchyController';
import TaskMultiLabelController from '../organisms/TaskMultiLabelController';
import TaskNote from '../organisms/TaskNote';
import TaskView from '../organisms/TaskView';
import TaskViewNotFound from '../organisms/TaskViewNotFound';

const TaskEditCheckResultsContainer = ({
  checkableItem,
  checkResultConfig,
  updateTaskResult,
  checkResultType,
  userStat,
  initialCheckResultList,
  initialNote,
}) => {
  const history = useHistory();
  const [note, setNote] = useState(initialNote);
  const [checkResultList, setCheckResultList] = useState(
    initialCheckResultList
  );
  const [disabledSaveButton, setDisabledSaveButton] = useState(true);
  const [rejectDisabled, setRejectDisabled] = useState(
    initialCheckResultList.length > 0
  );

  const updateNote = (text) => {
    setNote(text);
  };

  const addCheckResult = (label, value, group = null) => {
    const tempIds = [...Array(checkResultList.length + 1)].map(
      (_, idx) => idx + 1
    );

    const currentIds = checkResultList.map(({ id }) => id);
    const checkId = tempIds.find((id) => !currentIds.includes(id));

    setCheckResultList([
      ...checkResultList,
      { id: checkId, label, value, group },
    ]);
    setRejectDisabled(true);
  };

  const setValidCheckResult = (status) => {
    setDisabledSaveButton(!status);
  };

  const duplicateCheckResult = (id) => {
    const duplicatedCheckResult = checkResultList.find(
      (checkResult) => checkResult.id === id
    );

    setDisabledSaveButton(true);
    return duplicatedCheckResult;
  };

  const deleteCheckResult = (id) => {
    const filteredCheckResultList = checkResultList.filter(
      (checkResult) => checkResult.id !== id
    );

    setCheckResultList(filteredCheckResultList);
    setDisabledSaveButton(filteredCheckResultList.length === 0);
    setRejectDisabled(filteredCheckResultList.length !== 0);
  };

  const saveTaskResult = () => {
    const actionType = 'edit';
    const actionTime = new Date();
    updateTaskResult(checkResultList, note, actionType, actionTime);
    setCheckResultList([]);
    setNote('');
    setDisabledSaveButton(true);
    setRejectDisabled(false);
  };

  const rejectTaskResult = () => {
    const actionType = 'edit';
    const actionTime = new Date();
    updateTaskResult(
      [{ id: 0, label: ['Reject'], value: ['reject'], group: ['reject'] }],
      note,
      actionType,
      actionTime
    );
    setCheckResultList([]);
    setNote('');
    setDisabledSaveButton(true);
    setRejectDisabled(false);
  };

  const backToRecentActivity = () => {
    history.push(to(TASK_RECENT_ACTIVITY, checkableItem.taskID));
  };

  const backToTaskList = () => {
    history.push(to(TASK_LIST));
  };

  useEffect(() => {
    setCheckResultList(initialCheckResultList);
    setNote(initialNote);
  }, [initialCheckResultList, initialNote]);

  return checkableItem && checkableItem.id !== 'not-found' ? (
    <Row className="task-container">
      <Col md={12}>
        <UserStatistic
          checkedCount={userStat.checkedItemCount}
          remainCount={userStat.remainItemCount}
        />
      </Col>
      <Col md={12}>
        <TaskView checkableItem={checkableItem || {}} />
      </Col>
      <Col md={12}>
        <Label for="check-item-result">Result</Label>
        {checkResultType === 'MULTIPLE_LABEL' && (
          <TaskMultiLabelController
            checkItemId={checkableItem.id}
            checkResultConfig={checkResultConfig}
            checkResultList={checkResultList}
            addCheckResult={addCheckResult}
            deleteCheckResult={deleteCheckResult}
            duplicateCheckResult={duplicateCheckResult}
            setValidCheckResult={setValidCheckResult}
            setRejectDisabled={setRejectDisabled}
            isRejected={
              checkResultList.length > 0 &&
              checkResultList[0].value.includes('reject')
            }
          />
        )}
        {checkResultType === 'HIERARCHY_LABEL' && (
          <TaskHierarchyController
            checkItemId={checkableItem.id}
            checkResultConfig={checkResultConfig}
            checkResultList={checkResultList}
            addCheckResult={addCheckResult}
            deleteCheckResult={deleteCheckResult}
            duplicateCheckResult={duplicateCheckResult}
            setValidCheckResult={setValidCheckResult}
            setRejectDisabled={setRejectDisabled}
            isRejected={
              checkResultList.length > 0 &&
              checkResultList[0].value.includes('reject')
            }
          />
        )}
      </Col>
      <Col md={12}>
        <Label for="check-item-note">Note</Label>
        <TaskNote updateNote={updateNote} note={note} />
      </Col>
      <Col md={12}>
        <TaskAction
          onBackButtonClick={backToRecentActivity}
          onSaveButtonClick={saveTaskResult}
          onRejectButtonClick={rejectTaskResult}
          disableSaveButton={disabledSaveButton}
          disableRejectButton={rejectDisabled}
        />
      </Col>
    </Row>
  ) : (
    <Row className="task-container">
      <Col md={12}>
        <UserStatistic
          checkedCount={userStat.checkedItemCount}
          remainCount={userStat.remainItemCount}
        />
      </Col>
      <Col md={12}>
        <TaskViewNotFound />
      </Col>
      <Col md={12}>
        <TaskActionNotFound onBackButtonClick={backToTaskList} />
      </Col>
    </Row>
  );
};

export default TaskEditCheckResultsContainer;
