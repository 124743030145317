import React from 'react';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faSync } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Button } from 'reactstrap';

library.add(faSync);

const SyncButton = ({ disabled, onClick, taskId, message }) => (
  <OverlayTrigger
    key="top"
    placement="top"
    overlay={
      <Tooltip id={`${taskId || 'task'}-tooltip-sync-button`}>
        <strong>{message}</strong>
      </Tooltip>
    }
  >
    <Button
      className="start-button btn-outline"
      disabled={disabled}
      onClick={onClick}
      id={`${taskId}-sync-button`}
    >
      <FontAwesomeIcon icon={['far', 'sync']} />
    </Button>
  </OverlayTrigger>
);

export default SyncButton;
