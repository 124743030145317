import React from 'react';

import { Col, Row, Table } from 'reactstrap';

import Pagination from '../molecules/Pagination';
import TableHeader from '../molecules/TableHeader';
import TaskListBody from '../molecules/TaskListBody';

const TaskListController = ({ tasks, page, exportCheckItem }) => {
  const tableHeader = [
    { id: 'name', label: 'Name', width: 160 },
    { id: 'checker_count', label: 'Checker', width: 40 },
    { id: 'created_by', label: 'Created by', width: 80 },
    { id: 'deadline', label: 'Deadline', width: 90 },
    { id: 'action', label: 'Action', width: 130 },
  ];
  const { currentPage, setCurrentPage, totalPage, segmentSize } = page;

  return (
    <Row className="task-table">
      <Col md={12} className="table">
        <Table className="task-list-table" responsive striped bordered>
          <TableHeader data={tableHeader} />
          <TaskListBody data={tasks} exportCheckItem={exportCheckItem} />
        </Table>
      </Col>
      <Col md={12} className="pagination">
        <Pagination
          currentPage={currentPage}
          totalPage={totalPage}
          setCurrentPage={setCurrentPage}
          segmentSize={segmentSize}
        />
      </Col>
    </Row>
  );
};

export default TaskListController;
