import JWTDecode from 'jwt-decode';

import Datetime from './datetime';

const isExpired = (timestamp) =>
  Datetime.fromTimestamp(timestamp) > Datetime.now();

const isLoggedIn = (auth) => {
  const { exp: expireTimestamp } = auth.user.token
    ? JWTDecode(auth.user.token)
    : 0;
  return auth.user && auth.isAuthUser === true && isExpired(expireTimestamp);
};

const isOwnTask = (task, auth) =>
  task.assignedTo.includes(auth.user.id) === true ||
  (task.createdBy.id === auth.user.id) === true;

const canCreateUser = (auth) =>
  auth.user.permissions.includes('allow-all') === true ||
  auth.user.permissions.includes('create-users') === true;

const canListUser = (auth) =>
  auth.user.permissions.includes('allow-all') === true ||
  auth.user.permissions.includes('create-users') === true ||
  auth.user.permissions.includes('manage-any-users') === true;

const canDeleteTask = (auth) =>
  auth.user.permissions.includes('allow-all') === true ||
  auth.user.permissions.includes('delete-tasks') === true;

const canCreateTask = (auth) =>
  auth.user.permissions.includes('allow-all') === true ||
  auth.user.permissions.includes('create-tasks') === true;

const canManageAnyTask = (auth) =>
  auth.user.permissions.includes('allow-all') === true ||
  auth.user.permissions.includes('manage-any-tasks') === true;

const canManageTask = (auth) =>
  auth.user.permissions.includes('allow-all') === true ||
  auth.user.permissions.includes('manage-any-tasks') === true ||
  auth.user.permissions.includes('manage-tasks') === true;

const canListTask = (auth) =>
  auth.user.permissions.includes('allow-all') === true ||
  auth.user.permissions.includes('manage-any-tasks') === true ||
  auth.user.permissions.includes('manage-tasks') === true ||
  auth.user.permissions.includes('check-assigned-tasks') === true;

const canCheckResultInTask = (auth) =>
  auth.user.permissions.includes('allow-all') === true ||
  auth.user.permissions.includes('manage-any-tasks') === true ||
  auth.user.permissions.includes('manage-tasks') === true ||
  auth.user.permissions.includes('check-assigned-tasks') === true;

const canAssignUserToTask = (auth) =>
  auth.user.permissions.includes('allow-all') === true ||
  (auth.user.permissions.includes('manage-any-tasks') === true &&
    auth.user.permissions.includes('manage-any-users') === true) ||
  (auth.user.permissions.includes('manage-tasks') === true &&
    auth.user.permissions.includes('manage-any-users') === true) ||
  (auth.user.permissions.includes('manage-any-tasks') === true &&
    auth.user.permissions.includes('create-users') === true) ||
  (auth.user.permissions.includes('manage-tasks') === true &&
    auth.user.permissions.includes('create-users') === true);

const canImportCheckItemToTask = (auth) =>
  auth.user.permissions.includes('allow-all') === true ||
  (auth.user.permissions.includes('manage-any-tasks') === true &&
    auth.user.permissions.includes('manage-task-api-keys') === true) ||
  (auth.user.permissions.includes('manage-tasks') === true &&
    auth.user.permissions.includes('manage-task-api-keys') === true);

export {
  isLoggedIn,
  isOwnTask,
  canCreateUser,
  canListUser,
  canDeleteTask,
  canCreateTask,
  canManageAnyTask,
  canManageTask,
  canListTask,
  canCheckResultInTask,
  canAssignUserToTask,
  canImportCheckItemToTask,
};
