import React from 'react';

import { Col, Row } from 'reactstrap';

import ActionButtonGroup from '../molecules/ActionButtonGroup';

const TaskAction = ({
  checkItemId,
  onSaveButtonClick,
  onRejectButtonClick,
  disableSaveButton,
  disableRejectButton,
}) => (
  <Row className="task-action">
    <Col md={12}>
      <ActionButtonGroup
        checkItemId={checkItemId}
        onSaveButtonClick={onSaveButtonClick}
        onRejectButtonClick={onRejectButtonClick}
        disableSaveButton={disableSaveButton}
        disableRejectButton={disableRejectButton}
      />
    </Col>
  </Row>
);

export default TaskAction;
