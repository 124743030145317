import React, { useContext, useEffect, useState } from 'react';

import { useAlert } from 'react-alert';
import { Redirect, useHistory } from 'react-router-dom';

import Loading from '../components/molecules/Loading';
import TaskEditCheckResultsComponent from '../components/views/TaskEditCheckResults';
import {
  CLEAR_CHECKITEM_DATA,
  CLEAR_SELECTED_TASK,
  CLEAR_SELECTED_USER_STAT,
  SAVE_CHECK_RESULT,
  SET_CHECKITEM_DATA,
  SET_SELECTED_TASK,
  SET_SELECTED_USER_STAT,
} from '../constants/actions';
import {
  ALERT_CHECKITEM_UPDATE_ERROR,
  ALERT_CHECKITEM_UPDATE_SUCCESS,
  ALERT_TASK_NOT_FOUND_ERROR,
} from '../constants/messages';
import { TASK_LIST, TASK_RECENT_ACTIVITY } from '../constants/route';
import {
  FETCHING,
  INITIAL,
  PENDING,
  REDIRECT,
  RENDERED,
} from '../constants/state';
import Api from '../services/api';
import { AppContext } from '../store';
import { formatCheckResult } from '../utils/format';
import { to } from '../utils/utils';

const TaskEditCheckResults = ({ match }) => {
  const { state, dispatch } = useContext(AppContext);
  const [stateStatus, setStateStatus] = useState(INITIAL);
  const [taskResult, setTaskResult] = useState([
    { checkItemId: null, checkResult: null },
  ]);
  const [checkResultList, setCheckResultList] = useState([]);
  const [note, setNote] = useState('');
  const [taskSchedulerID, setTaskSchedulerID] = useState('');
  const history = useHistory();
  const alert = useAlert();

  const {
    checkitem: { data: checkableItem },
    task: { selectedTask, selectedUserStat },
    auth: {
      user: { token, id: userId },
    },
  } = state;

  const { id: checkItemId } = match.params;
  const taskId = checkItemId.split('_')[0];
  let checkResultConfig;
  let checkResultType;
  let checkItemPaymentRate;

  const backToRecentActivity = (taskId) => {
    history.push(to(TASK_RECENT_ACTIVITY, taskId));
  };

  const cleanUp = () => {
    dispatch({ type: CLEAR_CHECKITEM_DATA });
    dispatch({ type: CLEAR_SELECTED_TASK });
    dispatch({ type: CLEAR_SELECTED_USER_STAT });
  };

  if (selectedTask && selectedTask.id && selectedTask.config) {
    const { resultTypeConfiguration, paymentRate } = selectedTask.config;

    checkResultType = resultTypeConfiguration.type;
    checkResultConfig = resultTypeConfiguration.configuration;

    checkItemPaymentRate =
      paymentRate.type === 'flat'
        ? paymentRate.compensationPerItem
        : paymentRate.compensationSettings[checkableItem.channel];
  }

  const formatCheckResultBackward = (checkResultList) => {
    const formattedList = checkResultList.map(({ resultList }, id) => {
      const formattedResult = { id, group: [], label: [], value: [] };
      resultList.forEach((result) => {
        formattedResult.group = [...formattedResult.group, result.group];
        formattedResult.label = [...formattedResult.label, result.label];
        formattedResult.value = [...formattedResult.value, result.value];
      });
      return formattedResult;
    });
    return formattedList;
  };

  const setInitialCheckItemData = ({
    note,
    checkResultList,
    taskSchedulerID,
  }) => {
    setNote(note);
    setCheckResultList(formatCheckResultBackward(checkResultList));
    setTaskSchedulerID(taskSchedulerID);
  };

  const updateTaskResult = async (
    checkResultList,
    note,
    actionType,
    actionTime
  ) => {
    const checkResult = formatCheckResult(
      checkResultType,
      checkResultList,
      note,
      checkItemPaymentRate
    );

    setTaskResult([{ checkItemId, checkResult, actionType, actionTime }]);
    setStateStatus(SAVE_CHECK_RESULT);
  };

  useEffect(() => {
    const getTask = async (taskId, token) => {
      const api = new Api(token);
      const task = await api.getTask(taskId);

      if (task && task.id) {
        dispatch({
          type: SET_SELECTED_TASK,
          payload: { selectedTask: task },
        });
      } else {
        setStateStatus(REDIRECT);
        alert.show(ALERT_TASK_NOT_FOUND_ERROR, {
          timeout: 3000,
          type: 'error',
        });
      }
    };

    const getCheckedItem = async (checkItemId, taskId, token) => {
      const api = new Api(token);
      const data = await api.getCheckedCheckItem(checkItemId, taskId);
      dispatch({
        type: SET_CHECKITEM_DATA,
        payload: { data: data && data.id ? [data] : [] },
      });
      if (data && data.id) {
        setInitialCheckItemData(data.usersCheckResultList[0]);
      }
    };

    const getUserStatistic = async (taskId, userId, token) => {
      const api = new Api(token);
      const response = await api.getUserStatistic(taskId, userId);

      if (response) {
        dispatch({
          type: SET_SELECTED_USER_STAT,
          payload: { selectedUserStat: { ...response, userId } },
        });
      }
    };

    const saveCheckResult = async (taskId, taskResult, token) => {
      const { checkItemId, checkResult, actionType, actionTime } =
        taskResult[0];

      const api = new Api(token);
      const response = await api.saveCheckResult(
        taskId,
        checkItemId,
        taskSchedulerID,
        checkResult,
        actionType,
        actionTime
      );

      if (response && response.status === 'success') {
        setStateStatus(PENDING);
        backToRecentActivity(taskId);
        dispatch({ type: CLEAR_CHECKITEM_DATA });
        alert.show(ALERT_CHECKITEM_UPDATE_SUCCESS, {
          timeout: 3000,
          type: 'success',
        });
      } else {
        setStateStatus(RENDERED);
        alert.show(ALERT_CHECKITEM_UPDATE_ERROR, {
          timeout: 3000,
          type: 'error',
        });
      }
    };

    switch (stateStatus) {
      case INITIAL:
        cleanUp();
        setStateStatus(PENDING);
        break;
      case PENDING:
        setStateStatus(FETCHING);
        break;
      case FETCHING:
        if (!selectedTask.id || !selectedTask.config) {
          getTask(taskId, token);
        }

        if (selectedTask.id && checkableItem.length === 0) {
          getCheckedItem(checkItemId, taskId, token);
        }

        getUserStatistic(taskId, userId, token);
        if (
          selectedTask.id &&
          checkableItem.length > 0 &&
          checkableItem[0].id &&
          selectedUserStat.userId
        ) {
          setStateStatus(RENDERED);
        }
        break;
      case SAVE_CHECK_RESULT:
        saveCheckResult(taskId, taskResult, token);
        break;
      default:
        break;
    }
  }, [stateStatus, selectedTask, selectedUserStat, checkableItem]);
  return (
    <>
      {stateStatus === REDIRECT && <Redirect to={TASK_LIST} />}
      {stateStatus === RENDERED ? (
        <div id="task_check_item">
          <TaskEditCheckResultsComponent
            checkableItem={checkableItem[0]}
            checkResultConfig={checkResultConfig}
            checkResultType={checkResultType}
            updateTaskResult={updateTaskResult}
            userStat={selectedUserStat}
            initialCheckResultList={checkResultList}
            initialNote={note}
          />
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default TaskEditCheckResults;
