import React from 'react';

import { FormGroup, FormText, Input, Label } from 'reactstrap';

import DateRangePicker from '../atoms/DateRangePicker';

const CheckItOffDataFilter = ({ dataFilter, setDataFilter }) => {
  const sentiments = {
    Negative: 'negative',
    Neutral: 'neutral',
    Positive: 'positive',
  };
  const updateSentiment = (sentiment) => {
    let sentimentList = dataFilter.sentimentFromZe
      ? dataFilter.sentimentFromZe
      : Object.values(sentiments);
    if (sentimentList.includes(sentiment)) {
      sentimentList = sentimentList.filter((item) => item !== sentiment);
    } else {
      sentimentList = [...sentimentList, sentiment];
    }
    setDataFilter({
      version: dataFilter.version,
      postTime: dataFilter.postTime,
      sentimentFromZe: sentimentList,
      channel: dataFilter.channel,
    });
  };

  const channels = {
    Facebook: 'facebook',
    X: 'x',
    Instagram: 'instagram',
    YouTube: 'youtube',
    TikTok: 'tiktok',
    Forum: 'forum',
    News: 'news',
    Other: 'other',
  };
  const updateChannel = (channel) => {
    let channelList = dataFilter.channel
      ? dataFilter.channel
      : Object.values(channels);
    if (channelList.includes(channel)) {
      channelList = channelList.filter((item) => item !== channel);
    } else {
      channelList = [...channelList, channel];
    }

    setDataFilter({
      version: dataFilter.version,
      postTime: dataFilter.postTime,
      sentimentFromZe: dataFilter.sentimentFromZe,
      channel: channelList,
    });
  };

  return (
    <>
      <FormGroup>
        <Label for="checkitoffdatafilter-posttime">Post time</Label>
        <DateRangePicker
          startDateTime={dataFilter.postTime.start}
          endDateTime={dataFilter.postTime.end}
          onApply={(_, picker) => {
            setDataFilter({
              version: dataFilter.version,
              postTime: {
                start: picker.startDate,
                end: picker.endDate,
              },
              sentimentFromZe: dataFilter.sentimentFromZe,
              channel: dataFilter.channel,
            });
          }}
        />
      </FormGroup>
      <FormGroup>
        <Label for="checkitoffdatafilter-sentiment">Sentiment</Label>
        <FormGroup check className="checkitoffdatafilter-sentiment-checkbox">
          {Object.keys(sentiments).map((key) => (
            <Label key={key}>
              <Input
                type="checkbox"
                name={sentiments[key]}
                checked={
                  dataFilter.sentimentFromZe &&
                  dataFilter.sentimentFromZe.includes(sentiments[key])
                }
                onChange={() => updateSentiment(sentiments[key])}
              />{' '}
              {key}
            </Label>
          ))}
        </FormGroup>
        {(dataFilter.sentimentFromZe === undefined ||
          dataFilter.sentimentFromZe.length === 0) && (
          <FormText color="danger">Must include 1 sentiment at least.</FormText>
        )}
      </FormGroup>
      <FormGroup>
        <Label for="checkitoffdatafilter-channel">Channel</Label>
        <FormGroup check className="checkitoffdatafilter-channel-checkbox">
          {Object.keys(channels).map((key) => (
            <span key={key}>
              <Label>
                <Input
                  type="checkbox"
                  name={channels[key]}
                  checked={
                    dataFilter.channel &&
                    dataFilter.channel.includes(channels[key])
                  }
                  onChange={() => updateChannel(channels[key])}
                />{' '}
                {key}
              </Label>
            </span>
          ))}
        </FormGroup>
        {(dataFilter.channel === undefined ||
          dataFilter.channel.length === 0) && (
          <FormText color="danger">Must include 1 channel at least.</FormText>
        )}
      </FormGroup>
    </>
  );
};

export default CheckItOffDataFilter;
