import React, { useState, useEffect } from 'react';

import htmlParser from 'react-html-parser';
import { FormGroup, FormText, Input, Label } from 'reactstrap';

const TextImportOptions = ({
  columns,
  data,
  setData,
  setDisabledSaveButton,
}) => {
  const mandatoryKeys = ['id', 'message'];
  const optionalKeys = [
    'channel',
    'title',
    'mainKeyword',
    'subKeyword',
    'directUrl',
  ];
  const dataKeys = [...mandatoryKeys, ...optionalKeys];
  const dataSubtexts = {
    channel: 'Select a column if needed to <b>sync Spider</b>.',
    title: "Select a column if needed to <b>show the message's title</b>.",
    mainKeyword: 'Select a column if needed to <b>highlight the keyword</b>.',
    subKeyword: 'Select a column if needed to <b>highlight the keyword</b>.',
    directUrl:
      'Select a column if needed to <b>show link and embedded url</b>.',
  };

  const initialTextColumns = Object.fromEntries(
    dataKeys.map((key) => [key, ''])
  );
  const [textColumns, setTextColumns] = useState(initialTextColumns);

  const transformValue = (key, value) => {
    if (mandatoryKeys.includes(key)) {
      return textColumns[key] ? value : null;
    }
    if (optionalKeys.includes(key)) {
      return textColumns[key] && textColumns[key] !== '__undefined__'
        ? value
        : null;
    }
    return null;
  };

  const capitalizeFromCamelCase = (text) =>
    text[0].toUpperCase() +
    text
      .split(/(?=[A-Z])/)
      .join(' ')
      .slice(1);

  useEffect(() => {
    const transformData = (data) => {
      setDisabledSaveButton(true);

      const mappedData = data.map((d) => {
        const textData = Object.fromEntries(
          dataKeys.map((key) => [
            `$__${key}`,
            transformValue(key, d[`${textColumns[key]}`]),
          ])
        );
        return { ...d, ...textData };
      });
      if (mappedData) {
        setDisabledSaveButton(!mandatoryKeys.every((key) => textColumns[key]));
      }
      return mappedData;
    };
    if (data) {
      setData(transformData(data));
    }
  }, [textColumns]);

  return (
    <div className="text-import-options">
      {dataKeys.map((key) => (
        <FormGroup>
          <Label for="colId">{capitalizeFromCamelCase(key)}</Label>
          <Input
            type="select"
            id={`col-${key}`}
            value={textColumns[key]}
            onChange={(e) =>
              setTextColumns({ ...textColumns, [key]: e.target.value })
            }
          >
            <option value="">-- Please select a column --</option>
            {key in optionalKeys && (
              <option value="__undefined__" key={`col-${key}-undefined`}>
                Not defined
              </option>
            )}
            {columns.map((column) => (
              <option value={column} key={`col-${key}-${column}`}>
                {column}
              </option>
            ))}
          </Input>
          {key in dataSubtexts && (
            <FormText color="muted">{htmlParser(dataSubtexts[key])}</FormText>
          )}
        </FormGroup>
      ))}
    </div>
  );
};

export default TextImportOptions;
